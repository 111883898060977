import * as React from 'react';
import {Outlet} from 'react-router-dom';
import {DashboardPaths} from './DashboardRoutePaths';
import {LazyInstallationDashboard} from 'src/pages/Dashboard/pages/InstallationDashboard/LazyInstallationDashboard';
import { Suspense } from 'react';
import {LazyAssetDetails} from 'src/pages/Dashboard/pages/AssetDetails/LazyAssetDetails';
import {TilesPage} from 'src/pages/Dashboard/pages/TilesPage/TilesPage';
import {type AppRouteObject, makeRoutesRelative} from '../../router/util';
import { HomeDashboard } from './pages/HomeDashboard/HomeDashboard';

interface DashboardRoutesProps {

}

export const dashboardRoutes: AppRouteObject[] = [
  {path: DashboardPaths.Dashboard, element: <HomeDashboard />},
  {path: DashboardPaths.CompanyDashboard, element: <TilesPage />},
  {path: DashboardPaths.SiteDashboard, element: <TilesPage />},
  {path: DashboardPaths.InstallationDashboard, element: <LazyInstallationDashboard />},
  {path: DashboardPaths.AssetDetails, element: <LazyAssetDetails />}
];

export const relativeDashboardRoutes = makeRoutesRelative(dashboardRoutes, DashboardPaths.Dashboard);
const DashboardRoutes = (_: DashboardRoutesProps) => (
  <>
    <Suspense fallback={<div/>}>
      <Outlet />
    </Suspense>
  </>
);

export default DashboardRoutes;
