import {type LoginRequest, userStore, type UserWithToken} from '../common/redux/entities/user';
import {api, setToken} from './index';
import {type AppDispatch} from '../redux/store';

export interface PasswordResetRequest {
  email: string;
  passwordResetToken: string;
  password: string;
  confirmPassword: string;
}

export const logout = () => (dispatch: AppDispatch) => {
  localStorage.removeItem('token');
  dispatch(userStore.actions.setCurrentUser(null));
};

export async function login(user: LoginRequest) {
  const res = await api.post<UserWithToken>('authentication', user);
  setToken(res.data.token);
  return res;
}

export async function forgotPassword(email: string) {
  return (await api.post<void>('/user/forgotPassword/' + email)).data;
}

export async function validateToken(token: string) {
  return (await api.get<void>('/user/validateToken/' + token)).data;
}

export async function resetPassword(resetRequest: PasswordResetRequest) {
  return (await api.post<void>('/user/resetPassword', resetRequest)).data;
}

export async function getCurrentUserFromServer() {
  return (await api.get<void>('/user/current')).data;
}
