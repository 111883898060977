import {entities, type EntitiesActions} from './entities';
import {type Action as ReduxAction, type Dispatch} from 'redux';
import {type BatchAction} from 'redux-batched-actions';
import {type RootState} from '../../redux/store';

/**
 * Purposefully only returning a plain object, combineReducers will be called later.
 */
export const commonReducers = {
    entities
};

export type CommonState = RootState;

export default interface Action<T = any, D = any> extends ReduxAction<T> {
  type: T;
  data: D;
}

export type CommonActions = EntitiesActions | BatchAction ;
export type CommonDispatch = Dispatch<CommonActions>;

export type GetCommonState = () => CommonState;
