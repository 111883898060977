import {combineReducers} from 'redux';
import {type CommonState} from '../index';
import type Action from '../index';
import {type ConvertToItems} from '../../types/util';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';

export interface RmxBoxType {
  id: number;
  name: string;
  digitalInputCount: number;
  analogInputCount: number;
  digitalOutputCount: number;
  analogOutputCount: number;
}

export type RmxBoxTypeItems = ConvertToItems<RmxBoxType>;

export interface RmxBoxTypeState {
  items: RmxBoxTypeItems;
}
export type SetRmxBoxTypesAction = Action<'SET_RMX_BOX_TYPES', RmxBoxTypeItems>;
export const setRmxBoxTypes = (items: RmxBoxTypeItems): SetRmxBoxTypesAction => ({type: 'SET_RMX_BOX_TYPES', data: items});

export type RmxBoxTypeActions = SetRmxBoxTypesAction ;

function rmxBoxTypeItems(state: RmxBoxTypeItems = {}, action: RmxBoxTypeActions) {
  switch (action.type) {
    case 'SET_RMX_BOX_TYPES': return action.data;
    default: return state;
  }
}

export const rmxBoxTypes = combineReducers<RmxBoxTypeState>({
  items: rmxBoxTypeItems
});
export const getRmxBoxTypeItems = (state: CommonState) => state.entities.rmxBoxTypes.items;
export const getRmxBoxTypesAsDropDownItems = (state: CommonState): DropdownOption[] =>
  Object.values(getRmxBoxTypeItems(state)).map(t => ({value: t.id, label: t.name}));

export const getRmxBoxTypeById = (state: CommonState) => (id: number): RmxBoxType => getRmxBoxTypeItems(state)[id];
