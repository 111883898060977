import {type CommonDispatch} from '../index';
import {combineReducers} from 'redux';
import {batchAppActions, createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createAction, createReducer} from 'typesafe-actions';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {type SpTemplateForm, type SpTemplateRevisionActions, spTemplateRevisionStore} from './spTemplateRevision';
import {getSpTemplateRevisions, saveSpTemplateForm, type SpTemplateSaveResponse} from 'src/api/installationModelTemplateApi';

export interface SpTemplate  extends SpTemplateFormFields {
  id: number;
  latestRevisionId: number;
  revisionIds: number[];
}

export interface SpTemplateFormFields {
  name: string;
  systemProcessTypeId: number;
}

const cloneActions = {
  clear: createAction('CLEAR_SP_TEMPLATE_TO_CLONE')(),
  set: createAction('SET_SP_TEMPLATE_TO_CLONE')<SpTemplateForm>()
};

type CloneActions = GetActions<typeof cloneActions>;

const actions = createStandardActions(placeholder<SpTemplate>(), 'SP_TEMPLATE/SET', 'SP_TEMPLATE/SAVE');
export type SpTemplateActions = GetActions<typeof actions> | CloneActions;

export const spTemplates = combineReducers({
  items: standardItemsReducer<SpTemplate, SpTemplateActions>(actions),
  clone: createReducer<SpTemplateForm|null, CloneActions>(null)
    .handleAction(cloneActions.set, (state, action) => action.payload)
    .handleAction(cloneActions.clear, () => null)
});
const selectors = createStandardSelectors(placeholder<SpTemplate>(), s => getEntities(s).spTemplates);

export const spTemplateStore = {
  selectors: {
    ...selectors,
    getClone: selector(s => selectors.getState(s).clone),
    getRevisionsByTemplate: selector(s => (id: number) =>
      selectors.getById(s)(id).revisionIds.map(rId => spTemplateRevisionStore.selectors.getById(s)(rId))),
    getLatest: selector(s => (id: number) =>
      spTemplateRevisionStore.selectors.getById(s)(selectors.getById(s)(id).latestRevisionId))
  },
  actions: {
    ...actions,
    cloneActions,
    upsert: (spTemplateRevision: SpTemplateForm) => async (dispatch: CommonDispatch) => {
      const response: SpTemplateSaveResponse = await saveSpTemplateForm(spTemplateRevision);
      await dispatch(batchAppActions<SpTemplateRevisionActions | SpTemplateActions>([
        spTemplateRevisionStore.actions.save(response.revision),
        spTemplateStore.actions.save(response.template)
      ]));
      return response;
    },
    loadRevisions: (templateId: number) => async (dispatch: CommonDispatch) => {
      const response = await getSpTemplateRevisions(templateId);
      await dispatch(batchAppActions<SpTemplateRevisionActions | SpTemplateActions>([
        spTemplateStore.actions.save(response.template),
        spTemplateRevisionStore.actions.merge(response.revisions)
      ]));
      return response.revisions;
    }
  }
} as const;
