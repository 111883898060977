import {type Stringify} from 'src/util';
import {AxiosError} from 'axios';
import {type SetErrors} from 'src/pages/Configuration/GlobalConfiguration/pages/DataPointEditorRouter/pages/shared-methods';
import {type FormikErrors} from 'formik';
import {reportException} from 'src/util/sentryProvider';

export function isBadRequest(e: any): e is AxiosError {
  return getErrorStatus(e) === 400;
}

export function isServerError(e: any) {
  return getErrorStatus(e) >= 500 && getErrorStatus(e) < 600;
}

export function isUnknownError(e: any) {
  return getErrorStatus(e) === 0;
}

export function getErrorStatus(e: unknown): number {
  if(isAxiosError(e)) {
    if(e.response)
      return e.response.status;
    if(e.request instanceof XMLHttpRequest) {
      return e.request.status;
    }
  }
  return -1;
}

export type ErrorResponse<T> = {
  message: string;
} & Stringify<T>;

export function getResponseData<T>(e: AxiosError): T|undefined {
  return e.response ? e.response.data as T : undefined;
}

export function getErrorResponseMessage(e: AxiosError): string {
  return (e?.response?.data as {message: string}).message || '';
}

export interface AxiosErrorMessageParams {
  connectionMsg?: string;
  badRequestMsg?: string;
  four04Msg?: string;
  forbiddenMsg?: string;
  unauthorized?: string;
  serverError?: string;
}

/**
 * Converts a dictionary of keys with "." notation into an object structure
 *
 * eg.
 *
 * {
 *   "item.revisionTitle": ["Required"],
 *   "items[0].name": ["Required"]
 * }
 *
 * into
 *
 * {
 *   "item": {
 *     "revisionTitle": ["Required"]
 *   },
 *   "items": [
 *     {
 *       "name": ["Required"]
 *     }
 *   ]
 * }
 * @param errorsDictionary
 */
function convertAspNetCoreErrorsToFormikErrors<T extends {} = {}>(errorsDictionary: {[key: string]: string[]}): FormikErrors<T> {
  const keys = Object.keys(errorsDictionary);
  const errors: FormikErrors<any> = {};
  for (const key of keys) {
    const currentError = errorsDictionary[key];
    // matches item(.)property, item[(0)].property, and item[(0)](end of string)
    const keyParts = key.split(new RegExp(/\.|\[(\d)\]\.|\[(\d)\]$/)).filter(k => Boolean(k));
    let currentErrors = errors;
    for (let i = 0; i < keyParts.length; i++) {
      const nextKey = (i + 1) < keyParts.length ? keyParts[i + 1] : undefined;
      const currentKey = keyParts[i];

      if (currentErrors[currentKey] === undefined && nextKey !== undefined) {
       if (!isNaN(parseInt(nextKey, 10))) {
         currentErrors[currentKey] = [];
       } else {
         currentErrors[currentKey] = {};
       }
      }
      // hit tail
      if (nextKey === undefined) {
        currentErrors[currentKey] = currentError;
      } else {
        // @ts-ignore
        currentErrors = currentErrors[currentKey];
      }
    }
  }
  return errors;

}


function isAxiosError(e: unknown): e is AxiosError<{message: string; errors: {[key: string]: string[]}}> {
  return typeof(e) === 'object' && e !== null && ('response' in e || 'request' in e);
}

export function get400ResponseOrNull<TResponse>(e: unknown) {
  if (isAxiosError(e) && e.response?.status === 400) {
    return e.response.data as TResponse;
  }
  return null;
}

export function handleAxiosError(e: unknown, params: AxiosErrorMessageParams = {}, setErrors?: SetErrors<any>) {
  const connectionMsg = params.connectionMsg ?? 'Could not connect. Please check your internet connection.';
  const is401Or400 = isAxiosError(e) && (e.response?.status === 401 || e.response?.status === 400);
  // tslint:disable-next-line:no-console
  console.error(e);
  if (!isAxiosError(e)) {
    reportException(e);
    return 'Unknown Error';
  }

  if (!is401Or400 && e.code !== AxiosError.ERR_NETWORK) {
    reportException(e);
  }

  if (e.code === AxiosError.ERR_NETWORK) {
    // tslint:disable-next-line:no-console
    console.error(e);
    return connectionMsg;
  }

  if (e.response) {
    if (e.response.status === 400) {
      const defaultMessage = typeof e.response.data === 'object' && e.response.data.message
        ? e.response.data.message
        : typeof e.response.data === 'string'
          ? e.response.data
          : 'Bad Request';
      if (e.response.data.errors && setErrors) {
        const errors = e.response.data.errors;
        setErrors(convertAspNetCoreErrorsToFormikErrors(errors));
        if (Array.isArray(errors[''])) {
          return errors[''].join('\n');
        }
        return '';
      }
      return params.badRequestMsg || defaultMessage;
    } else if (e.response.status === 401) {
      return params.unauthorized || 'Unauthorized';
    } else if (e.response.status === 403) {
      return params.forbiddenMsg || 'Forbidden';
    } else if (e.response.status === 404) {
        return params.four04Msg || 'Not Found';
    } else if (e.response.status === 500) {
      // tslint:disable-next-line:no-console
      console.error(e);
      return params.serverError || 'A server error occurred, please try again later.';
    }
    else if (e.response.status === 0) {
      // tslint:disable-next-line:no-console
      console.error(e);
      return connectionMsg;
    } else {
      // tslint:disable-next-line:no-console
      console.error(e);
      return 'Unknown Error';
    }
  } else if (e.request instanceof XMLHttpRequest) {
    if (e.request.status === 0) {
      return connectionMsg;
    } else {
      // tslint:disable-next-line:no-console
      console.error(e);
      return 'Unknown Error';
    }
  }
  throw e;
}
