import {type ManufacturerOSLabelActions, manufacturerOSLabels} from './manufacturerOSLabel';
import {type OSConversionActions, osConversions} from './osConversion';
import {combineReducers} from 'redux';
import {type OSCodeConversionRevision, type OSConversionRevision, type OSConversionRevisionActions, osConversionsRevisions} from './osConversionRevision';
import {type OSBitByBitConversionRevision} from './osBitByBitConversionRevision';
import {getEnumValues} from 'src/common/util';
import {type CommonConversion, type CommonConversionFields} from '../index';

export const operationalStatusEntities = combineReducers({
  manufacturerLabels: manufacturerOSLabels,
  conversions: osConversions,
  conversionRevisions: osConversionsRevisions
});

export type OperationalStatusEntities = ReturnType<typeof operationalStatusEntities>;

export type OperationalStatusActions =
  ManufacturerOSLabelActions |
  OSConversionActions |
  OSConversionRevisionActions;

export interface OSConversionForm<T extends OSConversionRevision = OSConversionRevision> {
  conversion: OsConversionFormFields;
  revision: T;
}

export interface OSCodeConversionForm extends OSConversionForm<OSCodeConversionRevision> {}
export interface OSBitByBitConversionForm extends OSConversionForm<OSBitByBitConversionRevision> {}

export interface OSAssignment {
  id: number;
  osDataPointId: number;
  osCodeCheckId?: number;
  manufacturerOSLabelId: number|null;
  useDefaultLabel: boolean;
  state: boolean;
}

export interface OSConversion extends CommonConversion {
  revisionIds: number[];
  type: OsTypes;
}

export interface OsConversionFormFields extends CommonConversionFields {
  type: OsTypes;
}

export interface CommonOSCheck {
  id: number;
  registerDataPointId: number;
}

export enum OsType {
  CodeConversion = 'CodeConversion',
  BitByBitConversion = 'BitByBitConversion'
}
export type OsTypes = keyof typeof OsType;

export const isOSConversion =
  (conversion: CommonConversion): conversion is OSConversion =>
    getEnumValues(OsType).includes((conversion as OSConversion).type);

export const isOsCodeConversion = (c: OSConversion) => c.type === 'CodeConversion';
export const isOsBitByBitConversion = (c: OSConversion) => c.type === 'BitByBitConversion';

export function getOSConversionTypeLabel(type: OsTypes) {
  const label =  type === 'CodeConversion' ? 'Code' :
    type === 'BitByBitConversion' ? 'Bit By Bit' : null;
  if (label === null) {
    throw new Error(`OS Conversion Type: ${type} is not supported`);
  }
  return label;
}
