import {combineReducers} from 'redux';
import {type CommonDispatch, type CommonState} from '../../../index';
import {type OSConversionRevisionActions, osConversionRevisionStore} from './osConversionRevision';
import {convertToDropDownOptions} from 'src/common/util';
import {type OSConversion} from './index';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {createStandardSelectors, getEntities} from '../../../selectors';
import {batchAppActions, createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../../../utils';
import {getOsConversionRevisions} from 'src/api/installationModelTemplateApi';

export interface OSCodeConversion extends OSConversion {type: 'CodeConversion' }

const actions = createStandardActions(placeholder<OSConversion>(), 'OS_CONVERSION/SET', 'OS_CONVERSION/SAVE');
export type OSConversionActions = GetActions<typeof actions>;

export const osConversions = combineReducers({items: standardItemsReducer<OSConversion, OSConversionActions>(actions)});
const selectors = createStandardSelectors(placeholder<OSConversion>(), s => getEntities(s).operationalStatus.conversions);

// Code selectors
export const getOSCodeConversionsAsArray = (state: CommonState): OSCodeConversion[] =>
  osConversionStore.selectors.getAsArray(state).filter(c => c.type === 'CodeConversion') as OSCodeConversion[];

export const getOSCodeConversionsAsOptions = (state: CommonState): DropdownOption[] =>
  convertToDropDownOptions(osConversionStore.selectors.getAsArray(state));

export const osConversionStore = {
  selectors: {
    ...selectors,
    getRevisions: (state: CommonState) => (id: number) =>
      osConversionStore.selectors.getById(state)(id).revisionIds.map(rId => osConversionRevisionStore.selectors.getById(state)(rId)),
    getLatestRevision: (state: CommonState) => (id: number) =>
      osConversionRevisionStore.selectors.getById(state)(osConversionStore.selectors.getById(state)(id).latestRevisionId)
  },
  actions: {
    ...actions,
    loadRevisions: (templateId: number) => async (dispatch: CommonDispatch) => {
      const response = await getOsConversionRevisions(templateId);
      await dispatch(batchAppActions<OSConversionRevisionActions | OSConversionActions>([
        osConversionStore.actions.save(response.template),
        osConversionRevisionStore.actions.merge(response.revisions)
      ]));
      return response.revisions;
    },
    loadManyRevisions: (templateIds: number[]) => async (dispatch: CommonDispatch) => {
      for (const templateId of templateIds) {
        await dispatch(osConversionStore.actions.loadRevisions(templateId) as any);
      }
    }
  }
} as const;
