import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {type SystemProcess} from './installationEntities/spRevision';

const actions = createStandardActions(placeholder<SystemProcess>(), 'SYSTEM_PROCESS/SET', 'SYSTEM_PROCESS/SAVE');
export type SystemProcessActions = GetActions<typeof actions>;
export const systemProcesses = combineReducers({items: standardItemsReducer<SystemProcess, SystemProcessActions>(actions)});
export const systemProcessStore = {
  selectors: createStandardSelectors(placeholder<SystemProcess>(), s => getEntities(s).systemProcesses),
  actions: {
    ...actions
  }
} as const;
