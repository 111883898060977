import {combineReducers} from 'redux';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';

export interface Carrier {
  id: number;
  name: string;
}

const actions = createStandardActions(placeholder<Carrier>(), 'CARRIER/SET', 'CARRIER/SAVE');
const selectors = createStandardSelectors(placeholder<Carrier>(), s => getEntities(s).carriers);
export type CarrierActions = GetActions<typeof actions>;
export const carriers = combineReducers({items: standardItemsReducer<Carrier, CarrierActions>(actions)});

export const carrierStore = {
  selectors: {...selectors},
  actions: {...actions}
} as const;
