import React from 'react';
import {makeUrl} from 'src/common/util';
import {Link} from 'src/router';
import {type BreadCrumbData} from 'src/api/userDashboardApi';
import {DashboardPaths} from 'src/pages/Dashboard/DashboardRoutePaths';
import s from 'src/pages/Dashboard/DashboardBreadCrumbs/DashboardBreadCrumbs.module.scss';
import clsx from 'clsx';

type Props = {
  breadCrumbs: BreadCrumbData[];
  className?: string;
};

export type IBreadCrumb = {
  href?: string;
  label: string;
  disabled: boolean;
};

function getBreadCrumbs(p: BreadCrumbData[]): IBreadCrumb[]  {
  const arr: IBreadCrumb[] = [];
  arr.push(...p.map<IBreadCrumb>(b => makeBreadCrumbFromData(b)));
  if(arr.length > 1)
    arr[arr.length - 1].href = undefined;
  return arr;
}


export function makeBreadCrumbFromData(p: BreadCrumbData): IBreadCrumb {
  return ({label: p.label, href: getUrlPathByType(p), disabled: p.disabled});
}

export function getPreviousBreadCrumb<T>(b: T[]): T {
  if(b.length === 0)
    throw new Error('No previous bread crumb');
  if(b.length >= 2)
    return b[b.length - 2];
  return b[0];
}

export function getPreviousBreadCrumbLink(b?: BreadCrumbData[]): string|undefined {
  if(b === undefined)
    return undefined;
  if(b.length <= 1)
    return undefined;
  return getUrlPathByType(getPreviousBreadCrumb(b));
}

export function getUrlPathByType(b: BreadCrumbData) {
  return makeUrl(getUrlBasePathByType(b), {id: b.id, name: b.label});
}

export function getUrlBasePathByType(b: BreadCrumbData) {
  const t = b.type;
  switch (t) {
    case 'Company': return DashboardPaths.CompanyDashboard;
    case 'Site': return DashboardPaths.SiteDashboard;
    case 'Installation': return DashboardPaths.InstallationDashboard;
    case 'SystemProcess': return DashboardPaths.SystemProcessDashboard;
    case 'Asset': return DashboardPaths.AssetDetails;
    case 'Global': return DashboardPaths.Dashboard;
    default: throw new Error('Unknown BreadCrumbDataType: ' + t);
  }
}

export function DashboardBreadCrumbs({breadCrumbs: breadCrumbsParam, className}: Props) {
  if(breadCrumbsParam.length === 0)
    return null;
  const breadCrumbs = getBreadCrumbs(breadCrumbsParam);
  return <div className={clsx(s['bread-crumbs'], className)}>
    {breadCrumbs.map((b, i) => <React.Fragment key={b.href ?? ''}>
      <BreadCrumb breadCrumb={b}  />
      {i !== breadCrumbs.length -1 && <BreadCrumbSeparator />}
    </React.Fragment>)}
  </div>;
}

type BreadCrumbProps = {
  breadCrumb: IBreadCrumb;
};

function BreadCrumb({breadCrumb: {href, label, disabled}}: BreadCrumbProps) {
  return href && !disabled
    ? <Link className={s['breadcrumb']} to={href}>{label}</Link>
    : <span className={s['breadcrumb']}>{label}</span>;
}

function BreadCrumbSeparator(_: {}) {
  return <span className={s['separator']}>{'>'}</span>;
}
