import {type EffectCallback, type MutableRefObject, useEffect, useRef} from 'react';

export function useMount(func: EffectCallback | (() => Promise<any>) | (() => any)) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {func(); }, []);
}

export function useMountedRef(): MutableRefObject<boolean> {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);
  return mounted;
}


export function useExecuteOnMount(func: () => any | Promise<any>) {
  const mounted = useRef(false);
  useEffect(() => {
     if (!mounted.current) {
      mounted.current = true;
      func();
    }
  }, [func]);
}
