import {api} from './index';
import {type User, type UserPreferences} from '../common/redux/entities/user';
import {type UserManagementResponse} from '../common/redux/userManagement';
import {type UserPreferencesResponse} from '../common/redux/userPreferences';
import {type UserAlertPreference} from '../common/redux/entities/userAlertPreference';

export async function getUserManagementState() {
  return (await api.get<UserManagementResponse>('/UserManagement')).data;
}

export async function getUserPreferencesState() {
  return (await api.get<UserPreferencesResponse>('/UserPreferences')).data;
}

export async function getUsers() {
  return (await api.get<User[]>('/User')).data;
}

export async function archiveUser(id: string) {
  return (await api.post<User>(`/User/${id}/archive`)).data;
}

export async function restoreUser(id: string) {
  return (await api.post<User>(`/User/${id}/restore`)).data;
}

export async function upsertUser(form: User) {
  return (await api.put<User>('/User', form)).data;
}

export async function updateUserPreferences(form: UserPreferences) {
  return (await api.post<User>('/UserPreferences', form)).data;
}

export async function upsertUserAlertPreference(form: UserAlertPreference) {
  return (await api.post<UserAlertPreference>('/UserPreferences/Alert', form)).data;
}

export async function deleteUserAlertPreference(id: string) {
  return (await api.delete<UserAlertPreference>('/UserPreferences/Alert/' + id)).data;
}
