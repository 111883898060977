import React, {type ComponentType} from 'react';
import {type LazyRouteFunction, type RouteObject} from 'react-router-dom';


export function getLazyComponent<P>(factory: () => Promise<{ default: ComponentType<P> }>) {
  return React.lazy<ComponentType<P>>(factory);
}

export function getLazyComponentFromModule<T, P>(factory: () => Promise<T>, map: (module: T) => ComponentType<P>) {
  return React.lazy<ComponentType<P>>(async () => ({default: map((await factory()))}) );
}




export function lazy<T, C extends ComponentType<any>>(importedComponent: Promise<T>, selector: (file: T) => C) {
  return (async () => {
    const file = await importedComponent;
    const Component = selector(file);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return {Component: Component};
  }) satisfies LazyRouteFunction<RouteObject>;
}
