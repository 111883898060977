import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {type CommonDispatch} from '../index';
import {upsertDealer} from 'src/api/globalConfigApi';
import {type UuidModel} from 'src/common/types/UuidModel';

export interface Dealer extends UuidModel {
  id: number;
  name: string;
}

const actions = createStandardActions(placeholder<Dealer>(), 'DEALER/SET', 'DEALER/SAVE');
export type DealerActions = GetActions<typeof actions>;
export const dealers = combineReducers({items: standardItemsReducer<Dealer, DealerActions>(actions)});

export const dealerStore = {
  selectors: createStandardSelectors(placeholder<Dealer>(), s => getEntities(s).dealers),
  actions: {
    ...actions,
    upsert: (dealer: Dealer) => async (dispatch: CommonDispatch) => {
      const response = await upsertDealer(dealer);
      dispatch(actions.save(response));
      return response;
    }
  }
} as const;
