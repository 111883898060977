import {api, type TemplateSaveResponse} from '../index';
import {type AssetTemplateRevision} from 'src/common/redux/entities/assetTemplateRevision';
import {type AlertConversionRevision} from 'src/common/redux/entities/conversions/alertConversionRevision';
import {type AlertConversionWithAssignedStatus} from 'src/common/redux/entities/conversions/alertConversion';
import {
  type AlertBitByBitAssignmentGroup,
  type AlertBitByBitAssignmentGroupForm
} from 'src/common/redux/entities/alertBitByBitAssignmentGroup';
import {type RevisionStatus} from 'src/common/types/Revision';
import {type AlertBitByBitAssignmentGroupRevision} from 'src/common/redux/entities/alertBitByBitAssignmentGroupRevisions';
import {type IdDictionary} from 'src/common/util';

const baseUrl = '/AlertBitByBitAssignmentGroup';

type AlertBitByBitAssignmentGroupActivationResponse = {
  assetTemplateRevisions: AssetTemplateRevision[];
  alertConversionRevisions: AlertConversionRevision[];
};

export async function activateAlertBitByBitAssignmentGroup(revisionId: number, alertConversionIds: number[], all?: boolean, activate?: boolean) {
  return (await api.post<AlertBitByBitAssignmentGroupActivationResponse>(`${baseUrl}/${revisionId}/ApplyAssignments`, {
    alertConversionIds: alertConversionIds,
    all: all || false,
    activate: activate || false
  })).data;
}

export async function getAlertBitByBitAssignmentGroupAlertConversionStatuses(revisionId: number) {
  return (await api.get<AlertConversionWithAssignedStatus[]>(`${baseUrl}/${revisionId}/AlertConversionStatuses`)).data;
}

export async function getAlertBitByBitAssignmentGroupRevisionStatuses(id: number) {
  return (await api.get<RevisionStatus[]>(`${baseUrl}/${id}/Statuses`)).data;
}

export interface AlertBitByBitAssignmentGroupSaveResponse extends TemplateSaveResponse<AlertBitByBitAssignmentGroup, AlertBitByBitAssignmentGroupRevision> {
}

export async function saveAlertBitByBitAssignmentGroup(form: AlertBitByBitAssignmentGroupForm): Promise<AlertBitByBitAssignmentGroupSaveResponse> {
  return (await api.put<AlertBitByBitAssignmentGroupSaveResponse>(baseUrl, form)).data;
}

export async function getAlertBitByBitAssignmentGroupRevisions(alertBitByBitAssignmentGroupId: number):
  Promise<IdDictionary<AlertBitByBitAssignmentGroupRevision>> {
  return (await api.get<IdDictionary<AlertBitByBitAssignmentGroupRevision>>(`${baseUrl}/${alertBitByBitAssignmentGroupId}/Revisions`)).data;
}

export async function getAlertBitByBitAssignmentGroupRevisionsByGroupIds(
  alertBitByBitAssignmentGroupIds: number[]): Promise<IdDictionary<AlertBitByBitAssignmentGroupRevision>> {
  return (await api.post<IdDictionary<AlertBitByBitAssignmentGroupRevision>>(`${baseUrl}/Revisions`, alertBitByBitAssignmentGroupIds)).data;
}
