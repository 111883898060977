import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {type RevisionEntity} from '../../../types/Revision';
import {type AlertBitByBitCheckGroup, type AlertCodeCheckGroup, type AlertConversionForm, type AlertConversionType, type AlertOneToOneCheck} from './alertConversion';
import {type CommonDispatch} from '../../index';
import {type AssignmentWithOutputs, type DataPointTemplateAssignment, remapDataPoints, type TriggerDPTA} from '../../abstract/dataPointAssignment';
import {immutableRemoveProperty, removePropertyRecursively} from '../../../util/object';
import {cast, type IdDictionary} from '../../../util';
import {type CommonConversionRevision} from './operational-statuses/osConversionRevision';
import {createAction} from 'typesafe-actions';
import {getAlertConversionRevisions, getManyAlertConversionRevisions} from 'src/api/alerts/alertConversionApi';

export interface AlertConversionRevision extends CommonConversionRevision {
  hasEverBeenAssigned: boolean;
  createdAt?: string;
  updatedAt?: string;
  type: AlertConversionType;
  alertConversionId: number;
  validateDuplicateChecks: boolean;
}

export function convertAlertConversionFormToData(form: AlertConversionForm): AlertConversionForm {
  if(isAlertOneToOneConversionRevisionForm(form.revision)) {
    return {
      ...form,
      revision: convertAlertOneToOneConversionFormRevisionToRevision(form.revision)
    };
  }
  return form;
}

export const isAlertCodeConversionRevision =
  (revision: AlertConversionRevision): revision is AlertCodeConversionRevision => revision.type === 'AlertCodeConversion';
export const isAlertBitByBitConversionRevision =
  (revision: AlertConversionRevision): revision is AlertBitByBitConversionRevision => revision.type === 'AlertBitByBitConversion';
export const isAlertOneToOneConversionRevisionForm =
  (revision: AlertConversionRevision): revision is AlertOneToOneConversionRevisionForm => revision.type === 'AlertOneToOneConversion';

export const isAlertOneToOneConversionRevision =
  (revision: AlertConversionRevision): revision is AlertOneToOneConversionRevision => revision.type === 'AlertOneToOneConversion';
export const isAlertConversionWithTriggers =
  (revision: AlertConversionRevision): revision is AlertConversionWithTriggers => 'triggers' in revision;

export const isAlertConversionRevision =
  (revision: RevisionEntity): revision is AlertConversionRevision => 'alertConversionId' in revision;

export interface AlertCodeConversionRevision extends AlertConversionWithTriggers {
  alertCodeCheckGroups: AlertCodeCheckGroup[];
  manufacturerAlertCodeRegisters: DataPointTemplateAssignment[];
  type: 'AlertCodeConversion';
}

export type TriggerWithOneToOneCheck = TriggerDPTA & {alertOneToOneCheck: AlertOneToOneCheck};

export function isTriggerWithOneToOneCheck(values: AssignmentWithOutputs): values is TriggerWithOneToOneCheck {
  return 'alertOneToOneCheck' in values;
}

export function mapTriggerWithOneToOneCheck(v: TriggerWithOneToOneCheck): TriggerWithOneToOneCheck {
  return {...v, alertOneToOneCheck: {...v.alertOneToOneCheck, triggerUuid: v.dataPoint.uuid!}};
}
export interface AlertOneToOneConversionRevision extends AlertConversionRevision, AlertConversionWithTriggers {
  oneToOneAlertChecks: AlertOneToOneCheck[];
}

export function convertAlertOneToOneConversionFormRevisionToRevision(form: AlertOneToOneConversionRevisionForm): AlertOneToOneConversionRevision {
  const triggers = form.triggers.map<TriggerDPTA>(t => immutableRemoveProperty(t, 'alertOneToOneCheck'));
  const alertOneToOneChecks = form.triggers.map<AlertOneToOneCheck>(t => t.alertOneToOneCheck);
  return {...form, triggers, oneToOneAlertChecks: alertOneToOneChecks};
}
export interface AlertOneToOneConversionRevisionForm extends AlertConversionRevision, AlertConversionWithTriggers {
  type: 'AlertOneToOneConversion';
  triggers: TriggerWithOneToOneCheck[];
}

export interface AlertConversionWithTriggers extends AlertConversionRevision, TriggersTemplate {

}

export interface TriggersTemplate {
  triggers: TriggerDPTA[];
}
export const isTriggersTemplate = (values: {}): values is TriggersTemplate => values.hasOwnProperty('triggers');

export interface AlertBitByBitConversionRevision extends AlertConversionRevision {
  type: 'AlertBitByBitConversion';
  alertStatusAssignments: DataPointTemplateAssignment[];
  alertBitByBitCheckGroups: AlertBitByBitCheckGroup[];
}

const actions = createStandardActions(placeholder<AlertConversionRevision>(), 'ALERT_CONVERSION_REVISION/SET', 'ALERT_CONVERSION_REVISION/SAVE');
const merge = createAction('ALERT_CONVERSION_REVISION/MERGE')<IdDictionary<AlertConversionRevision>>();
export type AlertConversionRevisionActions = GetActions<typeof actions> | ReturnType<typeof merge>;
export const alertConversionRevisions = combineReducers({
  items: standardItemsReducer<AlertConversionRevision, AlertConversionRevisionActions>(actions)
    .handleAction(merge, (state, action) => ({...state, ...action.payload}))
});
const selectors = createStandardSelectors(placeholder<AlertConversionRevision>(), s => getEntities(s).alertConversionRevisions);
/**
 * This store does not load all the entities from the database and instead loads only the revisions for an individual conversion
 */
export const alertConversionRevisionStore = {
  selectors: {
    ...selectors,
    getRevisionForConversion: selector(s => (id: number) => selectors.getAsArray(s).filter(r => r.alertConversionId === id))
  },
  actions: {
    ...actions,
    merge,
    loadRevisions: (alertConversionId: number) => async (dispatch: CommonDispatch) => {
      const revisions = await getAlertConversionRevisions(alertConversionId);
      await dispatch(merge(revisions));
      return revisions;
    },
    loadManyRevisions: (alertConversionIds: number[]) => async (dispatch: CommonDispatch) => {
      const revisions = await getManyAlertConversionRevisions(alertConversionIds);
      await dispatch(merge(revisions));
      return revisions;
    }
  }
} as const;

export function cloneAlertConversionRevision(rev: AlertConversionRevision): AlertConversionRevision {
  const idsRemoved = {...removePropertyRecursively(rev, 'id'), alertConversionId: 0};
  if (isAlertBitByBitConversionRevision(idsRemoved)) {
    const {remappedDps: remappedAlertStatuses, dpsDictionary: alertStatusDict} = remapDataPoints(idsRemoved.alertStatusAssignments);
    return cast<AlertBitByBitConversionRevision>({
      ...idsRemoved,
      alertStatusAssignments: remappedAlertStatuses,
      alertBitByBitCheckGroups: idsRemoved.alertBitByBitCheckGroups.map<AlertBitByBitCheckGroup>(g => ({
        ...g,
        alertStatusDataPointUuid: alertStatusDict[g.alertStatusDataPointUuid].uuid!
      }))
    });
  }
  if (isAlertConversionWithTriggers(idsRemoved)) {

    if (isAlertCodeConversionRevision(idsRemoved)) {
      const {remappedDps: remappedTriggers, dpsDictionary: triggersDict} = remapDataPoints(idsRemoved.triggers);
      const {remappedDps:remappedRegisters, dpsDictionary: registerdDict} = remapDataPoints(idsRemoved.manufacturerAlertCodeRegisters);
      return cast<AlertCodeConversionRevision>({
        ...idsRemoved,
        triggers: remappedTriggers,
        manufacturerAlertCodeRegisters: remappedRegisters,
        alertCodeCheckGroups: idsRemoved.alertCodeCheckGroups.map(g => ({
          ...g,
          alertCodeChecks: g.alertCodeChecks.map(c => ({
            ...c,
            triggerUuid: c.hasTrigger && c.triggerUuid ? triggersDict[c.triggerUuid!].uuid : null,
            manufacturerAlertCodeDataPointUuid: registerdDict[c.manufacturerAlertCodeDataPointUuid].uuid!
          }))
        }))
      });
    }
    if (isAlertOneToOneConversionRevisionForm(idsRemoved)) {
      const {remappedDps: remappedTriggers} = remapDataPoints(idsRemoved.triggers);
      return cast<AlertOneToOneConversionRevisionForm>({
        ...idsRemoved,
        triggers: remappedTriggers
      });
    }
  }
  return idsRemoved;
}
