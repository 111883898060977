import {combineReducers} from 'redux';
import {
  convertToDropDownOptions,
  convertDictionaryToArray,
  groupBy
} from '../../util';
import {type CommonDispatch, type CommonState} from '../index';
import type Action from '../index';
import {addAssetType, updateAssetType} from 'src/api/groupsAndTypesApi';

export interface AssetType {
  id: number;
  name: string;
  assetGroupId: number;
}

export interface AssetTypeState {
  items: AssetTypeItems;
}

export interface AssetTypeItems {
  [key: number]: AssetType;
}

export type SetAssetTypesAction = {type: 'SET_ASSET_TYPES'; data: AssetTypeItems};
export const setAssetTypes = (items: AssetTypeItems) => ({type: 'SET_ASSET_TYPES', data: items});

export type SaveAssetTypeAction = Action<'SAVE_ASSET_TYPE', AssetType>;
export const saveAssetType = (item: AssetType): SaveAssetTypeAction => ({type: 'SAVE_ASSET_TYPE', data: item});

export const createNewAssetType = (assetType: AssetType) => async (dispatch: CommonDispatch) => {
  const response: AssetType = await addAssetType(assetType);
  dispatch(saveAssetType(response));
  return response;
};

export const updateExistingAssetType = (assetType: AssetType) => async (dispatch: CommonDispatch) => {
  const response: AssetType = await updateAssetType(assetType);
  dispatch(saveAssetType(response));
  return response;
};

export type AssetTypeActions = SetAssetTypesAction | SaveAssetTypeAction;

function assetTypeItems(state: AssetTypeItems = {}, action: AssetTypeActions) {
  switch (action.type) {
    case 'SET_ASSET_TYPES': return action.data;
    case 'SAVE_ASSET_TYPE':
      return {
        ...state,
        [String(action.data.id)]: action.data
      };
    default: return state;
  }
}

export const assetTypes = combineReducers<AssetTypeState>({
  items: assetTypeItems
});
export const getAssetTypeItems = (state: CommonState) => state.entities.assetTypes.items;
export const getAssetTypesAsArray = (state: CommonState): AssetType[] => convertDictionaryToArray(getAssetTypeItems(state));

export const getAssetTypesAsOptions = (state: CommonState) => convertToDropDownOptions(getAssetTypesAsArray(state));

export const getAssetTypesGroupedByAssetGroupId = (state: CommonState) =>
  groupBy(getAssetTypesAsArray(state), (assetType: AssetType) => assetType.assetGroupId);

export const makeGetAssetTypeItemsByAssetGroupId = (state: CommonState) => (assetGroupId: number): AssetType[] => {
  return getAssetTypesAsArray(state).filter(t => t.assetGroupId === assetGroupId);
};
