import {combineReducers} from 'redux';
import {convertArrayToDictionary, convertDictionaryToArray, convertToDropDownOptions, getObjectValues} from 'src/common/util';
import {type CommonDispatch, type CommonState} from '../../../index';
import type Action from '../../../index';
import memoizeOne from 'memoize-one';
import * as api from '../../../../../api/operational-status';
import {reduceToDictionary} from 'src/common/util/array';

export interface ManufacturerOSLabel {
  id: number;
  name: string;
  manufacturerId: number;
  assetGroupId: number;
}

export interface ManufacturerOSLabelList {
  manufacturerId: number;
  assetGroupId: number;
  labels: ManufacturerOSLabel[];
}

export interface ManufacturerOSLabelState {
  items: ManufacturerOSLabelItems;
}

export interface ManufacturerOSLabelItems {
  [key: number]: ManufacturerOSLabel;
}

export type SetManufacturerOperationalStatusLabelsAction = Action<'SET_MANUFACTURER_OS_LABELS', ManufacturerOSLabelItems>;
export const setManufacturerOperationalStatusLabels =
  (items: ManufacturerOSLabelItems): SetManufacturerOperationalStatusLabelsAction =>
    ({type: 'SET_MANUFACTURER_OS_LABELS', data: items
});

export type SaveManufacturerOSLabelAction = Action<'SAVE_MANUFACTURER_OS_LABEL', ManufacturerOSLabel>;
export const saveManufacturerOSLabel = (label: ManufacturerOSLabel): SaveManufacturerOSLabelAction =>
    ({type: 'SAVE_MANUFACTURER_OS_LABEL', data: label});

export type SaveManufacturerOSLabelsAction = Action<'SAVE_MANUFACTURER_OS_LABELS', ManufacturerOSLabelList>;
export const saveManufacturerOSLabels = (list: ManufacturerOSLabelList): SaveManufacturerOSLabelsAction =>
  ({type: 'SAVE_MANUFACTURER_OS_LABELS', data: list});

export const getManufacturerOSLabels = async (dispatch: CommonDispatch) => {
  const labels = await api.getAllManufacturerOperationalStatusLabels();
  await dispatch(setManufacturerOperationalStatusLabels(labels));
};

export const upsertManufacturerOperationalStatusLabels = (manufacturerOperationStatusLabelList: ManufacturerOSLabelList) =>
  async (dispatch: CommonDispatch) => {
    const response: ManufacturerOSLabelList = await api.upsertManufacturerOperationalStatusLabels(manufacturerOperationStatusLabelList);
    await dispatch(  saveManufacturerOSLabels(response));
    return response;
};

export type ManufacturerOSLabelActions =
  SetManufacturerOperationalStatusLabelsAction |
  SaveManufacturerOSLabelAction |
  SaveManufacturerOSLabelsAction;

function manufacturerOSLabelItems(state: ManufacturerOSLabelItems = {}, action: ManufacturerOSLabelActions) {
  switch (action.type) {
    case 'SET_MANUFACTURER_OS_LABELS': return action.data;
    case 'SAVE_MANUFACTURER_OS_LABEL':
      return {
        ...state,
        [String(action.data.id)]: action.data
      };
    case 'SAVE_MANUFACTURER_OS_LABELS': {
      const values = Object.values(state) as ManufacturerOSLabel[];
      const currentRemoved = values.filter(v => !(v.manufacturerId === action.data.manufacturerId && v.assetGroupId === action.data.assetGroupId));
      const addedBackIn = currentRemoved.concat(...action.data.labels);
      return convertArrayToDictionary(addedBackIn, 'id');
    }
    default: return state;
  }
}

export const manufacturerOSLabels = combineReducers<ManufacturerOSLabelState>({
  items: manufacturerOSLabelItems
});

export const getManufacturerOperationalStatusLabelItems =
  (state: CommonState) => state.entities.operationalStatus.manufacturerLabels.items;

export const getManufacturerOperationalStatusLabelsAsArray = (state: CommonState): ManufacturerOSLabel[] =>
  getObjectValues(getManufacturerOperationalStatusLabelItems(state));

export const getUsedAssetGroupByManufacturerId = (state: CommonState) => (manufacturerId: number) =>
  reduceToDictionary(
    getManufacturerOperationalStatusLabelsAsArray(state)
      .filter(l => l.manufacturerId === manufacturerId)
      .map(l => l.assetGroupId),
    l => l,
    _ => true
  );

export const getManufacturerOperationalStatusLabelsAsOptions = (state: CommonState) =>
  convertToDropDownOptions(getManufacturerOperationalStatusLabelsAsArray(state));

export const getManufacturerOperationalStatusLabelsByManufactureAndAssetGroupId =
  (state: CommonState) => memoizeOne((manufacturerId: number, assetGroupId: number) =>
       convertDictionaryToArray(getManufacturerOperationalStatusLabelItems(state))
        .filter(b => b.manufacturerId === manufacturerId)
        .filter(b => b.assetGroupId === assetGroupId));
