import {type AlertDefinition} from 'src/common/redux/entities/alertDefinition';
import {type AlertType} from 'src/common/redux/entities/alertType';
import {api} from '../index';

export async function saveAlertType(alertType: AlertType) {
  return (await api.post<AlertType>('/AlertType', alertType)).data;
}

export async function getAlertDefinition(id: number) {
  return (await api.get<AlertDefinition>(`/AlertDefinition/${id}`)).data;
}

export async function saveAlertDefinition(alert: AlertDefinition) {
  return (await api.put<AlertDefinition>('/AlertDefinition', {
    dpaAlert: alert.type === 'DataPointAssociated' ? alert : null,
    ndpaAlert: alert.type === 'NonDataPointAssociated' ? alert : null
  })).data;
}

export async function upsertAlertDescription(alert: AlertDefinition) {
  return (await api.post<AlertDefinition>('/AlertDefinition/DescriptionAndResponse', {
    dpaAlert: alert.type === 'DataPointAssociated' ? alert : null,
    ndpaAlert: alert.type === 'NonDataPointAssociated' ? alert : null
  })).data;
}
