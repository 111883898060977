
import {RoutePaths} from '../../router/RoutePaths';
import React from 'react';
import {useLocation, Navigate} from 'react-router-dom';

export const RedirectToLogin = (_: {}) => {
  const location = useLocation();
  return (
    <Navigate
      state={{from: location}}
      to={RoutePaths.login}
    />
  );
};
