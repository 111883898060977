import React, {type ReactElement, type ReactNode} from 'react';
import {
  mapIsAuthenticatedToProps,
  type MapIsAuthenticatedToPropsType
} from 'src/common/redux/entities/user';
import {connect} from 'react-redux';
import {RedirectToLogin} from '../RedirectToLogin';

type PrivateRouteProps = MapIsAuthenticatedToPropsType & {children: ReactNode};

function PrivateRouteComponent({authenticated, children}: PrivateRouteProps) {
  return !authenticated ? <RedirectToLogin /> : children as ReactElement;
}

export const PrivateRoute = connect(mapIsAuthenticatedToProps)(PrivateRouteComponent);
