import {api, API_URL} from 'src/api/index';
import {type ParentResourceType} from 'src/common/util/files';

export async function getSignedFileUrl(name: string) {
   return (await api.get<string>('/Files/SignedUrl', {
     params: {
       name: name
     }
   })).data;
}

export function getUploadUrl(parentResourceId: number, parentResourceType: ParentResourceType) {
  const url = new URL(`${API_URL}/Files/Upload`);
  url.searchParams.append('ParentResourceId', String(parentResourceId));
  url.searchParams.append('ParentResourceType', String(parentResourceType));
  return url.toString();
}

export async function removeFile(uuid: string) {
  return (await api.delete<void>(`/Files/${uuid}`)).data;
}

export type StoredFile  = {
  uuid: string;
  displayName: string;
  fileName: string;
  thumbnailFileName: string;
  contentType: string;
  parentResourceId: number;
  parentResourceType: ParentResourceType;
  signedThumbnailUrl: string;
  sizeBytes: number;
};
