import {combineReducers} from 'redux';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import {type CommonDispatch} from 'src/common/redux/index';
import {upsertProtocolAdapter} from 'src/api/globalConfigApi';

export interface ProtocolAdapter {
  id: number;
  name: string;
  description: string;
}

const actions = createStandardActions(placeholder<ProtocolAdapter>(), 'PROTOCOL_ADAPTER/SET', 'PROTOCOL_ADAPTER/SAVE');
const selectors = createStandardSelectors(placeholder<ProtocolAdapter>(), s => getEntities(s).protocolAdapters);
export type ProtocolAdapterActions = GetActions<typeof actions>;
export const protocolAdapters = combineReducers({items: standardItemsReducer<ProtocolAdapter, ProtocolAdapterActions>(actions)});

export const protocolAdapterStore = {
  selectors: {...selectors},
  actions: {
  ...actions,
  upsert: (payload: ProtocolAdapter) => async (dispatch: CommonDispatch) => {
      const response = await upsertProtocolAdapter(payload);
      dispatch(actions.save(response));
      return response;
    }
  }
} as const;
