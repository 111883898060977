import {cast, type IdDictionary} from 'src/common/util';
import {type RevisionStatus} from 'src/common/types/Revision';
import {type AlertConversion, type AlertConversionFields, type AlertConversionForm} from 'src/common/redux/entities/conversions/alertConversion';
import {
  type AlertBitByBitConversionRevision,
  type AlertCodeConversionRevision,
  type AlertConversionRevision, type AlertOneToOneConversionRevisionForm,
  isAlertBitByBitConversionRevision,
  isAlertCodeConversionRevision, isAlertOneToOneConversionRevisionForm
} from 'src/common/redux/entities/conversions/alertConversionRevision';
import {type AlertDescription} from 'src/common/redux/entities/alertDefinition';
import {type AssetTemplateRevision} from 'src/common/redux/entities/assetTemplateRevision';
import {type AssetTemplateWithAssignedStatus} from 'src/common/redux/entities/assetTemplate';
import {api, type TemplateSaveResponse} from '../index';

export async function getAlertConversionRevisionStatuses(conversionId: number) {
  return (await api.get<RevisionStatus[]>(`/AlertConversion/${conversionId}/Statuses`)).data;
}

export async function getAlertConversionAssetTemplateStatuses(revisionId: number) {
  return (await api.get<AssetTemplateWithAssignedStatus[]>(`/AlertConversion/${revisionId}/AssetTemplateStatuses`)).data;
}

export async function activateAlertConversionTemplate(revisionId: number, assetTemplateIds: number[], all?: boolean, activate?: boolean) {
  return (await api.post<AssetTemplateRevision[]>(`/AlertConversion/${revisionId}/ApplyAssignments`, {
    assetTemplateIds: assetTemplateIds,
    all: all || false,
    activate: activate || false
  })).data;
}

export interface AlertConversionSaveResponse extends TemplateSaveResponse<AlertConversion, AlertConversionRevision> {
}

export interface AlertConversionSaveRequest {
  template: AlertConversionFields;
  revision: {
    alertCodeConversionRevision: AlertCodeConversionRevision | null;
    alertBitByBitConversionRevision: AlertBitByBitConversionRevision | null;
    alertOneToOneConversionRevision: AlertOneToOneConversionRevisionForm | null;
  };
  descriptionAndResponses: AlertDescription[];
}

export async function saveAlertConversionForm(form: AlertConversionForm): Promise<AlertConversionSaveResponse> {
  return (await api.put<AlertConversionSaveResponse>('/AlertConversion', cast<AlertConversionSaveRequest>({
    template: form.conversion,
    revision: {
      alertBitByBitConversionRevision: isAlertBitByBitConversionRevision(form.revision) ? form.revision : null,
      alertCodeConversionRevision: isAlertCodeConversionRevision(form.revision) ? form.revision : null,
      alertOneToOneConversionRevision: isAlertOneToOneConversionRevisionForm(form.revision) ? form.revision : null
    },
    descriptionAndResponses: form.descriptionAndResponses
  }))).data;
}

export async function getAlertConversionRevisions(alertConversionId: number): Promise<IdDictionary<AlertConversionRevision>> {
  return (await api.get<IdDictionary<AlertConversionRevision>>(`/AlertConversion/${alertConversionId}/Revisions`)).data;
}

export async function getManyAlertConversionRevisions(alertConversionIds: number[]): Promise<IdDictionary<AlertConversionRevision>> {
  return (await api.post<IdDictionary<AlertConversionRevision>>(`/AlertConversion/Revisions`, alertConversionIds)).data;
}
