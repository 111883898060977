import React, {createContext, type ReactNode, useMemo, useState} from 'react';
import {Modal, ModalBody, ModalFooter, Button} from '../Controls';

interface Props {
  open: boolean;
  setIsOpen: (open: boolean) => void;
  message: string|ReactNode;
}

export function AlertDialog({open, setIsOpen, message}: Props) {
  return (
    <Modal
      show={open}
      centered={true}
    >
      <ModalBody>
        <p>{message}</p>
      </ModalBody>
      <ModalFooter>
        <Button variant='info' onClick={() => setIsOpen(false)}>Ok</Button>
      </ModalFooter>
    </Modal>
  );
}

export function GlobalAlertDialogProvider({children}: {children: ReactNode}) {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<string|ReactNode>('');
   const contextValue: GlobalAlertDialogContextValue = useMemo(() => ({
     showAlert: (msg) => {
       setMessage(msg);
       setIsOpen(true);
     }
   }), [setMessage, setIsOpen]);
  return <>
    <GlobalAlertDialogContext.Provider value={contextValue}>
      {children}
      <AlertDialog open={isOpen} setIsOpen={setIsOpen} message={message} />
    </GlobalAlertDialogContext.Provider>
  </>;
}
export type ShowAlert = (message: string| ReactNode) => void;
type GlobalAlertDialogContextValue = {
  showAlert: ShowAlert;
};
export const GlobalAlertDialogContext = createContext<GlobalAlertDialogContextValue>({
  // tslint:disable-next-line:no-empty
  showAlert: (message) => {}
});

export function useAlertDialog() {
  return React.useContext(GlobalAlertDialogContext);
}
