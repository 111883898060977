import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {type StringDictionary} from '../../util';

export interface TimeZoneData {
  countryName: string;
  zoneId: string;
  comment: string;
}

const selectors = createStandardSelectors(placeholder<TimeZoneData>(), s => getEntities(s).timeZones);
const actions = createStandardActions(placeholder<TimeZoneData>(), 'TIMEZONE/SET', 'TIMEZONE/SAVE');
export type TimeZoneActions = GetActions<typeof actions>;
export const timeZones = combineReducers({items: standardItemsReducer<TimeZoneData, TimeZoneActions>(actions)});
export const timeZoneStore = {
  selectors: {
    ...selectors,
    getCountries: selector(s => Object.keys(selectors.getItems(s))),
    getCountryByTimeZone: selector(s =>  (zoneId: string) =>
        ((Object.values(selectors.getItems(s) as any as StringDictionary<TimeZoneData[]>)
        .filter(tzd => tzd.find(tz => tz.zoneId === zoneId))) as any as TimeZoneData[][])[0][0].countryName),
    getTimeZonesByCountry: selector(s => (country: string) => (selectors.getItems(s) as any as StringDictionary<TimeZoneData[]>)[country])
  },
  actions: {
    ...actions
  }
} as const;
