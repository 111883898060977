import {combineReducers, type Dispatch} from 'redux';
import {type AssignableTemplateRevision} from './index';
import {type RmxBoxSourceAssignment} from '../installationEntities';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities} from '../../selectors';
import {getAssignableAssetTemplateRevisions} from 'src/api/installationModelTemplateApi';

// Assignable Asset Template Revision
export interface AATemplateRevision extends AssignableTemplateRevision {
    modules: AATemplateRevision[];
}

export function isAATemplateRevision(value: AssignableTemplateRevision): value is AATemplateRevision {
  return Array.isArray((value as AATemplateRevision).modules);
}

export function getAssigableTemplateByLuid(assignableTemplate: AssignableTemplateRevision, luid: string) {
  return assignableTemplate.luid === luid ? assignableTemplate : ((assignableTemplate as AATemplateRevision).modules ?? []).find(m => m.luid === luid);
}

const actions = createStandardActions(placeholder<AATemplateRevision>(), 'AA_TEMPLATE_REVISION/SET', 'AA_TEMPLATE_REVISION/SAVE');
export type AATemplateRevisionActions = GetActions<typeof actions>;
export const assignableAssetTemplateRevisions = combineReducers({items: standardItemsReducer<AATemplateRevision, AATemplateRevisionActions>(actions)});
export const aATemplateStore = {
  selectors: createStandardSelectors(placeholder<AATemplateRevision>(), s => getEntities(s).assignableTemplateRevisions.assetTemplates),
  actions: {
    ...actions,
    load: (templateId: number) => async (dispatch: Dispatch) => {
      const items = await getAssignableAssetTemplateRevisions(templateId);
      await dispatch(actions.set(items));
      return items;
    }
  }
} as const;

export function isAssignmentInRevision(assignment: RmxBoxSourceAssignment, aaRevision: AssignableTemplateRevision) {
  switch (assignment.type) {
    case 'ModBus': return aaRevision.templateSources.map(s => s.id).includes(assignment.templateSourceId!);
    case 'Analog': return aaRevision.analogInputs.map(s => s.id).includes(assignment.inputId!) && aaRevision.luid === assignment.templateRevisionLuid;
    case 'Digital': return aaRevision.digitalInputs.map(s => s.id).includes(assignment.inputId!) && aaRevision.luid === assignment.templateRevisionLuid;
    default: throw new Error('RmxBoxSource type not supported!' + JSON.stringify(assignment));
  }
}
