import {combineReducers, type Dispatch} from 'redux';
import {type CommonDispatch, type CommonState} from '../index';
// eslint-disable-next-line @typescript-eslint/naming-convention
import jwt_decode from 'jwt-decode';
import {type AuthToken} from '../../types/AuthToken';
import storage from 'redux-persist/es/storage';
import {
  createStandardActions,
  type GetActions,
  placeholder,
  standardItemsReducer
} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction, createReducer} from 'typesafe-actions';
import {archiveUser, restoreUser, updateUserPreferences, upsertUser} from 'src/api/userManagementApi';
import {setSentryUser} from 'src/util/sentryProvider';
import {type Role} from 'src/common/redux/entities/role';

export interface LoginRequest {
  email: string;
  password: string;
}

export interface User extends UserPreferences {
  id: number;
  email: string;
  archivedAt: string | null;
  roles: Role[];
}
export interface UserPreferences {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  countryName: string;
  timeZone: string;
}

export interface UserWithToken extends User {
  token: string;
}

export interface UserState {
  currentUser: User | null;
  items: UserItems;
}

export interface UserItems {
  [key: number]: User;
}

export const userPersistConfig = {
  key: 'users',
  storage: storage,
  whitelist: ['currentUser']
};

const currentUserActions = {
  setCurrentUser: createAction('USER/SET_CURRENT_USER')<User | null>()
};

const actions = createStandardActions(placeholder<User>(), 'USER/SET', 'USER/SAVE');
const selectors = createStandardSelectors(placeholder<User>(), s => getEntities(s).users);

export type UserActions = GetActions<typeof actions>;
type CurrentUserActions = GetActions<typeof currentUserActions>;

export const users = combineReducers<UserState>({
  currentUser: createReducer<User|null, CurrentUserActions>(null)
    .handleAction(currentUserActions.setCurrentUser, (state, action) => action.payload),
  items: standardItemsReducer<User, UserActions>(actions)
});

export const userStore = {
  selectors: {
    ...selectors,
    getCurrentUser: selector(s => selectors.getState(s).currentUser),
    getNonArchivedUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt === null)),
    getArchivedUsers: selector(s => selectors.getAsArray(s).filter(u => u.archivedAt !== null))
  },
  actions: {
    ...actions,
    ...currentUserActions,
    upsert: (form: User) => async (dispatch: CommonDispatch) => {
      const response = await upsertUser(form);
      dispatch(userStore.actions.save(response));
      return response;
    },
    updateUserPreferences: (form: UserPreferences) => async (dispatch: Dispatch) => {
      const response = await updateUserPreferences(form);
      dispatch(userStore.actions.save(response));
      dispatch(userStore.actions.setCurrentUser(response));
      return response;
    },
    archiveUser: (id: string) => async (dispatch: CommonDispatch) => {
      const user: User = await archiveUser(id);
      dispatch(userStore.actions.save(user));
    },
    restoreUser: (id: string) => async (dispatch: CommonDispatch) => {
      const user: User = await restoreUser(id);
      dispatch(userStore.actions.save(user));
    }
  }
} as const;

export function isAuthenticated(state: CommonState) {
  const localUser: User | null = userStore.selectors.getCurrentUser(state);
  if(localUser) {
    setSentryUser(localUser.email);
  }
  const token = localStorage.getItem('token');
  if (token && localUser) {
    const time = Math.ceil((new Date()).getTime() / 1000);
    const decoded = jwt_decode<AuthToken>(token);
    return time < decoded['exp'];
  }
  return false;
}

export type MapIsAuthenticatedToPropsType = ReturnType<typeof mapIsAuthenticatedToProps>;
export const mapIsAuthenticatedToProps = (state: CommonState) => ({ authenticated: isAuthenticated(state)});
