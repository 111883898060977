import { useDashboardTiles } from '../../hooks/useDashboardTiles';
import { getPreviousBreadCrumbLink } from '../../DashboardBreadCrumbs/DashboardBreadCrumbs';
import { DashboardTiles } from '../../DashboardTiles/DashboardTiles';
import React from 'react';
import { PanelLayout } from '../../../../components/util/PanelLayout/PanelLayout';


export function HomeDashboard() {
    const dashboardTiles = useDashboardTiles();
  const {resp, searchState} = dashboardTiles;
  return (<>
    <PanelLayout breadCrumbs={resp?.breadCrumbs}  backLink={getPreviousBreadCrumbLink(resp?.breadCrumbs)} searchState={searchState}>
      <DashboardTiles {...dashboardTiles} />
    </PanelLayout>
  </>);
}
