import {type SpTemplate} from '../common/redux/entities/spTemplate';
import {type SpTemplateForm, type SpTemplateRevision} from '../common/redux/entities/spTemplateRevision';
import {type AssetStatus} from '../common/redux/entities/installationEntities/assetRevision';
import {type SpStatus} from '../common/redux/entities/installationEntities/spRevision';
import {type RevisionStatus} from '../common/types/Revision';
import {type IdDictionary} from '../common/util';
import {type AATemplateRevision} from '../common/redux/entities/assignableTemplateRevision/assetTemplate';
import {type AssetTemplateForm, type AssetTemplateRevision} from '../common/redux/entities/assetTemplateRevision';
import {type ASpTemplateRevision} from '../common/redux/entities/assignableTemplateRevision/aSpTemplateRevision';
import {type AssetTemplate} from '../common/redux/entities/assetTemplate';
import {api, type TemplateSaveResponse} from './index';
import {type OSConversion} from '../common/redux/entities/conversions/operational-statuses';
import {type OSConversionRevision} from '../common/redux/entities/conversions/operational-statuses/osConversionRevision';

export async function saveSpTemplateForm(form: SpTemplateForm): Promise<SpTemplateSaveResponse> {
  return (await api.post<SpTemplateSaveResponse>('/SpTemplate', form)).data;
}

export async function getSpTemplateRevisionStatuses(templateId: number) {
  return (await api.get<RevisionStatus[]>(`/SpTemplate/${templateId}/Statuses`)).data;
}

export interface AssetTemplateSaveResponse extends TemplateSaveResponse<AssetTemplate, AssetTemplateRevision> {
}

export interface SpTemplateSaveResponse extends TemplateSaveResponse<SpTemplate, SpTemplateRevision> {
}

export async function saveAssetTemplateForm(form: AssetTemplateForm): Promise<AssetTemplateSaveResponse> {
  return (await api.post<AssetTemplateSaveResponse>('/AssetTemplate', form)).data;
}

export interface TemplateRevisionsResponse<T, R> {
  revisions: IdDictionary<R>;
  template: T;
}

export interface AssetTemplateRevisionsResponse extends TemplateRevisionsResponse<AssetTemplate, AssetTemplateRevision> { }

export async function getAssetTemplateRevisions(assetTemplateId: number): Promise<AssetTemplateRevisionsResponse> {
  return (await api.get<AssetTemplateRevisionsResponse>(`/AssetTemplate/${assetTemplateId}/Revisions`)).data;
}

export async function getAssetTemplateRevisionStatuses(assetTemplateId: number) {
  return (await api.get<RevisionStatus[]>(`/AssetTemplate/${assetTemplateId}/Statuses`)).data;
}

export async function getAssetTemplateAssociatedAssetStatuses(revisionId: number, onlyShowNonActivated: boolean) {
  return (await api.get<AssetStatus[]>(`/AssetTemplate/Revision/${revisionId}/AssociatedAssets`,
    {
      params: {
        onlyShowNonActivated: onlyShowNonActivated
      }
    })).data;
}

export async function getSpTemplateAssociatedSpStatuses(revisionId: number, onlyShowNonActivated: boolean) {
  return (await api.get<SpStatus[]>(`/SpTemplate/Revision/${revisionId}/AssociatedAssets`, {
    params: {
      onlyShowNonActivated: onlyShowNonActivated
    }
  })).data;
}

export async function activateAssetTemplate(assetTemplateRevisionId: number, assetIds: number[], all?: boolean) {
  return (await api.post<void>(`/AssetTemplate/Revision/${assetTemplateRevisionId}/Activate`, {
    ids: assetIds,
    all: all || false
  })).data;
}

export async function activateSpTemplate(revisionId: number, systemProcessIds: number[], all?: boolean) {
  return (await api.post<void>(`/SpTemplate/Revision/${revisionId}/Activate`, {
    ids: systemProcessIds,
    all: all || false
  })).data;
}

export interface SpTemplateRevisionsResponse extends TemplateRevisionsResponse<SpTemplate, SpTemplateRevision> { }

export async function getSpTemplateRevisions(templateId: number): Promise<SpTemplateRevisionsResponse> {
  return (await api.get<SpTemplateRevisionsResponse>(`/SpTemplate/${templateId}/Revisions`)).data;
}

export interface OsConversionRevisionsResponse extends TemplateRevisionsResponse<OSConversion, OSConversionRevision> { }
export async function getOsConversionRevisions(templateId: number): Promise<OsConversionRevisionsResponse> {
  return (await api.get<OsConversionRevisionsResponse>(`/OsConversion/${templateId}/Revisions`)).data;
}

export async function getAssignableAssetTemplateRevisions(templateId: number): Promise<IdDictionary<AATemplateRevision>> {
  return (await api.get<IdDictionary<AATemplateRevision>>(`/AssetTemplate/${templateId}/AssignableRevisions`)).data;
}

export async function getAssignableSpTemplateRevisions(templateId: number): Promise<IdDictionary<ASpTemplateRevision>> {
  return (await api.get<IdDictionary<ASpTemplateRevision>>(`/SpTemplate/${templateId}/AssignableRevisions`)).data;
}

export interface TestCalculationRequest {
  run: boolean;
  variables: VariableNameValuePair[];
  code: string;
}
export interface VariableValue {
  double?: number | null;
  dateTime?: string | null;
  entities?: VariableEntity[]|null;
  type: 'Double' | 'DateTime' | 'Entities';
}

export interface VariableNameValuePair {
  variableName: string;
  value: VariableValue;
  entities?: VariableEntity[];
}

export interface VariableEntity {
  attributes: VariableNameValuePair[];
}

export interface TestCalculationResult {
  compileError: string;
  runtimeError: string;
  output: string;
}

export async function testCalculation(request: TestCalculationRequest): Promise<TestCalculationResult> {
  return (await api.post<TestCalculationResult>('/Calculation/Test', request)).data;
}
