import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, type Dispatch} from 'redux';
import {logout} from 'src/api/authApi';
import {thunk} from '../../util';
import {RedirectToLogin} from '../../components/util/RedirectToLogin';

type LogoutProps = ReturnType<typeof mapDispatchToProps> & {
};

export function LogOutComponent(props: LogoutProps) {
  props.logout();
  return (
    <RedirectToLogin />
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({logout: thunk(logout)}, dispatch);

export const LogOut = connect(undefined, mapDispatchToProps)(LogOutComponent);
