import {RoutePaths} from 'src/router/RoutePaths';

const basePath = `${RoutePaths.globalConfiguration}/operational-status`;
const conversionsBasePath = `${basePath}/conversions`;

export const operationalStatusConversionPaths = {
  New: `${conversionsBasePath}/:osType/new`,
  View: `${conversionsBasePath}/:osType/view/:id`,
  Edit: `${conversionsBasePath}/:osType/edit/:id`
};
