import {combineReducers} from 'redux';
import type TemplateSource from '../../../../types/TemplateSource';
import {type CommonOSCheck, type OSAssignment} from './index';
import {type RegisterAssignment} from '../../../abstract/dataPointAssignment';
import {type RevisionEntity} from 'src/common/types/Revision';
import {createStandardSelectors, getEntities} from '../../../selectors';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../../../utils';
import {createAction} from 'typesafe-actions';
import {type IdDictionary} from 'src/common/util';

/// WARNING: Asset template revisions have not been refactored to this reducer yet.

export interface OSConversionRevision extends CommonConversionRevision {
  registerAssignments: RegisterAssignment[];
  osConversionId: number;
  updatedAt?: string;
}

export interface CommonConversionRevision extends RevisionEntity {
  id: number;
  templateSources: TemplateSource[];
}

export const isOSConversionRevision = (revision: RevisionEntity): revision is OSConversionRevision => 'osConversionId' in revision;

export interface OSConversionRevisionWithStatus extends OSConversionRevision { status: string }

export interface OSCodeConversionRevision extends OSConversionRevision { osCodeCheckGroups: OSCodeCheckGroup[] }

export interface OSCodeCheckGroup extends CommonOSCheck { osCodeChecks: OSCodeCheck[] }

export interface OSCodeCheck {
  id: number;
  manufacturerStatusCode: number;
  osCodeCheckGroupId?: number;
  osAssignments: OSAssignment[];
}
export const mergeRevisions = createAction('CONVERSION_REVISION/MERGE')<IdDictionary<OSConversionRevision>>();
const actions = createStandardActions(placeholder<OSConversionRevision>(), 'CONVERSION_REVISION/SET', 'CONVERSION_REVISION/SAVE');
export type OSConversionRevisionActions = GetActions<typeof actions> | ReturnType<typeof mergeRevisions>;
export const osConversionsRevisions = combineReducers({
  items: standardItemsReducer<OSConversionRevision, OSConversionRevisionActions>(actions)
    .handleAction(mergeRevisions, (state, action) => ({...state, ...action.payload}))
});
const selectors = createStandardSelectors(placeholder<OSConversionRevision>(), s => getEntities(s).operationalStatus.conversionRevisions);

export const osConversionRevisionStore = {
  selectors: {
    ...selectors
  },
  actions: {
    ...actions,
    merge: mergeRevisions
  }
} as const;
