import {combineReducers} from 'redux';
import {type CommonActions, type CommonDispatch, type CommonState} from '../index';
import type Action from '../index';
import {type ConvertToItems} from '../../types/util';
import {getBoxRevSources, type RmxBox, type RmxBoxPort, type RmxBoxRevision, type RmxBoxSource, type UnsavedRmxBox} from '../../types/RmxBox';
import {type RmxBoxChangeResponse} from 'src/api/rmxBoxApi';
import {convertToDropDownOptions, groupBy} from '../../util';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {batchActions} from 'redux-batched-actions';
import {getRmxBoxTypeById} from './rmxBoxType';
import {assetRevisionStore} from './installationEntities/assetRevision';
import {installationStore} from './installation';
import {
  saveRmxBox as saveRmxBoxRequest,
  saveRmxBoxPort as saveRmxBoxPortRequest,
  saveRmxBoxSource as saveRmxBoxSourceRequest
} from '../../../api/rmxBoxApi';
import {loadCompanyConfiguration} from '../companyConfiguration';

export type RmxBoxItems = ConvertToItems<RmxBox>;

export interface RmxBoxState {
  items: RmxBoxItems;
}
export type SetRmxBoxesAction = Action<'SET_RMX_BOXES', RmxBoxItems>;
export const setRmxBoxes = (items: RmxBoxItems): SetRmxBoxesAction => ({type: 'SET_RMX_BOXES', data: items});

export type SaveRmxBoxAction = Action<'SAVE_RMX_BOX', RmxBox>;
export const saveRmxBox = (item: RmxBox): SaveRmxBoxAction => ({type: 'SAVE_RMX_BOX', data: item});

export const upsertRmxBox = (item: UnsavedRmxBox, companyId: number) => async (dispatch: CommonDispatch) => {
  const response = await saveRmxBoxRequest(item);
  if (isNaN(companyId)) {
    handleRmxBoxChangeResponse(dispatch, response);
  } else {
    await loadCompanyConfiguration(companyId)(dispatch);
  }
  return response.rmxBox;
};

export const saveRmxBoxPort = (item: RmxBoxPort, companyId: number) => async (dispatch: CommonDispatch) => {
  const response = await saveRmxBoxPortRequest(item);
  if (!isNaN(companyId)) {
    await loadCompanyConfiguration(companyId)(dispatch);
  } else {
    handleRmxBoxChangeResponse(dispatch, response);
  }

  return response;
};

export const saveRmxBoxSource = (item: RmxBoxSource, companyId: number) => async (dispatch: CommonDispatch) => {
  const response = await saveRmxBoxSourceRequest(item);
  if (!isNaN(companyId)) {
    await loadCompanyConfiguration(companyId)(dispatch);
  } else {
    handleRmxBoxChangeResponse(dispatch, response);
  }
};

function handleRmxBoxChangeResponse(dispatch: CommonDispatch, response: RmxBoxChangeResponse) {
  const actions: CommonActions[] = [saveRmxBox(response.rmxBox)];
  if (response.installation) {
    actions.push(installationStore.actions.save(response.installation));
  }
  for (const assetRevision of response.assetRevisions) {
    actions.push(assetRevisionStore.actions.save(assetRevision));
  }
  dispatch(batchActions(actions));
}

export type RmxBoxActions = SaveRmxBoxAction | SetRmxBoxesAction ;

function rmxBoxItems(state: RmxBoxItems = {}, action: RmxBoxActions) {
  switch (action.type) {
    case 'SAVE_RMX_BOX': return {
      ...state,
      [Number(action.data.id)]: action.data
    };
    case 'SET_RMX_BOXES': return action.data;
    default: return state;
  }
}

export const rmxBoxes = combineReducers<RmxBoxState>({
  items: rmxBoxItems
});
export const getRmxBoxItems = (state: CommonState) => state.entities.rmxBoxes.items;
export const getRmxBoxById = (state: CommonState) => (id: number): RmxBox => getRmxBoxItems(state)[id];
export const getRmxBoxesAsArray = (state: CommonState) => Object.values(getRmxBoxItems(state));
export const getRmxBoxesDropDownOptionsByInstallationId = (state: CommonState) => (installationId: number) =>
  convertToDropDownOptions(
    Object.values(getRmxBoxItems(state))
    .filter(b => b.installationId === installationId)
  );

export interface RmxBoxParing {
  rmxBox: RmxBox;
  rmxBoxRevision: RmxBoxRevision;
}

export const getRmxBoxRevsByInstallationRevId = (state: CommonState) => (installationId: number, installationRevisionId: number) =>
  Object.values(getRmxBoxItems(state))
    .filter(b => b.installationId === installationId)
    .filter(b => b.rmxBoxRevisions.find(r => r.installationRevisionId === installationRevisionId))
    .map<RmxBoxParing>(b => {
      const rev = b.rmxBoxRevisions.find(r => r.installationRevisionId === installationRevisionId) as RmxBoxRevision;
      return {rmxBox: b, rmxBoxRevision: rev};
    });

export const getRmxBoxRevsByInstallationRevIdAsOptions = (state: CommonState) => (installationId: number, installationRevisionId: number) =>
  getRmxBoxRevsByInstallationRevId(state)(installationId, installationRevisionId)
    .map<DropdownOption<number>>(p => ({value: p.rmxBoxRevision.id, label: p.rmxBox.name}));

export const getRmxBoxRevAndRmxBoxFromSourceId = (state: CommonState) => (installationId: number, installationRevisionId: number, sourceId: number) =>
  getRmxBoxRevsByInstallationRevId(state)(installationId, installationRevisionId)
    .find(b =>
      !!(getBoxRevSources(b.rmxBoxRevision).find(s => s.id === sourceId))
    );

export const getRmxBoxesAsDropDowns = (state: CommonState) => convertToDropDownOptions(Object.values(getRmxBoxItems(state)));
export const getRmxBoxesGroupedByCompany = (state: CommonState) => groupBy(getRmxBoxesAsArray(state), (rmxBox: RmxBox) => rmxBox.companyId);

export const getRmxBoxTypeFromRmxBoxRevisionId = (state: CommonState) => (id: number) => {
  const rmxBox = getRmxBoxesAsArray(state).find(rb => rb.rmxBoxRevisions.find(r => r.id === id));
  if (!rmxBox) {
    return null;
  }
  return getRmxBoxTypeById(state)(rmxBox.rmxBoxTypeId);
};
