import {type Installation, type InstallationFormFields, type InstallationRevisionForm} from '../common/redux/entities/installation';
import {type Asset, type AssetRevision} from '../common/redux/entities/installationEntities/assetRevision';
import {type SpRevision} from '../common/redux/entities/installationEntities/spRevision';
import {type RmxBox} from '../common/types/RmxBox';
import {type CompanyConfiguration} from '../common/redux/companyConfiguration';
import {type Site} from '../common/redux/entities/site';
import {api} from './index';

export async function getCompanyConfigurationState(id: number) {
  return (await api.get<CompanyConfiguration>(`/CompanyConfiguration/${id}`)).data;
}

export interface SaveInstallationRevisionEntityResponse {
  installation: Installation;
  assetRevisions: AssetRevision[];
  spRevisions: SpRevision[];
  rmxBoxes: RmxBox[];
}

export interface DeleteInstallationRevisionEntityResponse {
  newInstallationRevision: SaveInstallationRevisionEntityResponse;
  deletedAssetRevisions: AssetRevision[];
  deletedSpRevisions: SpRevision[];
}

export async function saveAssetRevision(rev: AssetRevision) {
  return (await api.post<SaveInstallationRevisionEntityResponse>('/AssetRevision', rev)).data;
}

export async function deleteAssetRevision(id: string) {
  return (await api.delete<DeleteInstallationRevisionEntityResponse>('/AssetRevision/' + id)).data;
}

export async function saveSpRevision(rev: SpRevision) {
  return (await api.post<SaveInstallationRevisionEntityResponse>('/SpRevision', rev)).data;
}

export async function deleteSpRevision(id: string) {
  return (await api.delete<DeleteInstallationRevisionEntityResponse>('/SpRevision/' + id)).data;
}

export async function upsertSite(site: Site) {
  return (await api.post<Site>('/Site', site)).data;
}

export async function addInstallation(installation: InstallationFormFields) {
  return (await api.post<Installation>('/Installation', installation)).data;
}

export async function activateInstallationRevision(revisionId: number) {
  return (await api.post<void>('/Installation/Activate/' + revisionId)).data;
}

export async function saveInstallationRevisionForm(installationRevisionForm: InstallationRevisionForm) {
  return (await api.post<void>('/Installation/RevisionForm', installationRevisionForm)).data;
}

export async function updateInstallation(installation: InstallationFormFields): Promise<Installation> {
  return (await api.put<Installation>('/Installation', installation)).data;
}

export async function deleteInstallation(installation: Installation) {
  return (await api.delete<Installation>('/Installation/' + installation.id)).data;
}

export async function deleteInstallationRevision(installationRevisionId: number) {
  return (await api.delete<void>('/InstallationRevision/' + installationRevisionId)).data;
}

export async function cloneInstallationRevision(installationRevisionId: number) {
  return (await api.post<void>('/InstallationRevision/clone/' + installationRevisionId)).data;
}

export async function getInstallationPossibleRmxBoxAssetsWithGPS(installationId: number) {
  return (await api.get<Asset[]>(`/Installation/${installationId}/PossibleRmxBoxAssetsWithGPS`)).data ;
}

export async function activateDeactivateInstallation(installationId: number) {
  await api.post<void>(`/Installation/ActivateDeactivate/${installationId}`) ;
}

export type ProcessingStatus = {
    timeStamp: string;
    processed: boolean;
    failed: boolean;
};

export type InstallationProcessingErrors = {
  lastPollStatus: ProcessingStatus|null;
  lastCycleStatus: ProcessingStatus|null;
  errors: string[];
};

export type InstallationDataInformation = {
  pollId: number;
  firstTimeStamp: string;
  rmxBoxName: string;
  parseStatus: string;
  latestCycleMessages:ParsedMessage[];
};
type ParsedMessage = {

};

export async function getInstallationErrors(installationId: number) {
  return (await api.get<InstallationProcessingErrors>(`/Installation/${installationId}/Errors`)).data ;
}

export async function getInstallationDataInformation(installationId: number) {
  return (await api.get<InstallationDataInformation>(`/Installation/${installationId}/DataInformation`)).data ;
}
