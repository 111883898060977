import {combineReducers} from 'redux';
import {type CommonDispatch, type CommonState} from '../index';
import type Action from '../index';
import {cast, convertToDropDownOptions, getObjectValues, groupBy} from '../../util';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {EExtrema} from '../../types/Gauge';
import {batchAppActions} from '../utils';
import {getUnitsAsArray, type Unit} from './unit';
import memoize from 'lodash.memoize';
import {createSelector} from '@reduxjs/toolkit';
import {addDataPoint, addExtremaDataPoint, updateDataPoint} from 'src/api/dataPointApi';
import {type ValueOf} from '../../types/util';
import {getUUID} from '../../../util/uuid';
import {type AlertGroup} from 'src/common/redux/entities/alertGroup';
import {type AlertType} from 'src/common/redux/entities/alertType';

export interface DataPoint extends UnsavedOperationalStatusField {
  id: number;
  name: string;
  dataPointType: DataPointType;
  uuid: string;
  unitGroupId: number;
  displayUnitId: number | null;
  defaultDecimalPlaces?: number;
  variableName: string;
  maxDataPointId: number | null;
  minDataPointId: number | null;
  isMinOrMax: boolean;
  assetGroupId: number | null;
  systemProcessTypeId: number | null;
  associatedDataPointId: number | null;
  ndpaAlertId: number | null;
  alertGroupId: number | null;
  alertTypeId: number | null;
  isGlobalOrNullForLocal: boolean | null;
}

export interface UnsavedOperationalStatusField {
  statusTrue?: string;
  statusFalse?: string;
}

export const EDataPointType = {
  ManufacturerAlertCode: 'ManufacturerAlertCode',
  Data: 'Data',
  AlertStatusRegister: 'AlertStatusRegister',
  OperationalStatusField: 'OperationalStatusField',
  OperationalStatusCodeRegister: 'OperationalStatusCodeRegister',
  OperationalStatusBitByBitRegister: 'OperationalStatusBitByBitRegister',
  ProductionStatus: 'ProductionStatus',
  AlertSetPoint: 'AlertSetPoint',
  AlertTrigger: 'AlertTrigger',
  Spec: 'Spec'
} as const;

export type DataPointType = ValueOf<typeof EDataPointType>;
export const DataPointTypes = getObjectValues(EDataPointType);

export const DataPointNameSeparator = '|DpUuid:';

export interface DataPointItems {
  [key: number]: DataPoint;
}

export interface DataPointState {
  items: DataPointItems;
}

export interface SetDataPointsAction extends Action<'SET_DATA_POINTS', DataPointItems> {}
export const setDataPoints = (items: DataPointItems) => ({
  type: 'SET_DATA_POINTS',
  data: items
});

export type SaveDataPointAction = Action<'SAVE_DATA_POINT', DataPoint>;
export const saveDataPoint = (dataPoint: DataPoint): SaveDataPointAction => ({type: 'SAVE_DATA_POINT', data: dataPoint});

export const createNewDataPoint = (dataPoint: DataPoint) => async (dispatch: CommonDispatch) => {
  const response: DataPoint = await addDataPoint(dataPoint);
  dispatch(saveDataPoint(response));
  return response;
};

export const updateExistingDataPoint = (dataPoint: DataPoint) => async (dispatch: CommonDispatch) => {
  const response: DataPoint = await updateDataPoint(dataPoint);
  dispatch(saveDataPoint(response));
  return response;
};

export const upsertExtrema = (dpId: number, extrema: EExtrema) => async (dispatch: CommonDispatch, getState: () => CommonState) => {
  const dp = getDataPointById(getState())(dpId);
  const extremaId = extrema === EExtrema.Max ? dp.maxDataPointId : dp.minDataPointId;
  if (extremaId === null) {
    const response = await addExtremaDataPoint(dp.id, extrema);
    await dispatch(batchAppActions([
      saveDataPoint(response.dataPoint),
      saveDataPoint(response.extrema)
    ]));
    return response.extrema;
  } else {
    return getDataPointById(getState())(extremaId);
  }
};

export type DataPointActions = SetDataPointsAction | SaveDataPointAction;

export function dataPointItems(state: DataPointItems = {}, action: DataPointActions) {
  switch (action.type) {
    case 'SET_DATA_POINTS': return action.data;
    case 'SAVE_DATA_POINT':
      return {
        ...state,
        [String(action.data.id)]: action.data
      };
    default:
      return state;
  }
}

export const dataPoints = combineReducers<DataPointState>({
  items: dataPointItems
});

// selectors
export const getDataPointItems = (state: CommonState) => state.entities.dataPoints.items;
export const getDataPointsAsArray = createSelector(getDataPointItems, (items) => Object.values(items) as DataPoint[]);
export const getDataPointsAsOptions = (state: CommonState) => convertToDropDownOptions(getDataPointsAsArray(state));
export const getDataDataPoints = (state: CommonState) =>
  getDataPointsAsArray(state).filter(dp => dp.dataPointType === EDataPointType.Data);

const usableDataPointTypes = cast<DataPointType[]>([
  'Data',
  'ProductionStatus'
]);
export const getUsableDataPoints = createSelector(
  getDataPointsAsArray,
    arr => arr.filter(dp => usableDataPointTypes.includes(dp.dataPointType)));

export const getUsableDataPointsAsOptions = createSelector(getUsableDataPoints, usableDps => convertToDropDownOptions(usableDps));

export const getDataPointsGroupedByDataPointType = createSelector(
  getDataPointsAsArray,
  (dps): Map<string, DataPoint[]> => groupBy(dps.filter(dp => !dp.isMinOrMax), (dataPoint: DataPoint) => dataPoint.dataPointType));

export const getDataPointsGroupedByUnitGroup = (state: CommonState) =>
  groupBy(getDataPointsAsArray(state), (dataPoint: DataPoint) => dataPoint.unitGroupId);

export const getDataPointsByDataPointType = createSelector(
  getDataPointsAsArray,
  arr => {
  const grouped = groupBy(arr, (dataPoint: DataPoint) => dataPoint.dataPointType);
  return memoize((type: DataPointType) => grouped.get(type) || []);
});

export const getDataPointOptionsByDataPointType = createSelector(
  getDataPointsByDataPointType,
    getByType => memoize((type: DataPointType) => convertToDropDownOptions(getByType(type)))
);

export const getManufacturerAlertCodeOptions = createSelector(getDataPointOptionsByDataPointType, getByType => getByType(EDataPointType.ManufacturerAlertCode));

export const getDataPointById = createSelector(getDataPointItems, items => memoize((id: number) => items[id]));

export type GetDPById = ReturnType<typeof getDataPointById>;

interface SplitOSDPName {
  trueLabel: string;
  falseLabel: string;
}

export function splitOSDPName(dp: DataPoint): SplitOSDPName {
  const statusValues = getDataPointName(dp).split('/');
  return {
    trueLabel: statusValues[0],
    falseLabel: statusValues[1]
  };
}

export const makeGetSetPointOptionsByAssociatedDataPointId = createSelector(
  getDataPointsGroupedByDataPointType,
  (dpsByDataPointType) => memoize((associatedDpId: number) =>
    convertToDropDownOptions(
      (dpsByDataPointType.get(EDataPointType.AlertSetPoint) ?? [])
        .filter(dp => dp.associatedDataPointId === associatedDpId))
  ));

export const makeGetDataPointSetPointByAssociatedDataPointId = createSelector(
  getDataPointsGroupedByDataPointType,
  (dpsByDataPointType) => memoize((associatedDpId: number) =>
      (dpsByDataPointType.get(EDataPointType.AlertSetPoint) ?? [])
        .filter(dp => dp.associatedDataPointId === associatedDpId)
  ));

export function makeOSValueOptions<T extends number | boolean>(dataPointId: number | null, osDataPoints: DataPoint[], trueValue: T, falseValue: T) {
  const dp = osDataPoints.find(d => d.id === dataPointId);
  if (!dp) {
    return [];
  }
  const labels = splitOSDPName(dp);
  return cast<Array<DropdownOption<T>>>([
    {label: labels.trueLabel, value: trueValue},
    {label: labels.falseLabel, value: falseValue}
  ]);
}

export const getUnitDropDownOptions = (s: CommonState) => (dataPointId: number) => {
  const getDPById = getDataPointById(s);
  const dataPoint = getDPById(dataPointId);
  const units = groupBy(getUnitsAsArray(s), type => type.unitGroupId).get(dataPoint.unitGroupId) as Unit[];
  return convertToDropDownOptions(units);
};

export function getDataPointName(dp: DataPoint | undefined) {
  if (!dp) { return ''; }
  return dp.name.indexOf(DataPointNameSeparator) !== -1 ? dp.name.substring(0, dp.name.indexOf(DataPointNameSeparator)) : dp.name;
}
export function remapDataPoint(dp: DataPoint): DataPoint {
  const uniqueId = getUUID();
  return {
    ...dp,
    uuid: uniqueId,
    name: getDataPointName(dp),
    isGlobalOrNullForLocal: null
  };
}


export function getAlertSetPointDataPointPrefix(associatedDp: DataPoint) {
  return getDataPointName(associatedDp) + ' SetPoint - ';
}


export function makeAlertSetPointDataPointName(associatedDp: DataPoint, alertGroup: AlertGroup, alertType: AlertType) {
  return `${getAlertSetPointDataPointPrefix(associatedDp)}${alertGroup.name} - ${alertType.name}`;
}
