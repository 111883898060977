import {type DataPointTemplateAssignment} from '../redux/abstract/dataPointAssignment';
import {type NonNullableObject} from 'src/util';
import {isNull} from '../util';

export enum EGaugeBoundType {
  Manual = 'Manual',
  DataPointAssociated = 'DataPointAssociated'
}
export enum EExtrema {
  Min = 'Min',
  Max = 'Max'
}

export function isManual(type: EGaugeBoundType) {
  return type === EGaugeBoundType.Manual;
}

export interface Gauge {
  id: number;
  uuid: string;
  min: EGaugeBoundType;
  max: EGaugeBoundType;
  minManual: number | null;
  maxManual: number | null;
  minDataPointTemplateAssignment: DataPointTemplateAssignment | null;
  maxDataPointTemplateAssignment: DataPointTemplateAssignment | null;
  majorTicks?: string;
  highlights?: GaugeHighlightEntity[];
}
// not currently used. May have some use in the future, if confirmed with the client that there is no use, go ahead and remove it.
export interface GaugeHighlightEntity {
  from: number;
  to: number;
  color: string;
}

export interface GaugeHighlight {
  from: number;
  to: number;
  color: string;
}
export interface GaugeDataProperties {
  min: number | null;
  max: number | null;
  title: string;
  unit: string;
  highlights: GaugeHighlight[];
}

export interface GaugeData extends GaugeDataProperties {
  value: number | null;
}

export type ComputedGaugeData = {
  value: number | undefined;
} & NonNullableObject<GaugeDataProperties>;

export function convertGaugeData(g: GaugeData): ComputedGaugeData {
  return {
    ...g,
    max: !isNull(g.max) ? g.max! : 100,
    min: !isNull(g.min) ? g.min! : 0,
    value: isNull(g.value) ? undefined : g.value!,
    highlights: g.highlights ?? []
  };
}

export interface OverridableGauge extends ReferencesGauge  {
  name: string;
  gaugeUuid: string;
}

export interface GaugeOverride extends ReferencesGauge {
  name: string;
}
export interface ReferencesGauge {
  gaugeId: number;
}

export const makeGaugeOverride = (gaugeId: number, name: string = ''): GaugeOverride  => ({
  gaugeId: gaugeId,
  name: name
});
