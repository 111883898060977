import {combineReducers} from 'redux';
import {type CommonDispatch} from '../index';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {upsertCompany} from 'src/api/globalConfigApi';
import {groupBy} from '../../util';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {type UuidModel} from 'src/common/types/UuidModel';

export interface Company extends UuidModel {
  id: number;
  name: string;
  dealerId: number;
  archivedAt: null | string;
}

export function getCompanyLabel(c?: Company) {
  if(!c)
    return '';
  return c.name + (c.archivedAt ? ' (archived)' : '');
}


const actions = createStandardActions(placeholder<Company>(), 'COMPANY/SET', 'COMPANY/SAVE');
const selectors = createStandardSelectors(placeholder<Company>(), s => getEntities(s).companies);
export type CompanyActions = GetActions<typeof actions>;
export const companies = combineReducers({items: standardItemsReducer<Company, CompanyActions>(actions)});
export const companyStore = {
  selectors: {
    ...selectors,
    getOptions: selector(s => selectors.getAsArray(s).map<DropdownOption<number>>(c => ({label: getCompanyLabel(c), value: c.id}))),
    getCompaniesGroupedByDealerId: selector(s => (dealerId: number) =>
      selectors.getAsArray(s).filter(c => c.dealerId === dealerId)),
    getCompaniesGroupedByDealer: selector(s => groupBy(selectors.getAsArray(s), (c: Company) => c.dealerId))
  },
  actions: {
    ...actions,
    upsert: (company: Company) => async (dispatch: CommonDispatch) => {
      const response = await upsertCompany(company);
      dispatch(actions.save(response));
      return response;
    }
  }
} as const;
