import {type CommonState} from '../index';
import type Action from '../index';
import {combineReducers} from 'redux';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {getObjectValues} from '../../util';

export interface SensorType {
    id: number;
    measurement: string;
    min: number;
    max: number;
}

export interface SensorTypeItems {
    [key: number]: SensorType;
}

export interface SensorTypeState {
    items: SensorTypeItems;
}

export type SetSensorTypesAction = Action<'SET_SENSOR_TYPES', SensorTypeItems>;
export const setSensorTypes = (items: SensorTypeItems): SetSensorTypesAction => ({type: 'SET_SENSOR_TYPES', data: items});

export type SensorTypeActions = SetSensorTypesAction;

function sensorTypeItems(state: SensorTypeItems = {}, action: SensorTypeActions) {
    switch (action.type) {
        case 'SET_SENSOR_TYPES':
            return action.data;
        default:
            return state;
    }
}

export const sensorTypes = combineReducers<SensorTypeState>({
    items: sensorTypeItems
});

export const getSensorTypeItems = (state: CommonState) => state.entities.sensorTypes.items;
export const getSensorTypesArray = (state: CommonState): SensorType[] => getObjectValues(getSensorTypeItems(state));

export const getSensorTypeOptions = (state: CommonState) => getSensorTypesArray(state)
  .map<DropdownOption<number>>(s => ({value: s.id, label: `${s.min} ${s.measurement} - ${s.max} ${s.measurement}`}));
