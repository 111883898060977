import {EDataPointType} from '../redux/entities/dataPoint';
import {getObjectValues} from '../util';
import {type ValueOf} from './util';

export enum EInputType {
  ModBus = 'ModBus',
  ModBusRawAdc = 'ModBusRawAdc',
  Analog = 'Analog',
  Digital = 'Digital',
  DataPoints = 'DataPoints',
  DataPoint = 'DataPoint',
  Output = 'Output',
  OperationalStatus = 'OperationalStatus',
  Spec = 'Spec'
}

export type InputType = keyof typeof EInputType;

export interface TemplateInputDefaultProperties {
  id: number;
  variableName: string;
  testValue: number;
  modBusInput: ModBusInput | null;
  adcConversion: AdcConversion | null;
  constantInput?: ConstantInput;
  dataPointsInput: DataPointsInput | null;
  dataPointInput: DataPointInput | null;
  outputInput?: OutputInput;
  digitalInput: DigitalInput | null;
  osInput: OSInput | null;
  specInput: SpecInput | null;
  unitId: number | null;
  uuid: string;
  templateSourceUuid: string;
}

export interface TemplateInput extends TemplateInputDefaultProperties {
  type: InputType;
}

export enum BitOrders {
  LeastToMost = 'LeastToMost',
  MostToLeast = 'MostToLeast'
}

export type BitOrder = keyof typeof BitOrders | null;

export enum DataTypes {
  S8 = 'S8',
  U8 = 'U8',
  S16 = 'S16',
  U16 = 'U16',
  S32 = 'S32',
  U32 = 'U32',
  F32 = 'F32'
}

export type DataType = keyof typeof DataTypes;

export interface ModBusInput {
  modbusFunction: number;
  bitOrder: BitOrder;
  register: number;
  displayRegisterInHex: boolean;
  dataType: DataType;
  decimals: number;
}

const adcBits = [8, 12, 16, 20, 24, 28, 32] as const;

export function getAdcBits(): AdcBits[] {
  return adcBits as any as AdcBits[];
}

export const AnalogResponse = {
  Linear: 'Linear',
  NonLinear: 'NonLinear'
} as const;

export type AnalogResponses = keyof typeof AnalogResponse;
export type AdcBits = typeof adcBits[number];

export interface AdcConversion {
  sensorTypeId: number;
  adcBits: AdcBits;
  response: AnalogResponses;
  // sensor min and max are only used when the response is linear
  sensorMin: number | null;
  sensorMax: number | null;
  interpolationGraphId: number | null;
  optionalResistance: number | null;
  // internal resistance is provided by the PLC if it is a analog input
  // if the input type is ModbusRawAdc then it is required.
  internalResistance?: number | null;
}

export interface ConstantInput {
  value: number | string;
}

export interface DigitalInput {
  useAlertValue: boolean;
  timerOn: number | null;
  timerOff: number | null;
}

export const EDataPointsInputSourceType = {
  AssetModules: 'AssetModules',
  Assets: 'Assets',
  SystemProcesses: 'SystemProcesses',
  EmbeddedAsset: 'EmbeddedAssets'
} as const;

export const DataPointsInputSourceTypes = getObjectValues(EDataPointsInputSourceType);
export type DataPointsInputSourceType = ValueOf<typeof EDataPointsInputSourceType>;
export interface DataPointsInput {
  sourceType: DataPointsInputSourceType;
  dataOptions: DataPointOption[];
  filterOptions: DataPointFilterOption[];
  testEntities: TestEntity[];
  assetGroupIdFilter: number | null;
}

export interface TestEntity {
  testValues: TestEntityValue[];
}

export interface TestEntityValue {
  dataOptionUuid: string;
  testValue: number;
}

export const EDataPointTypesForAggregation = {
  Data: EDataPointType.Data,
  ProductionStatus: EDataPointType.ProductionStatus,
  OperationalStatusField: EDataPointType.OperationalStatusField,
  Spec: EDataPointType.Spec,
  AlertGroup: 'AlertGroup'
} as const;

export type DataPointTypesForAggregation = ValueOf<typeof EDataPointTypesForAggregation>;
export const DataPointTypesForAggregationValues = getObjectValues(EDataPointTypesForAggregation);

export interface DataPointOption {
  uuid: string;
  dataPointType: DataPointTypesForAggregation;
  dataPointId: number | null;
  alertGroupId: number | null;
}

export function getDataOptionUUID(dataOptions: DataPointOption[], uuid: string): DataPointOption|undefined {
  return dataOptions.find(o => o.uuid === uuid);
}

export interface DataPointFilterOption extends DataPointOption {
  value: number;
}

export interface OutputInput {
  outputUuid: string;
}

export enum EOSInputLogicOptions {
  All = 'All',
  AtLeastOne = 'AtLeastOne'
}

export type OSInputLogicOption = keyof typeof EOSInputLogicOptions;

export interface OSInput {
  osDataPointId: number;
  logic: OSInputLogicOption;
  condition: boolean;
}

export interface SpecInput {
  specDataPointId: number;
}

export const EDataPointInputReferenceType = {
  DataPoint: 'DataPoint',
  AlertGroup: 'AlertGroup'
} as const;

export type DataPointInputReferenceType = ValueOf<typeof EDataPointInputReferenceType>;
export const DataPointInputReferenceTypes = getObjectValues(EDataPointInputReferenceType);

export interface DataPointInput {
  alertGroupId: number | null;
  dataPointId: number | null;
  referenceType: DataPointInputReferenceType;
}
