
import * as React from 'react';
import { type ComponentType, Suspense } from 'react';
import {type HeaderChangeProp, PageHeader, type PageHeaderProps, PageHeaderWrapper} from '../layout/PageHeader/PageHeader';
import {CenteredSpinner} from 'src/components/util/widgets/CenteredSpinner/CenteredSpinner';

type PageComponent<T = {}> = ComponentType<T>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function makePageWithHeader(Page: PageComponent, pageHeaderProps: PageHeaderProps): ComponentType {
  const ComponentWithHeader = () => (
    <PageHeader {...pageHeaderProps}>
      <Suspense fallback={<CenteredSpinner/>}>
        <Page />
      </Suspense>
    </PageHeader>
  );
  ComponentWithHeader.displayName = `PageWithHeader_${Page.displayName}`;
  return ComponentWithHeader;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export function makePageWithDynamicHeader(Page: ComponentType<HeaderChangeProp>, removeMargin: boolean): ComponentType {
  const PageWithDynamicHeader = () => (
    <PageHeaderWrapper page={Page}  removeMargin={removeMargin} />
  );
  PageWithDynamicHeader.displayName = `PageWithDynamicHeader_${Page.displayName}`;
  return PageWithDynamicHeader;
}
