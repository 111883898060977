import React from 'react';
import styles from './PageContainer.module.scss';
import {AppTheme} from 'src/appTheme';

export interface PageContainerProps {
  children?: any;
}

export function PageContainer(props: PageContainerProps) {
  return (
    <div className={styles['page-body']} style={AppTheme.styles.offsetBackground}>
      {props.children}
    </div>
  );
}
