import {combineReducers} from 'redux';
import {isSpRevision, isSpStatus, type SpRevisionActions, spRevisions} from './spRevision';
import {type AssetRevisionActions, assetRevisions, isAssetRevision, isAssetStatus} from './assetRevision';
import {makeRevisionEntity, type RevisionEntity} from '../../../types/Revision';
import {type Installation} from '../installation';
import {type Site} from '../site';
import {type Company} from '../company';
import {type Dealer} from '../dealer';
import {type NamedEntity} from '../../../types/Entity';
import {type AlertSetPointOverride} from '../../abstract/dataPointAssignment';
import {cast, throwErr} from '../../../util';
import {type UuidModel} from 'src/common/types/UuidModel';

export interface InstallationModel extends UuidModel{
  id: number;
  name: string;
  installationId: number;
  specs: CurrentSpec[];
}

export interface SpecValue {
  dataPointId: number;
  value: number|null;
  stringValue: string|null;
  unitId: number;
  id: number;
  assetId: number|null;
}

export interface CurrentSpec {
  specValue: SpecValue;
  specValueId: number;
}
export type InstallationModelRevisionType = 'Sp' | 'Asset';
export function getInstallationModelRevisionType(revision: InstallationModelRevision): InstallationModelRevisionType {
  return isAssetRevision(revision) ? 'Asset'
    : isSpRevision(revision) ? 'Sp'
      : throwErr<InstallationModelRevisionType>('invalid revision object given');
}
export interface InstallationModelRevision extends RevisionEntity {
  id: number;
  installationRevisionId: number;
  rmxBoxSourceAssignments: RmxBoxSourceAssignment[];
  parents: SpRevisionRelationship[];
  children: SpRevisionRelationship[];
  alertSetPointOverrides: AlertSetPointOverride[];
}

export interface InstallationModelStatus {
  status: 'Activated' | 'Not Activated';
  installation: Installation;
  site: Site;
  company: Company;
  dealer: Dealer;
}

export function getInstallationModelStatusEntity(status: InstallationModelStatus) {
  if (isSpStatus(status)) {
    return status.systemProcess as NamedEntity;
  } else if (isAssetStatus(status)) {
    return status.asset as NamedEntity;
  }
  throw new Error('Status not valid');
}

export function makeInstallationModelRevision(installationRevisionId: number = 0, revisionTitle: string = ''): InstallationModelRevision {
  return {
    id: 0,
    ...makeRevisionEntity(revisionTitle),
    parents: [],
    rmxBoxSourceAssignments: [],
    installationRevisionId: installationRevisionId,
    alertSetPointOverrides: [],
    children: []
  };
}

export interface SpRevisionRelationship {
  id: number;
  parentSpRevisionId: number | null;
  parentAssetRevisionId: number | null;
  childSpRevisionId: number | null;
  childAssetRevisionId: number | null;
}

export interface RmxBoxSourceAssignment {
  id: number;
  templateRevisionLuid: string;
  templateSourceId?: number | null;
  rmxBoxSourceId?: number | null;
  rmxBoxRevisionId: number;
  assetRevisionId: number | null;
  systemProcessRevisionId: number | null;
  type: RmxBoxSourceAssignmentType;
  analogInput?: number | null;
  digitalInput?: number | null;
  inputId?: number | null;
  // a reference to the asset id for the asset module
  assetModuleId: number | null;
  isAssetModule: boolean;
  digitalInputTimerOnOverride: number | null;
  digitalInputTimerOffOverride: number | null;
  digitalInputNormalState: DigitalInputNormalState | null;
}

export type DigitalInputNormalState = typeof DigitalInputNormalStates[number];
export const DigitalInputNormalStates = [
  'NormallyOpen',
  'NormallyClosed'
] as const;

export enum ERmxBoxSourceAssignmentType {
  Analog = 'Analog',
  ModBus = 'ModBus',
  Digital = 'Digital'
}

export type RmxBoxSourceAssignmentType = keyof typeof ERmxBoxSourceAssignmentType;

export const installationEntities = combineReducers({
  assetRevisions,
  spRevisions
});

export type InstallationEntitiesActions = AssetRevisionActions | SpRevisionActions;

export const analogOrDigital = cast<RmxBoxSourceAssignmentType[]>(['Analog', 'Digital']);
