import { useNavigate } from 'react-router-dom';
import { getLinkByOrgLevel, getOrgLevelFromUrl, useCurrentUrl, useRouteId } from '../DashboardRoutePaths';
import { useGlobalSearchState } from '../GlobalSearch/GlobalSearch';
import { type MenuConfig, useShowMenu } from '../../../portal/menu-modal/MenuModal/MenuModal';
import { type AlertStatus, getStatuses } from '../../../api/userDashboardApi';
import { useQuery } from '@tanstack/react-query';
import { useModal } from '../../../portal/hooks/useModal';
import type { NotesEditorModalParam } from '../../../components/dashboard/NotesEditorModal';
import { useMemo } from 'react';
import type { ActionConfig } from '../../../portal/menu-modal/ActionModal/ActionModal';


export type UseDashboardTilesResult = ReturnType<typeof useDashboardTiles>;

export function useDashboardTiles() {
  const navigate = useNavigate();
  const url = useCurrentUrl();
  const searchState = useGlobalSearchState();
  // sets the show menu type to AlertStatus allowing us to use the AlertStatus type in the menuConfig
  const { showMenu } = useShowMenu<AlertStatus>();
  const uuid = useRouteId();
  const state = useQuery({
    queryKey: ['statuses', getOrgLevelFromUrl(url), uuid, searchState.value],
    queryFn: async () => await getStatuses(getOrgLevelFromUrl(url), uuid, searchState.value),
    placeholderData: (previousData) => previousData // ensure when searching the loading indicator does not show
  });
  const resp = state.data;

  const notesEditorModal = useModal<NotesEditorModalParam>({ onClose: () => state.refetch() });
  // this is the menuConfig that is passed into the showMenu function
  // because its generic we're able to define the handlers for our buttons which are specific to the AlertStatus type
  const menuConfig: MenuConfig<AlertStatus> = useMemo(() => ({
    open: r => navigate(getLinkByOrgLevel(r.nextId, r.nextName, r.nextType)),
    alerts: () => console.log('Displaying alerts modal'),
    graphing: () => console.log('Displaying graphing modal'),
    edit: (r) => notesEditorModal.open({ type: r.orgLevel, id: r.id, notes: r.notes }),
    getLabel: r => r.name
  }), [navigate, notesEditorModal]);

  const actionConfig: ActionConfig<AlertStatus> = useMemo(() => ({}), []);
  return {
    state,
    resp,
    searchState,
    notesEditorModal,
    menuConfig,
    showMenu,
    actionConfig
  };
}
