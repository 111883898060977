import type { UseDashboardTilesResult } from '../hooks/useDashboardTiles';
import s from '../pages/TilesPage/TilesPage.module.scss';
import { ReactQueryLayout } from '../../../components/util/LoadableResourceLayout';
import { NotesEditorModal } from '../../../components/dashboard/NotesEditorModal';
import { DashboardGrid } from '../../../components/util/Grid/DashboardGrid';
import { mapOrDefault } from '../../../common/util';
import { getLinkByOrgLevel } from '../DashboardRoutePaths';
import React from 'react';

export type DashboardTilesProps = UseDashboardTilesResult;

export function DashboardTiles({
  state,
  resp,
  searchState,
  notesEditorModal,
  menuConfig,
  showMenu,
  actionConfig
                               }: DashboardTilesProps) {
  return (
    <div className={s['tiles-view']}>
      <ReactQueryLayout state={state}>
        <NotesEditorModal state={notesEditorModal} />
        <DashboardGrid noItemsText="None found." items={mapOrDefault(resp?.statuses, status => {
          return ({
            title: status.name,
            link: getLinkByOrgLevel(status.nextId, status.nextName, status.nextType),
            backgroundColor: status.color,
            symbol: status.symbol,
            notes: status.notes,
            onRightClick: async () => {
              // passing in the resource and the menuConfig to the showMenu function
              showMenu(status, menuConfig, actionConfig);
              await state.refetch();
            }
          });
        })} />
      </ReactQueryLayout>
    </div>
  );
}
