import React from 'react';
import {Redirect} from 'src/router';
import {RoutePaths} from 'src/router/RoutePaths';

export class Home extends React.Component {
  render() {
    return (
      <Redirect to={RoutePaths.dashboard}/>
    );
  }
}
