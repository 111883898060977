import {type Dispatch} from 'redux';
import {type Site, siteStore} from './entities/site';
import {type Installation, installationStore} from './entities/installation';
import {calculateSize} from '../util/object';
import {type AssetRevision, assetRevisionStore} from './entities/installationEntities/assetRevision';
import {type IdDictionary} from '../util';
import {type CommonConfigurationResponse, getCommonConfigurationActions} from './commonConfiguration';
import {batchActions} from 'redux-batched-actions';
import {type SpRevision, spRevisionStore} from './entities/installationEntities/spRevision';
import {setUnits} from './entities/unit';
import {getCompanyConfigurationState} from 'src/api/companyConfigApi';
export const loadCompanyConfiguration = (id: number) => async (dispatch: Dispatch) => {
  const config = await getCompanyConfigurationState(id);
  if (import.meta.env.DEV) {
    calculateSize(config, 'Company Configuration Response');
  }
  await dispatch(batchActions([
    ...getCommonConfigurationActions(config),
    setUnits(config.units),
    siteStore.actions.set(config.sites),
    installationStore.actions.set(config.installations),
    assetRevisionStore.actions.set(config.assetRevisions),
    spRevisionStore.actions.set(config.systemProcessRevisions)
  ], 'BATCH_SET_COMPANY_CONFIGURATION_STATE'));
};

export interface CompanyConfiguration extends CommonConfigurationResponse {
  sites: IdDictionary<Site>;
  installations: IdDictionary<Installation>;
  assetRevisions: IdDictionary<AssetRevision>;
  systemProcessRevisions: IdDictionary<SpRevision>;
}
