import {EnvironmentFileNotConfiguredException} from 'src/exceptions/EnvironmentFileNotConfiguredException';
import {type ValidationError} from 'src/common/util/yup';
import {EnvironmentSchema} from 'src/env/envSchema';

export function validateEnvironment() {
  try {
    EnvironmentSchema.validateSync(import.meta.env, {
      abortEarly: false
    });
  } catch (e) {
    const err = e as ValidationError;
    const validationErrors = err.inner as ValidationError[];
    const errorMessages = validationErrors.map(v => `${v.path}: ${v.errors.join(', ')}`).join('\n');

    throw new EnvironmentFileNotConfiguredException(errorMessages +
      '\nPlease configure values in the .env file, or copy .env.example to .env to get started');
  }
}
