import {type CommonActions, type CommonDispatch} from '../../index';
import {saveRmxBox} from '../rmxBox';
import {assetRevisionStore, deleteAssetRevisionAction} from './assetRevision';
import {deleteSpRevisionAction, spRevisionStore} from './spRevision';
import {batchActions} from 'redux-batched-actions';
import {installationStore} from '../installation';
import {
  type DeleteInstallationRevisionEntityResponse,
  type SaveInstallationRevisionEntityResponse
} from 'src/api/companyConfigApi';

export function dispatchInstallationRevisionEntityActions(dispatch: CommonDispatch, actions: CommonActions[]) {
  dispatch(batchActions(actions));
}

export function getInstallationRevisionEntityUpsertActions(response: SaveInstallationRevisionEntityResponse) {
  const actions: CommonActions[] = [installationStore.actions.save(response.installation)];
  for (const rmxBox of response.rmxBoxes) {
    actions.push(saveRmxBox(rmxBox));
  }
  for (const assetRevision of response.assetRevisions) {
    actions.push(assetRevisionStore.actions.save(assetRevision));
  }
  for (const spRevision of response.spRevisions) {
    actions.push(spRevisionStore.actions.save(spRevision));
  }
  return actions;
}

export function getInstallationRevisionEntityDeleteActions(response: DeleteInstallationRevisionEntityResponse) {
  const actions: CommonActions[] = [];
  for (const assetRevision of response.deletedAssetRevisions) {
    actions.push(deleteAssetRevisionAction(assetRevision.id));
  }
  for (const spRevision of response.deletedSpRevisions) {
    actions.push(deleteSpRevisionAction(spRevision.id));
  }
  return [...actions, ...getInstallationRevisionEntityUpsertActions(response.newInstallationRevision)];

}
