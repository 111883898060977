import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type Loader<TParam> = (p: TParam) => Promise<void>;
type CloseFunction<TReturnValue> = (r?: TReturnValue | undefined) => void;
type PromiseResolver<T> = (value: T) => void;
type PromiseRejecter = (reason?: any) => void;

export type ModalState<TParam, TReturnValue = undefined> = {
  setIsOpen: (s: boolean) => void;
  open: (p: TParam) => Promise<TReturnValue | undefined>;
  close: CloseFunction<TReturnValue>;
  setLoader: (loader: Loader<TParam>) => void;
  isLoading: boolean;
} & OpenParamState<boolean, TParam>;

type OpenParamState<TOpen extends boolean, TParam> = {
  param: TOpen extends true ? TParam : undefined; // trying to get the param to be not null when isOpen is true. not working :(
  isOpen: TOpen;
};

export function useModal<TParam = undefined, TReturnValue = undefined>(params?: {
  onClose?: (result?: TReturnValue) => unknown;
}): ModalState<TParam, TReturnValue> {
  const onCloseRef = useRef(params?.onClose);
  onCloseRef.current = params?.onClose;
  const [isOpen, setIsOpenInner] = useState(false);
  const setIsOpen = useCallback((s: boolean, result?: TReturnValue) => {
    setIsOpenInner((prev) => {
      if (prev && !s && onCloseRef.current) {
        onCloseRef.current(result);
      }
      return s;
    });
  }, []);
  const [isLoading, setLoading] = useState(false);
  const [param, setParam] = useState<TParam | undefined>(undefined);
  const resolverRef = useRef<PromiseResolver<TReturnValue | undefined> | undefined>(undefined);
  const rejectRef = useRef<PromiseRejecter | undefined>(undefined);
  const loaderRef = useRef<Loader<TParam>>((p: TParam) => Promise.resolve());

  const open = useCallback(
    (p: TParam) => {
      return new Promise<TReturnValue | undefined>(async (resolve, reject) => {
        setParam(p);
        resolverRef.current = resolve;
        rejectRef.current = reject;
        try {
          setIsOpen(true);
          setLoading(true);
          await loaderRef.current(p);
        } catch (ex) {
          console.error(ex);
          alert('Failed to load information for the modal.');
        } finally {
          setLoading(false);
        }
      });
    },
    [setIsOpen]
  );

  const close = useCallback(
    (r?: TReturnValue | undefined) => {
      setIsOpen(false, r);
      resolverRef.current?.(r);
    },
    [setIsOpen]
  ) as CloseFunction<TReturnValue>;
  const setLoader = useCallback(
    (loader: Loader<TParam>) => {
      loaderRef.current = loader;
    },
    [loaderRef]
  );

  return useMemo(
    () => ({
      open,
      close,
      isOpen,
      param,
      setLoader,
      isLoading,
      setIsOpen
    }),
    [open, close, isOpen, param, setLoader, isLoading, setIsOpen]
  );
}

/**
 * Closes the modal if the resource is not truthy
 * @param resource
 * @param modalState
 */
export function useCloseIfMissingResource<T, TParam, TReturn>(resource: T, modalState: ModalState<TParam, TReturn>) {
  useEffect(() => {
    if (!resource && modalState.isOpen) {
      modalState.close();
    }
  }, [resource, modalState]);
}
