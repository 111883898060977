
import {navigator} from './navigator';
import {browser} from './browser';

/**
 * Purposefully only returning a plain object, combineReducers will be called later.
 * @param history
 */
const rootReducer = () => ({
  navigator,
  browser: browser
});
export default rootReducer;
