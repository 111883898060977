import {type CommonState} from '../index';
import type Action from '../index';
import {combineReducers} from 'redux';
import {convertToDropDownOptions, getObjectValues, groupBy} from '../../util';
import {selector} from '../selectors';
import {convertCamelCaseToTitleCase} from '../../util/string';
import {EDataPointType} from './dataPoint';

export interface UnitGroup {
  id: number;
  name: string;
  unitGroupTypeId: number;
}

export interface UnitGroupItems {
  [key: number]: UnitGroup;
}

export interface UnitGroupState {
  items: UnitGroupItems;
}

export type SetUnitGroupsAction = Action<'SET_UNIT_GROUPS', UnitGroupItems>;
export const setUnitGroups = (items: UnitGroupItems): SetUnitGroupsAction => ({type: 'SET_UNIT_GROUPS', data: items});

export type UnitGroupActions = SetUnitGroupsAction;

function unitGroupItems(state: UnitGroupItems = {}, action: UnitGroupActions) {
  switch (action.type) {
    case 'SET_UNIT_GROUPS': return action.data;
    default: return state;
  }
}

export const unitGroups = combineReducers<UnitGroupState>({
  items: unitGroupItems
});

export const getUnitGroupItems = (state: CommonState) => state.entities.unitGroups.items;
export const getUnitGroupById = (state: CommonState) => (id: number) => getUnitGroupItems(state)[id];
export const getUnitGroupsArray = (state: CommonState): UnitGroup[] => getObjectValues(getUnitGroupItems(state));
const specialUnitGroupTypeId  = 1;
export const getUnitGroupsAsOptions = (state: CommonState) =>
  convertToDropDownOptions(getUnitGroupsArray(state).filter(unitGroup => unitGroup.unitGroupTypeId !== specialUnitGroupTypeId));
export const getUnitGroupsAsOptionsWithUnitlessTypes = (state: CommonState) =>
  convertToDropDownOptions(getUnitGroupsArray(state));
export const getUnitGroupsGroupedByName = (state: CommonState) =>
  groupBy(getUnitGroupsArray(state), (unitGroup: UnitGroup) => unitGroup.name);

export const getUnitGroupByName = (state: CommonState) => (name: string) =>
  (getUnitGroupsGroupedByName(state).get(name) as UnitGroup[])[0];

export const getAlertTriggerUnitGroup = selector(s => getUnitGroupByName(s)(convertCamelCaseToTitleCase(EDataPointType.AlertTrigger)));
export const getManufacturerAlertCodeUnitGroup = selector(s => getUnitGroupByName(s)(convertCamelCaseToTitleCase(EDataPointType.ManufacturerAlertCode)));
export const getAlertStatusRegisterUnitGroup = selector(s => getUnitGroupByName(s)(convertCamelCaseToTitleCase(EDataPointType.AlertStatusRegister)));
export const getAlertSetPointUnitGroup = selector(s => getUnitGroupByName(s)(convertCamelCaseToTitleCase(EDataPointType.AlertSetPoint)));
