import React from 'react';
import { getPreviousBreadCrumbLink } from 'src/pages/Dashboard/DashboardBreadCrumbs/DashboardBreadCrumbs';
import { useDashboardTiles } from '../../hooks/useDashboardTiles';
import { DashboardTiles } from '../../DashboardTiles/DashboardTiles';
import { PanelLayout } from '../../../../components/util/PanelLayout/PanelLayout';

export function TilesPage(_: {}) {
  const dashboardTiles = useDashboardTiles();
  const {resp, searchState} = dashboardTiles;
  return (<>
    <PanelLayout breadCrumbs={resp?.breadCrumbs} backLink={getPreviousBreadCrumbLink(resp?.breadCrumbs)} searchState={searchState}>
      <DashboardTiles {...dashboardTiles} />
    </PanelLayout>
  </>);
}
