import React, {type ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import styles from './Input.module.scss';
import {getFieldValue, goToNextElementOnEnterKeyPress} from 'src/util';
import {RedErrorMessage} from '../../RedErrorMessage/RedErrorMessage';
import debounce from 'lodash/debounce';
import {FormControl, type FormControlElement} from 'src/components/util/Controls/Form/Control/FormControl';
import {useMountedRef} from 'src/hooks/useMount';
export type InputType = 'number' | 'text' | 'password' | 'email' | 'textarea' | 'color';
export interface InputProps {
  name: string;
  type?: InputType;
  disabled?: boolean;
  rows?: number;
  defaultValue?: any;
  emptyAsNull?: boolean;
  placeholder?: string;
  autoComplete?: string;
  overrideDebouncePeriod?: number;
}

const Input = (props: InputProps) => {
  const {emptyAsNull, name, overrideDebouncePeriod} = props;
  const [field, {value, error, touched}] = useField<string>(name);
  const mounted = useMountedRef();
  const {setFieldValue} = useFormikContext<any>();
  const fieldOnChange = field.onChange;
  if (!value && props.defaultValue) {
    setFieldValue(name, props.defaultValue, true);
  }
  const [localValue, setLocalValue] = useState<null|string>(getFieldValue(value, emptyAsNull));
  // if the value in the form changes, ensure the local value changes too.
  useEffect(() => setLocalValue(getFieldValue(value, emptyAsNull)), [value, emptyAsNull]);

  const getValueFromEvent = useCallback((e: ChangeEvent<FormControlElement>) =>
    getFieldValue( (e.target as HTMLInputElement).value, emptyAsNull), [emptyAsNull]);

  const rawOnChange = useCallback((e: ChangeEvent<FormControlElement>) => {
    if(!mounted.current) {
      return;
    }
    const val = getValueFromEvent(e);
    setLocalValue(val);
    if (val == null) {
      setFieldValue(name, null, true);
    } else {
      fieldOnChange(e);
    }
  }, [mounted, getValueFromEvent, setFieldValue, name, fieldOnChange]);
  // optimize updates by keeping a local state for fast reflection of user input.
  // after debounce period, the changes are committed to the entire formik state because formik has to revalidate/re-render the entire form which can be very slow.
  const debouncedChange = useMemo(() => debounce(rawOnChange,overrideDebouncePeriod ?? 1000), [rawOnChange, overrideDebouncePeriod]);
  const localOnChangeThenDebounce = useCallback((e: ChangeEvent<FormControlElement>) => {
    e.persist();
    setLocalValue(e.currentTarget.value! as string);
    return debouncedChange(e);
  }, [debouncedChange]);
  const rows = {rows: props.rows};
  return (
    <React.Fragment>
      <FormControl
        as={props.type === 'textarea' ? 'textarea' : 'input'}
        isInvalid={touched && Boolean(error)}
        className={styles['form-inputs']}
        {...rows}
        {...field}
        value={localValue ?? ''}
        onKeyDown={props.type !== 'textarea' ? goToNextElementOnEnterKeyPress : undefined}
        onChange={localOnChangeThenDebounce}
        onBlur={rawOnChange}
        disabled={props.disabled}
        type={props.type || 'text'}
        placeholder={props.placeholder ? props.placeholder : ''}
        autoComplete={props.autoComplete ? props.autoComplete : 'on'}
      />
      <RedErrorMessage name={props.name}/>
    </React.Fragment>
  );
};

export default Input;
