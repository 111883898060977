import type TemplateSource from '../../../types/TemplateSource';
import {type TemplateInput} from '../../../types/TemplateInput';
import {combineReducers} from 'redux';
import {assignableAssetTemplateRevisions} from './assetTemplate';
import {aSpTemplateRevisions} from './aSpTemplateRevision';
import { type OverridableGauge } from '../../../types/Gauge';
import {type DataPointAlertSetPoints} from '../../abstract/dataPointAssignment';

export interface AssignableTemplateRevision {
  requiredSpecIds: number[];
  id: number;
  revisionTitle: string;
  name: string;
  luid: string;
  templateSources: TemplateSource[];
  analogInputs: TemplateInput[];
  digitalInputs: TemplateInput[];
  overridableGauges: OverridableGauge[];
  dataPointSetPoints: DataPointAlertSetPoints[];
}

export const assignableTemplateRevisions = combineReducers({
  spTemplates: aSpTemplateRevisions,
  assetTemplates: assignableAssetTemplateRevisions
});
