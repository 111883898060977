import React, {Suspense} from 'react';
import AppRoutes from './router/AppRoutes';
import {GlobalAlertDialogProvider} from 'src/components/util/AlertDialog/AlertDialog';
import {SignedImageCacheProvider} from './components/util/SignedImageCacheContext';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import { GlobalMenuModalProvider } from './portal/menu-modal/MenuModal/MenuModal';

const queryClient = new QueryClient();
function App(_: {}) {
  return(
    <QueryClientProvider client={queryClient}>
      <SignedImageCacheProvider>
        <GlobalAlertDialogProvider>
          <GlobalMenuModalProvider>
            <Suspense fallback={<div>Loading...</div>}>
              <AppRoutes/>
            </Suspense>
          </GlobalMenuModalProvider>
        </GlobalAlertDialogProvider>
      </SignedImageCacheProvider>
    </QueryClientProvider>
  );
}

export default App;
