import {combineReducers} from 'redux';
import {type IdDictionary, groupBy} from '../../util';
import {type CommonDispatch, type CommonState} from '../index';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction, createReducer} from 'typesafe-actions';
import {
  activateDeactivateInstallation,
  activateInstallationRevision, addInstallation,
  deleteInstallation,
  saveInstallationRevisionForm,
  updateInstallation
} from 'src/api/companyConfigApi';
import {siteStore} from './site';
import {immutableSort} from '../../util/array';
import {makeInstallationFormFields} from 'src/common/util/factory';
import {copyObject, getObjectKeys} from 'src/common/util/object';

export interface Installation extends InstallationCore {
  activeRevisionId?: number;
  installationRevisions: InstallationRevision[];
  activeRevision?: InstallationRevision;
  uuid: string;
}
export interface InstallationCore {
  id: number;
  name: string;
  siteId: number;
  alertIndicatorsEnabled: boolean;
  mainRmxBoxAssetId: number | null;
  useGps: boolean;
  activated: boolean;
}

export interface InstallationFormFields extends InstallationCore {
}

export interface InstallationRevision extends InstallationRevisionForm {
  installationId: number;
  updatedAt: string;
}

export interface InstallationRevisionForm {
  id: number;
  revisionTitle: string;
  revisionDescription: string;
}

const activateRevision =
  (installationId: number, installationRevisionId: number) => async (dispatch: CommonDispatch, getState: () => CommonState) => {
  await activateInstallationRevision(installationRevisionId);
  const installation = selectors.getById(getState())(installationId);
  dispatch(actions.save({
    ...installation,
    activeRevisionId: installationRevisionId,
    activeRevision: installation.installationRevisions.find(r => r.id === installationRevisionId) as InstallationRevision
  }));
};

const activateDeactivate =
  (installationId: number) => async (dispatch: CommonDispatch, getState: () => CommonState) => {
  await activateDeactivateInstallation(installationId);
  const installation = selectors.getById(getState())(installationId);
  dispatch(actions.save({
    ...installation,
    activated: !installation.activated
  }));
};
const saveRevisionTitleAndDescription = (installationId: number, revisionForm: InstallationRevisionForm) =>
  async (dispatch: CommonDispatch, getState: () => CommonState) => {
  await saveInstallationRevisionForm(revisionForm);
  const installation = selectors.getById(getState())(installationId);
  dispatch(actions.save({
    ...installation,
    activeRevision: (installation.activeRevision || {id: undefined}).id === revisionForm.id ?
      {
        ...installation.activeRevision,
        revisionTitle: revisionForm.revisionTitle,
        revisionDescription: revisionForm.revisionDescription
      } as InstallationRevision : installation.activeRevision,
    installationRevisions: installation.installationRevisions.map(r => {
      if (r.id === revisionForm.id) {
        return {
          ...r,
          revisionTitle: revisionForm.revisionTitle,
          revisionDescription: revisionForm.revisionDescription
        };
      }
      return r;
    })
  }));
};

const saveFormFields = createAction('INSTALLATION/SAVE_FORM_FIELDS')<InstallationFormFields>();

const createNew = (installation: InstallationFormFields) => async (dispatch: CommonDispatch) => {
  const response: Installation = await addInstallation(installation);
  dispatch(actions.save(response));
  return response;
};

const updateExisting = (installation: InstallationFormFields) => async (dispatch: CommonDispatch) => {
  const response: Installation = await updateInstallation(installation);
  dispatch(saveFormFields(response));
  return response;
};

const deleteExisting = (installation: Installation) => async (dispatch: CommonDispatch) => {
  const response: Installation = await deleteInstallation(installation);
  dispatch(actions.save(response));
  return response;
};

type SelectedInstallationRevisionActions = ReturnType<typeof setSelectedRevision>;
const setSelectedRevision = createAction('SET_SELECTED_INSTALLATION_REVISION')<{installationId: number; installationRevisionId: number}>();
const selectedInstallationRevisions = createReducer<IdDictionary<number>, SelectedInstallationRevisionActions>({})
  .handleAction(setSelectedRevision, (_, action) => ({[action.payload.installationId]: action.payload.installationRevisionId}));

const getRevisionSelectionState = (state: CommonState) => state.entities.installations.selectedInstallationRevisions;

const getSelectedRevisionId = (state: CommonState) => (installationId: number): number => {
  const selected = getRevisionSelectionState(state);
  const installationRevisionId = selected[installationId];
  if (installationRevisionId === undefined) {
    return getLatestRevision(state)(installationId).id;
  }
  return installationRevisionId;
};

const selectors = createStandardSelectors(placeholder<Installation>(), s => getEntities(s).installations);

const getSelectedRevision = (state: CommonState) => (installationId: number): InstallationRevision => {
  const selected = getRevisionSelectionState(state);
  const installationRevisionId = selected[installationId];
  if (installationRevisionId === undefined) {
    return getLatestRevision(state)(installationId);
  }
  const revisions = selectors.getById(state)(installationId);
  return revisions.installationRevisions.find(ir => ir.id === installationRevisionId)!;
};

const getLatestRevision = (state: CommonState) => (installationId: number) => {
  const installation = selectors.getById(state)(installationId);
  return immutableSort(installation.installationRevisions, (a, b) => b.id - a.id)[0];
};

const actions = createStandardActions(placeholder<Installation>(), 'INSTALLATION/SET', 'INSTALLATION/SAVE');
export type InstallationActions = GetActions<typeof actions> | SelectedInstallationRevisionActions | ReturnType<typeof saveFormFields>;
export const installations = combineReducers({
  items: standardItemsReducer<Installation, InstallationActions>(actions)
    .handleAction(saveFormFields, (state, action) => {
      const newState: Installation = copyObject(getObjectKeys(makeInstallationFormFields()),  action.payload, {...state[action.payload.id]});
      return {
        ...state,
        [action.payload.id]: newState
      };
    }),
  selectedInstallationRevisions: selectedInstallationRevisions
});

export const installationStore = {
  selectors: {
    ...selectors,
    getAsOptions: selector(s => selectors.getAsArray(s)
      .map<DropdownOption>((i: Installation) => ({value: i.id, label: `${siteStore.selectors.getById(s)(i.siteId).name} - ${i.name}`}))),
    getLatestRevision: getLatestRevision,
    getSelectedRevision: getSelectedRevision,
    getSelectedRevisionId: getSelectedRevisionId,
    getGroupedBySite:  selector(s => groupBy(selectors.getAsArray(s), (installation: Installation) => installation.siteId))
  },
  actions: {
    ...actions,
    deleteExisting: deleteExisting,
    updateExisting: updateExisting,
    createNew: createNew,
    activateRevision: activateRevision,
    activateDeactivateInstallation: activateDeactivate,
    saveRevisionTitleAndDescription: saveRevisionTitleAndDescription,
    setSelectedRevision: setSelectedRevision
  }
} as const;
