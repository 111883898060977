import {api} from 'src/api/index';
import {type StoredFile} from 'src/api/files';
import {type ControllerTypeRevision} from 'src/common/redux/entities/controllerTypeRevision';
import {type ControllerType, type ControllerTypeSaveResponse} from 'src/common/redux/entities/controllerType';

export async function upsertControllerType(payload: ControllerType) {
  return (await api.post<ControllerTypeSaveResponse>('/ControllerType/Upsert', payload)).data;
}

export async function getControllerType(id: number) {
  return (await api.get<ControllerType>(`/ControllerType/${id}`)).data;
}



export type ControllerTypeMedia = {
  thumbnail: StoredFile | null;
  files: StoredFile[];
};

export async function getControllerTypeMedia(controllerTypeId: number) {
  return (await api.post<ControllerTypeMedia>(`/ControllerType/${controllerTypeId}/Media`)).data;
}

export async function updateControllerTypeThumbnail(controllerTypeId: number, fileUuid: string) {
  return (await api.put<ControllerTypeSaveResponse>(`/ControllerType/${controllerTypeId}/Thumbnail`, {
    fileUuid: fileUuid
  })).data;
}

export async function upsertControllerTypeRevision(payload: ControllerTypeRevision) {
  return (await api.post<ControllerTypeRevision>('/ControllerTypeRevision/Upsert', payload)).data;
}
