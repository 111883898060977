import {type EExtrema} from '../common/types/Gauge';
import {type DataPoint} from '../common/redux/entities/dataPoint';
import {api} from './index';

export async function addDataPoint(dataPoint: DataPoint) {
  return (await api.post<DataPoint>('/DataPoint', dataPoint)).data;
}

export async function updateDataPoint(dataPoint: DataPoint) {
  return (await api.put<DataPoint>('/DataPoint', dataPoint)).data;
}

interface AddExtremaDataPointResponse {
  extrema: DataPoint;
  dataPoint: DataPoint;
}

export async function addExtremaDataPoint(parentDpId: number, extrema: EExtrema) {
  return (await api.put<AddExtremaDataPointResponse>('/DataPoint/extrema', {
    dataPointId: parentDpId,
    extrema: extrema
  })).data;
}
