import {type CommonDispatch} from '../../index';
import {combineReducers} from 'redux';
import {type InstallationModel, type InstallationModelRevision, type InstallationModelStatus} from './index';
import {type DeleteInstallationRevisionEntityResponse, type SaveInstallationRevisionEntityResponse} from 'src/api/companyConfigApi';
import {createStandardSelectors, getInstallationEntities, selector} from '../../selectors';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../../utils';
import {
  dispatchInstallationRevisionEntityActions,
  getInstallationRevisionEntityDeleteActions,
  getInstallationRevisionEntityUpsertActions,
} from './common';
import {convertToDropDownOptions, groupBy} from '../../../util';
import {type GaugeOverride} from '../../../types/Gauge';
import {createAction} from 'typesafe-actions';
import {deleteSpRevision, saveSpRevision} from 'src/api/companyConfigApi';

export interface SystemProcess extends InstallationModel {
  displayInOwnTab: boolean;
  powerQualityFactor: number|null;
  powerRatePerKW: number|null;
}

export interface SpStatus extends InstallationModelStatus {
  systemProcess: SystemProcess;
}
export function isSpStatus(status: InstallationModelStatus): status is SpStatus {
  return 'systemProcess' in status;
}

export interface SpRevision extends InstallationModelRevision {
  systemProcess: SystemProcess;
  systemProcessId?: number;
  spTypeId: number;
  spTemplateId: number;
  spTemplateRevisionId: number;
  gaugeOverrides: GaugeOverride[];
}

export function isSpRevision(revision: InstallationModelRevision): revision is SpRevision {
  return 'systemProcess' in revision || 'systemProcessId' in revision;
}

const actions = createStandardActions(placeholder<SpRevision>(), 'SP_REVISION/SET', 'SP_REVISION/SAVE');
export const deleteSpRevisionAction = createAction('SP_REVISION/DELETE')<number>();
export type SpRevisionActions = GetActions<typeof actions> | ReturnType<typeof deleteSpRevisionAction>;
export const spRevisions = combineReducers({
  items: standardItemsReducer<SpRevision, SpRevisionActions>(actions)
    .handleAction(deleteSpRevisionAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload];
      return newState;
    })
});
const selectors = createStandardSelectors(placeholder<SpRevision>(), s => getInstallationEntities(s).spRevisions);

export const spRevisionStore = {
  selectors: {
    ...selectors,
    getByInstallationRevisionId:
      selector(s => (id: number) => selectors.getAsArray(s).filter(r => r.installationRevisionId === id)),
    byInstallationRevisionId: selector(s => groupBy(selectors.getAsArray(s), (r) => r.installationRevisionId)),
    getSpChildrenIds: selector(s => (id: number) => selectors.getById(s)(id).children
      .filter(r => !!r.childSpRevisionId)
      .map(r => r.childSpRevisionId!))
  },
  actions: {
    ...actions,
    upsert: (item: SpRevision) => async (dispatch: CommonDispatch) => {
      const response: SaveInstallationRevisionEntityResponse = await saveSpRevision(item);
      dispatchInstallationRevisionEntityActions(dispatch, getInstallationRevisionEntityUpsertActions(response));
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response: DeleteInstallationRevisionEntityResponse = await deleteSpRevision(id);
      dispatchInstallationRevisionEntityActions(dispatch, getInstallationRevisionEntityDeleteActions(response));
    }
  }
} as const;

export const convertSpRevisionsToOptions = (revs: SpRevision[]) => convertToDropDownOptions(revs.map(r => ({id: r.id, name: r.systemProcess.name})));
