import {type Company} from '../common/redux/entities/company';
import {type Dealer} from '../common/redux/entities/dealer';
import {type GlobalConfigurationResponse} from '../common/redux/globalConfiguration';
import {type Manufacturer} from '../common/redux/entities/manufacturers';
import {type Unit} from '../common/redux/entities/unit';
import {api} from './index';
import {type ConnectionKit} from 'src/common/redux/entities/connectionKit';
import {type ProtocolAdapter} from 'src/common/redux/entities/protocolAdapter';

export async function getGlobalConfigurationState() {
  return (await api.get<GlobalConfigurationResponse>('/GlobalConfiguration')).data;
}

export async function getUnits() {
  return (await api.get<Unit[]>('/Unit')).data;
}

export async function upsertDealer(dealer: Dealer) {
  return (await api.post<Dealer>('/Dealer', dealer)).data;
}

export async function upsertManufacturer(form: Manufacturer) {
  return (await api.post<Manufacturer>('/Manufacturer', form)).data;
}

export async function upsertCompany(company: Company) {
  return (await api.post<Company>('/Company', company)).data;
}

export const getCompanies = async () => (await api.get<Company[]>('/company/all')).data;
export const getDealers = async () => (await api.get<Dealer[]>('/dealer')).data;


export async function upsertConnectionKit(payload: ConnectionKit) {
  return (await api.post<ConnectionKit>('/ConnectionKit/Upsert', payload)).data;
}

export async function upsertProtocolAdapter(payload: ProtocolAdapter) {
  return (await api.post<ProtocolAdapter>('/ProtocolAdapter/Upsert', payload)).data;
}
