import {combineReducers} from 'redux';
import {type CommonDispatch} from '../index';
import {type RequiredOrderableDPAssignment} from '../abstract/dataPointAssignment';
import {type DataPointTemplate} from '../abstract/dataPointTemplate';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {type RelationshipType} from '../../util/factory/spRevisionFactory';
import {type SpRevision, spRevisionStore} from './installationEntities/spRevision';
import {type SpRevisionRelationship} from './installationEntities';
import {addSpType, updateSpType} from 'src/api/groupsAndTypesApi';

export interface SPType extends DataPointTemplate<RequiredOrderableDPAssignment> {
  id: number;
  name: string;
  primaryAssetGroupId: number;
  allowableSPTypes: SpTypeAllowableSPType[];
  allowableAssetGroups: SpTypeAllowableAssetGroups[];
}

export function getAllowableAssetGroupIds(type: SPType) {
  return [type.primaryAssetGroupId].concat(type.allowableAssetGroups.map(g => g.assetGroupId));
}

export function getAllowableSystemProcessTypeIds(type: SPType) {
  return [type.id].concat(type.allowableSPTypes.map(g => g.childSPTypeId));
}
export function getAllowableChildren(spType: SPType, type: RelationshipType): number[] {
  return type === 'Asset' ? getAllowableAssetGroupIds(spType) : getAllowableSystemProcessTypeIds(spType);
}

export const getParentSystemProcesses = selector(s => (revision: SpRevision) => {
  const getById = spTypeStore.selectors.getById(s);
  const getSpRevisionById = spRevisionStore.selectors.getById(s);
  const parentRelations: SpRevisionRelationship[] = revision.parents;
  return parentRelations.map(r => getById(getSpRevisionById(r.parentSpRevisionId!).spTypeId));
});

export interface SpTypeAllowableSPType {
  childSPTypeId: number;
}

export interface SpTypeAllowableAssetGroups {
  assetGroupId: number;
}

export const createNewSpType = (spType: SPType) => async (dispatch: CommonDispatch) => {
  const response: SPType = await addSpType(spType);
  dispatch(actions.save(response));
  return response;
};

export const updateExistingSpType = (spType: SPType) => async (dispatch: CommonDispatch) => {
  const response: SPType = await updateSpType(spType);
  dispatch(actions.save(response));
  return response;
};

const actions = createStandardActions(placeholder<SPType>(), 'SP_TYPE/SET', 'SP_TYPE/SAVE');
export type SPTypeActions = GetActions<typeof actions>;
export const spTypes = combineReducers({items: standardItemsReducer<SPType, SPTypeActions>(actions)});
export const spTypeStore = {
  selectors: createStandardSelectors(placeholder<SPType>(), s => getEntities(s).spTypes),
  actions: {
    ...actions
  }
} as const;
