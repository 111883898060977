import React from 'react';
import Row, {type RowProps} from 'react-bootstrap/Row';
import {type BootStrapProps} from '../Bootstrap';

export type BootStrapRowProps = BootStrapProps<RowProps>;
export const BootstrapRow = (props: BootStrapRowProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {noGutters, ...rest} = props;
  return <Row {...rest as any} />;
};
