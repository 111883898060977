import {makeLogger} from 'src/util/logger';

const logger = makeLogger();

export function log(message: any, ...optionalParams: any[]) {
  logMessage('info', message, optionalParams);
}

export function info(message: any, ...optionalParams: any[]) {
  logMessage('info', message, optionalParams);
}

export function logError(message?: any, ...optionalParams: any[]) {
  logMessage('error', message, optionalParams);
}

export function warn(message?: any, ...optionalParams: any[]) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  logMessage('warn', message, ...optionalParams);
}

export function verbose(message?: any, ...optionalParams: any[]) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  logMessage('verbose', message, ...optionalParams);
}

function logMessage(level: string, message: any, ...optionalParams: any[]) {
  if (typeof message === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    logger_log(level, '', ...[message, ...optionalParams]);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  logger_log(level, message, optionalParams);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function logger_log(level: string, message: string, ...meta: any[]) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    console.log(level, message, ...meta);
  } else {
    logger.log(level, message, meta);
  }
}
