import {type LoadableResourceState} from 'src/hooks/useLoadableResource';
import {CenteredSpinner} from './widgets/CenteredSpinner/CenteredSpinner';
import React from 'react';
import {Alert} from './Controls';
import {type UseQueryResult} from '@tanstack/react-query';
import {handleAxiosError} from '../../common/util/http';

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(functionToCheck: any): functionToCheck is () => void {
  return functionToCheck ? {}.toString.call(functionToCheck) === '[object Function]' : false;
}

export function LoadableResourceLayout<T>({state, children, ...p}: {state: LoadableResourceState<T>; globalSpinner?: boolean; children: any}) {
  const globalSpinner = p.globalSpinner === undefined ? false : p.globalSpinner;
  const {errorMessage, loading, alert, resource} = state;
  return errorMessage ? <Alert variant='danger'>{errorMessage}</Alert>
    : loading
    ? <CenteredSpinner global={globalSpinner}/>
    : (
    <>
      {alert && <Alert variant='danger'>{alert}</Alert>}
      {resource !== undefined && (isFunction(children) ? children() : children)}
    </>
  );
}


export function ReactQueryLayout<T>({ state, children, ...p }: { state: UseQueryResult<T, Error>; globalSpinner?: boolean; children: any }) {
  const globalSpinner = p.globalSpinner === undefined ? false : p.globalSpinner;
  const { error, isLoading, data } = state;
  return (
    <>
      {error && <Alert variant="danger">{handleAxiosError(error)}</Alert>}
      {isLoading
        ? <CenteredSpinner global={globalSpinner} minHeight={'50vh'} />
        : (
          <>
            {data !== undefined && (isFunction(children) ? children() : children)}
          </>
        )}
    </>
  );
}
