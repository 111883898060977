import {combineReducers} from 'redux';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {type CommonDispatch} from '../index';
import {upsertSite} from 'src/api/companyConfigApi';
import {groupBy} from '../../util';
import {type IExternalIntId} from 'src/common/redux/abstract/IExternalIntId';
import {type UuidModel} from 'src/common/types/UuidModel';

export interface Site extends IExternalIntId, UuidModel {
  id: number;
  name: string;
  companyId: number;
}
const selectors = createStandardSelectors(placeholder<Site>(), s => getEntities(s).sites);
const actions = createStandardActions(placeholder<Site>(), 'SITE/SET', 'SITE/SAVE');
export type SiteActions = GetActions<typeof actions>;
export const sites = combineReducers({items: standardItemsReducer<Site, SiteActions>(actions)});

export const siteStore = {
  selectors: {
    ...selectors,
    getSitesGroupedByCompanyId: selector(s => (companyId: number) =>
      selectors.getAsArray(s).filter(site => site.companyId === companyId)),
    getSitesGroupedByCompany: selector(s => groupBy(selectors.getAsArray(s), (c: Site) => c.companyId))
  },
  actions: {
    ...actions,
    upsert: (site: Site) => async (dispatch: CommonDispatch) => {
      const response = await upsertSite(site);
      dispatch(actions.save(response));
      return response;
    }
  }
} as const;
