import {type CommonState} from '../index';
import type Action from '../index';
import {combineReducers} from 'redux';
import {convertToDropDownOptions, getObjectValues} from '../../util';

export interface UnitGroupType {
  id: number;
  name: string;
}

export interface UnitGroupTypeItems {
  [key: number]: UnitGroupType;
}

export interface UnitGroupTypeState {
  items: UnitGroupTypeItems;
}

export type SetUnitGroupTypesAction = Action<'SET_UNIT_GROUP_TYPES', UnitGroupTypeItems>;
export const setUnitGroupTypes = (items: UnitGroupTypeItems): SetUnitGroupTypesAction => ({type: 'SET_UNIT_GROUP_TYPES', data: items});

export type UnitGroupTypeActions = SetUnitGroupTypesAction;

function unitGroupTypeItems(state: UnitGroupTypeItems = {}, action: UnitGroupTypeActions) {
  switch (action.type) {
    case 'SET_UNIT_GROUP_TYPES': return action.data;
    default: return state;
  }
}

export const unitGroupTypes = combineReducers<UnitGroupTypeState>({
  items: unitGroupTypeItems
});

export const getUnitGroupTypeItems = (state: CommonState) => state.entities.unitGroupTypes.items;
export const getUnitGroupTypesAsArray = (state: CommonState) => getObjectValues(getUnitGroupTypeItems(state));
export const getUnitGroupTypesAsOptions = (state: CommonState) => convertToDropDownOptions(getUnitGroupTypesAsArray(state));
