import React from 'react';
import s from './GridItem.module.scss';
import {Icon} from '../widgets/Icon/Icon';
import {type AlertColor, type AlertSymbol} from '../../../api/userDashboardApi';
import {type IconProp} from '@fortawesome/fontawesome-svg-core';
import {combine, combineClasses} from '../../../util';
import {Link} from '../../../router';

export type GridItemProps = {
  title: string;
  link: string;
  descriptions?: string[];
  backgroundColor?: AlertColor;
  symbol?: AlertSymbol;
  onRightClick?: () => void;
  indicator?: string;
  opStatusColor?: string | null;
  isAssetCard?: boolean;
  notes: string | null;
};

export function DashboardGridItem({title, link, backgroundColor, symbol, onRightClick, indicator, opStatusColor, descriptions, isAssetCard, notes}: GridItemProps) {
  const icon = symbol ? getFontAwesomeIcon(symbol) : 'check';
  const hasIndicator = opStatusColor || indicator;
  return (
    <div className={combineClasses(backgroundColor ? s[`color-${backgroundColor.toLocaleLowerCase()}`] : s['color-blue'], s['container'], isAssetCard && s['asset-card'])}>
      <Link className={combineClasses(s['left'], descriptions ? s['has-descriptions'] : '')} to={link}>
        <span className={s['title']} title={title}>{title}</span>
        {descriptions && descriptions.map(d => <span key={d} className={s['line']}>{d}</span>)}
        {notes && <span title={notes} className={s['tile-notes']}>{notes}</span>}
      </Link>
      <div className={combine(s['right'], hasIndicator ? s['with-indicator'] : '')} onClick={onRightClick}>
        <Icon icon={icon} className={s['icon']}  customSize={40} />
        {hasIndicator && <div className={combine(s['indicator'], opStatusColor && s[`indicator-${opStatusColor.toLocaleLowerCase()}`])}>
          {indicator}
        </div>}
      </div>
    </div>
  );
}


export function getFontAwesomeIcon(symbol: AlertSymbol): IconProp {
  switch (symbol) {
    case 'Check': return 'check';
    case 'Info': return 'info';
    case 'CircleAlert': return 'exclamation-circle';
    case 'TriangleAlert': return 'exclamation-triangle';
    case 'Bypass': return 'bell-slash';
    case 'NoConnection': return 'wifi-slash';
    default: throw new Error(`${symbol} is not a valid symbol`);
  }
}
