import {combineReducers, type Dispatch} from 'redux';
import {type AssignableTemplateRevision} from './index';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities} from '../../selectors';
import {getAssignableSpTemplateRevisions} from 'src/api/installationModelTemplateApi';

export interface ASpTemplateRevision extends AssignableTemplateRevision {}
const actions = createStandardActions(placeholder<ASpTemplateRevision>(), 'A_SP_TEMPLATE_REVISION/SET', 'A_SP_TEMPLATE_REVISION/SAVE');
export type ASpTemplateRevisionActions = GetActions<typeof actions>;
export const aSpTemplateRevisions = combineReducers({items: standardItemsReducer<ASpTemplateRevision, ASpTemplateRevisionActions>(actions)});
export const aSpTemplateRevisionStore = {
  selectors: createStandardSelectors(placeholder<ASpTemplateRevision>(), s => getEntities(s).assignableTemplateRevisions.spTemplates),
  actions: {
    ...actions,
    load: (templateId: number) => async (dispatch: Dispatch) => {
      const items = await getAssignableSpTemplateRevisions(templateId);
      await dispatch(actions.set(items));
      return items;
    }
  }
} as const;
