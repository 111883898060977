import React, {
  createContext,
  type MutableRefObject,
  type PropsWithChildren,
  useCallback,
  useContext, useEffect, useMemo,
  useRef
} from 'react';
import {getSignedFileUrl} from '../../api/files';

interface SignedImageCache {
  [key: string]: { signedUrl: string; timestamp: Date };
}

const SignedImageCacheContext = createContext<MutableRefObject<SignedImageCache>>({current: {}});

export function SignedImageCacheProvider({children}: PropsWithChildren) {
  const cacheRef = useRef<SignedImageCache>({});
  return <SignedImageCacheContext.Provider value={cacheRef}>
    {children}
  </SignedImageCacheContext.Provider>;
}

const FIVE_MINUTES = 1000 * 60 * 5;

export function useSignedImageCache() {
  const cacheRef = useContext(SignedImageCacheContext);
  return {
    getSignedUrl: useCallback((key: string) => {
      const cache = cacheRef.current;
      const cacheEntry = cache[key];
      if (cacheEntry) {
        const now = new Date();
        const cacheAge = now.getTime() - cacheEntry.timestamp.getTime();
        if (cacheAge < FIVE_MINUTES) {
          return cacheEntry.signedUrl;
        } else {
          delete cache[key];
        }
      }
      return null;
    }, [cacheRef]),
    setSignedUrl: useCallback((key: string, signedUrl: string) => {
      const cache = cacheRef.current;
      const now = new Date();
      cache[key] = {signedUrl, timestamp: now};
    }, [cacheRef]),
    clearCache: useCallback(() => {
      const cache = cacheRef.current;
      for (const key in cache) {
        delete cache[key];
      }
    }, [cacheRef])
  };
}

export function useSignedUrl(name: string) {
  const {setSignedUrl, getSignedUrl} = useSignedImageCache();
  const [src, setSrc] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const url = await getSignedFileUrl(name);
        setSignedUrl(name, url);
        setSrc(url);
      } catch(e) {
        console.error(e);
        setError('Failed to Retrieve Image');
      }

    };
    if(getSignedUrl(name)) {
       setSrc(getSignedUrl(name));
    } else {
        void fetchImage();
    }
  }, [getSignedUrl, name, setSignedUrl]);
  return useMemo(() =>({ src: src ?? getSignedUrl(name), error}), [src, getSignedUrl, name, error]);
}
