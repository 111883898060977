import {immutableSort} from '../util/array';

export interface Revision {
  id: number;
  updatedAt: string;
  status: string;
  title: string;
}

export interface RevisionEntity {
  revisionTitle: string;
  revisionDescription: string;
}
export function makeRevisionEntity(title: string = ''): RevisionEntity {
  return {
    revisionDescription: '',
    revisionTitle: title
  };
}

export interface RevisionStatus {
  status: 'Active' | 'Partially Active' | '';
  id: number;
  updatedAt?: string;
  revisionTitle: string;
}

export function convertToRevisions(statuses: RevisionStatus[] | undefined) {
  const revisions: RevisionStatus[] = statuses || [];
  return immutableSort(revisions.map<Revision>(r => ({
    id: r.id || 0,
    status: r.status,
    updatedAt: r.updatedAt || '',
    title: r.revisionTitle
  })), (r, r1) => r1.id - r.id);
}
