import { persistStore, persistReducer } from 'redux-persist';
// tslint:disable-next-line:no-submodule-imports
import storage from 'redux-persist/lib/storage';
import {type Reducer} from 'redux';
import {info} from '../util/logger';
import type {ToolkitStore} from '@reduxjs/toolkit/dist/configureStore';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['entities']
};

export function persistRootReducer<T extends Reducer>(rootReducer: T): T {
  return persistReducer(persistConfig, rootReducer) as unknown as T;
}

export function persistGlobalStore(store: ToolkitStore, onReady?: () => void) {
  return persistStore(store, {}, () => {
    info('Store Re-hydrated. See state in redux dev-tools');
    if (onReady) {
      onReady();
    }
  });
}
