import {combineReducers} from 'redux';
import {batchAppActions, createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import {type ControllerTypeRevision, controllerTypeRevisionStore} from 'src/common/redux/entities/controllerTypeRevision';
import {type AppDispatch} from 'src/redux/store';
import {convertArrayToDictionary} from 'src/common/util';
import {getControllerType, updateControllerTypeThumbnail, upsertControllerType} from 'src/api/controllerTypeApi';

export interface ControllerType {
  id: number;
  name: string;
  description: string;
  protocolAdapterId: number | null;
  connectionKitId: number;
  baudRate: string;
  manufacturerIds: number[];
  assetGroupIds: number[];
  thumbnailImageFileName: string | null;
  thumbnailImageThumbnailFileName: string | null;
}

export type ControllerTypeSaveResponse = ControllerType & {revisions: ControllerTypeRevision[]};

const actions = createStandardActions(placeholder<ControllerType>(), 'CONTROLLER_TYPE/SET', 'CONTROLLER_TYPE/SAVE');
const selectors = createStandardSelectors(placeholder<ControllerType>(), s => getEntities(s).controllerTypes);
export type ControllerTypeActions = GetActions<typeof actions>;
export const controllerTypes = combineReducers({items: standardItemsReducer<ControllerType, ControllerTypeActions>(actions)});

export const controllerTypeStore = {
  selectors: {...selectors},
  actions: {
    ...actions,
    updateThumbnail: (controllerTypeId: number, fileUuid: string) => async (dispatch: AppDispatch) => {
       const response = await updateControllerTypeThumbnail(controllerTypeId, fileUuid);
      dispatch(actions.save(response));
      return response;
    },
    refresh: (id: number) => async (dispatch: AppDispatch) => {
      const response = await getControllerType(id);
      dispatch(actions.save(response));
      return response;
    },
    upsert: (payload: ControllerType) => async (dispatch: AppDispatch) => {
      const response = await upsertControllerType(payload);
      dispatch(batchAppActions([
        controllerTypeRevisionStore.actions.merge(convertArrayToDictionary(response.revisions, 'id')),
        actions.save(response)
      ]));
      return response;
    }
  }
} as const;
