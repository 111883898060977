import s from './CenteredSpinner.module.scss';
import {Spinner} from 'src/components/util/Controls';
import React from 'react';

type Props = {
  global?: boolean;
  minHeight?: string|number;
};

export function CenteredSpinner(p: Props) {
  const global = p.global === undefined ? true : p.global;
  if(!global) {
    return (
      <div className={s['centered-spinner']} style={{minHeight: p.minHeight}}>
        <Spinner animation='border' role='status' style={{margin: 'auto', display: 'block'}}>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div className={s['global-centered-spinner']}>
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  );
}
