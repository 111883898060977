import {immutableSort} from './array';
import {type Map as ImmutableMap} from 'immutable';
export function getMapKeys<K, T>(map: Map<K, T>) {
  const keys = [];
  for (const key of map.keys()) {
    keys.push(key);
  }
  return keys;
}

export function getMapValues<K, T>(map: Map<K, T> | ImmutableMap<K, T>) {
  const values: T[] = [];
  for (const value of map.values()) {
    values.push(value);
  }
  return values;
}

export function setMap< K, V>(map: ImmutableMap<K, V>, key: K, setter: (lastVal: V | undefined) => V) {
  return map.set(key, setter(map.get(key) ?? undefined));
}



export function getObjectKeys<K extends keyof T, T extends {}>(record: T) {
 return Object.keys(record) as K[];
}

export function copyObject<T, K extends keyof T, D extends T = T>(keys: K[], fromItem: T, destination: D) {
  for (const key of keys) {
    // @ts-ignore
    destination[key] = fromItem[key];
  }
  return destination;
}

export function getMapEntries<K, T>(map: Map<K, T> | ImmutableMap<K, T>) {
  const entries = [];
  for (const key of map.entries()) {
    entries.push(key);
  }
  return entries;
}

export type Readonly<T> = {
  readonly [P in keyof T]: T[P];
};



export const propertyOf = <T>(name: keyof T ) => name;
export const exactPropertyOf = <T>() => <P extends keyof T>(name: P): P => name;

export function immutableRemoveProperty<T>(obj: T, propertyName: keyof T): T {
  const newObj = {...obj};
  delete newObj[propertyName];
  return newObj;
}

export function removePropertyRecursively<T extends (any | any[])>(obj: T, propertyName: string): T {
  if (obj === null || obj === undefined) {
    return obj;
  } else if (Array.isArray(obj)) {
    const newArray = [];
    for (const item of obj) {
      newArray.push(removePropertyRecursively(item, propertyName));
    }
    return newArray as T;
  } else if (typeof obj === 'object') {
    const newObj: Record<string, unknown> = {};
    for (const key in obj) {
      if (key !== propertyName) {
        newObj[key] = removePropertyRecursively(obj[key], propertyName);
      }
    }
    return newObj as T;
  } else {
    return obj;
  }
}

export function setPropertyRecursively<T, V>(obj: T, propertyName: string, value: V): T {
  if (obj === null || obj === undefined) {
    return obj;
  } else if (Array.isArray(obj)) {
    const newArray = [];
    for (const item of obj) {
      newArray.push(setPropertyRecursively(item, propertyName, value));
    }
    return newArray as T;
  } else if (typeof obj === 'object') {
    const newObj: Record<string, unknown> = {};
    // tslint:disable-next-line:forin
    for (const key in obj) {
      if (key !== propertyName) {
        newObj[key] = setPropertyRecursively (obj[key], propertyName, value);
      }
      if(key === propertyName) {
        newObj[key] = value;
      }
    }
    return newObj as T;
  } else {
    return obj;
  }
}

export function cloneObjectWithoutProperties(obj: { [key: string]: any }, ...keysToNotInclude: any[]) {
  const target: { [key: string]: any } = {};
  for (const key in obj) {
    if (keysToNotInclude.indexOf(key) >= 0) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    target[key] = obj[key];
  }
  return target;
}

/**
 * Returns undefined if path is not found, gets the property value via a path string
 * @param obj
 * @param fullPath ex. someArray.0.name, in {someArray: [{name: 'hello'}]}
 */
export function deepFind(obj: object, fullPath: string) {
  const paths = fullPath.split('.');
  let current: Record<string, unknown> = obj as unknown as Record<string, unknown>;
  for (const path of paths) {
    if (current[path] === undefined) {
      return undefined;
    } else {
      current = current[path] as unknown as Record<string, unknown>;
    }
  }
  return current as unknown;
}

/**
 * This function affects performance and should not be called except for debug situations
 * @param obj
 * @param label
 */
export function calculateSize(obj: { [key: string]: any }, label?: string) {
  const keys = Object.keys(obj);
  let output = '';
  output += `Size breakdown of ${label}: ${estimateStringMB(JSON.stringify(obj))} MB\n`;
  const sizes = [];
  for (const key of keys) {
    sizes.push({label: key, size: estimateStringMB(JSON.stringify(obj[key]))});
  }

  immutableSort(sizes, (s1, s2) => s2.size - s1.size)
    .forEach(size => output += `${size.label}:\t\t${size.size.toFixed(4)} MB\n`);
  // tslint:disable-next-line:no-console
  console.log(output);
}

export function estimateStringMB(str: string) {
  return (str.length / 1000 / 1024);
}

export function isShallowEqual(a: object, b: object) {
  for (const key in a) {
    if (!(key in b) || a[key as keyof typeof a] !== b[key as keyof typeof a]) {
      return false;
    }
  }
  for (const key in b) {
    if (!(key in a) || a[key as keyof typeof a] !== b[key as keyof typeof a]) {
      return false;
    }
  }
  return true;
}

// tslint:disable-next-line:ban-types
function loop<T extends object>(iterated: T, callback: (value: any, idx: string, obj?: object) => void, thisArg?: any) {
  let i;

  if (Array.isArray(iterated)) {
    for (i = 0; i < iterated.length; i++) {
      callback.call(thisArg, iterated[i], String(i), iterated);
    }
  } else {
    for (i in iterated) {
      if (iterated.hasOwnProperty(i)) {
        // @ts-ignore
        callback.call(thisArg, iterated[i] , i, iterated);
      }
    }
  }
}

export function shallowDiff<T extends object>(base: T, compared: T) {
  const unchanged: string[] = [],
    updated: string[] = [],
    deleted: string[] = [],
    added: string[] = [];

  // Loop through the compared object
  loop(compared, (value, idx) => {
    // To get the added items
    if (!(idx in base)) {
      added.push(idx);

      // The updated items
    } else if (value !== (base as Record<string, unknown>)[idx]) {
      updated.push(idx);

      // And the unchanged
    } else if (value === (base as Record<string, unknown>)[idx]) {
      unchanged.push(idx);
    }
  });

  // Loop through the before object
  loop(base, (value, idx) => {
    // To get the deleted items
    if (!(idx in compared)) {
      deleted.push(idx);
    }
  });

  return {
    updated: updated,
    unchanged: unchanged,
    added: added,
    deleted: deleted
  };
}

export const scalarArgsResolver = (...args: Array<string|number|undefined|null>) => args.join('_');
