import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {range} from 'src/common/util';
import {type Installation} from 'src/common/redux/entities/installation';
import {type GetById} from 'src/common/redux/selectors';
import {type Site} from 'src/common/redux/entities/site';

export const SerialTransmitter = {
  None: 'none',
  Laird: 'laird',
  Synapse: 'synapse'
} as const;

export const serialTransmitterOptions: DropdownOption[] = [
  {value: SerialTransmitter.None, label: 'None'},
  {value: SerialTransmitter.Laird, label: 'Laird'},
  {value: SerialTransmitter.Synapse, label: 'Synapse'}
];

const maxHours = 6;
export const timeoutMinutesOptions: DropdownOption[] = range(1, 2 * maxHours).map(i => ({value: i * 30, label: String((i / 2) + ' Hour(s)')}));

export const makeGetInstallationOptions = (isGlobal: boolean, installations: Installation[], getSiteById: GetById<Site>): () => DropdownOption[] =>
  () => isGlobal ? [] : installations.map(i => ({value: i.id, label: `${getSiteById(i.siteId).name} - ${i.name}`}));
export const wifiDropDownOptions: DropdownOption[] = range(0, 1).map(i => ({value: i === 0, label: i === 0 ? 'Yes' : 'No'}));
