import {type RmxBox, type RmxBoxPort, type RmxBoxSource, type UnsavedRmxBox} from '../common/types/RmxBox';
import {type Installation} from '../common/redux/entities/installation';
import {type AssetRevision} from '../common/redux/entities/installationEntities/assetRevision';
import {api} from './index';

export async function saveRmxBox(rmxBox: UnsavedRmxBox) {
  return (await api.post<RmxBoxChangeResponse>('RmxBox', rmxBox)).data;
}

export interface RmxBoxChangeResponse {
  rmxBox: RmxBox;
  installation: Installation | null;
  assetRevisions: AssetRevision[];
}

export async function saveRmxBoxPort(rmxBox: RmxBoxPort) {
  return (await api.post<RmxBoxChangeResponse>('RmxBoxRevision/Port', rmxBox)).data;
}

export async function saveRmxBoxSource(source: RmxBoxSource) {
  return (await api.post<RmxBoxChangeResponse>('RmxBoxRevision/Source', source)).data;
}


export async function uploadRmxBoxes(uploadData: FormData) {
  return await api.post('/RmxBox/Upload', uploadData, {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  });
}
