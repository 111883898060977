import {memo, type ReactElement, useEffect} from 'react';
import {bindActionCreators, type Dispatch} from 'redux';
import {type WebState} from 'src/redux/types/WebState';
import {connect} from 'react-redux';
import {userStore} from 'src/common/redux/entities/user';
import LogRocket from 'logrocket';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {children: ReactElement};
const UserEffectComponent = memo(({currentUser, children}: Props) => {
  useEffect(() => {
    if(currentUser) {
      if (import.meta.env.VITE_SESSION_RECORDING_ENABLED.toLowerCase() === 'true') {
        LogRocket.identify(currentUser.email);
      }
    }
  }, [currentUser]);
  return children;
});
UserEffectComponent.displayName = 'UserEffectComponent';

const mapDispatchToProps = (dispatch: Dispatch) => ({ actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({currentUser: userStore.selectors.getCurrentUser(state)});

export default connect(mapStateToProps, mapDispatchToProps)(UserEffectComponent);
