import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {type CommonState} from 'src/common/redux/index';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';

export interface AlertGroup {
  id: number;
  name: string;
  oldName: string;
}

const actions = createStandardActions(placeholder<AlertGroup>(), 'ALERT_GROUP/SET', 'ALERT_GROUP/SAVE');
export type AlertGroupActions = GetActions<typeof actions>;
export const alertGroups = combineReducers({items: standardItemsReducer<AlertGroup, AlertGroupActions>(actions)});
const selectors = createStandardSelectors(placeholder<AlertGroup>(), s => getEntities(s).alertGroups);
export const alertGroupStore = {
  selectors: {
    ...selectors,
    getOptions: (s: CommonState) => selectors.getAsArray(s).map<DropdownOption>(ag => ({value: ag.id, label: getAlertGroupName(ag)}))
  },
  actions: {
    ...actions
  }
} as const;

export function getAlertGroupName(ag: AlertGroup) {
  if (ag.oldName) {
    return `${ag.name}:${ag.oldName}`;
  }
  return ag.name;
}
