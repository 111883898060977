import {combineReducers} from 'redux';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';

export interface Router {
  id: number;
  name: string;
  hasGPS: boolean;
  hasWifi: boolean;
  assetTemplateId: number;
}
const selectors = createStandardSelectors(placeholder<Router>(), s => getEntities(s).routers);
const actions = createStandardActions(placeholder<Router>(), 'ROUTER/SET', 'ROUTER/SAVE');
export type RouterActions = GetActions<typeof actions>;
export const routers = combineReducers({items: standardItemsReducer<Router, RouterActions>(actions)});

export const routerStore = {
  selectors: {
    ...selectors
  },
  actions: {
    ...actions
  }
} as const;
