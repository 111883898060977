import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  value: string;
  setValue?: (e: string) => void;
  readOnly: boolean;
  placeholder?: string;
}

export function GrowingTextArea({ value, setValue, readOnly, placeholder }: Props) {
  const [textAreaRows, setTextAreaRows] = React.useState<number>(1);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const calculateRows = (text: string, textBoxWidth: number) => {
    // average character width
    const estimatedCharacterWidth = 7;
    const charsPerLine = Math.floor(textBoxWidth / estimatedCharacterWidth);

    // Split text into lines considering existing line breaks
    const textLines = text.split('\n');

    const textAreaRows = Math.floor(text.length / charsPerLine);
    return textLines.length + textAreaRows;
  };

  useEffect(() => setTextAreaRows(calculateRows(value, inputRef.current?.clientWidth ?? 1)), [value]);

  return (
    <Form.Control
      placeholder={placeholder}
      ref={inputRef}
      as='textarea'
      rows={textAreaRows}
      value={value}
      onChange={(e) => setValue?.(e.currentTarget.value)}
      readOnly={readOnly}
    />
  );
}
