import {
  batchAppActions,
  createStandardActions,
  type GetActions,
  placeholder,
  standardItemsReducer
} from '../../utils';
import {combineReducers, type Dispatch} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {
  type AlertCodeConversionRevision,
  type AlertConversionRevision,
  type AlertConversionRevisionActions,
  alertConversionRevisionStore,
  type AlertOneToOneConversionRevisionForm
} from './alertConversionRevision';
import {type CommonDispatch, type GetCommonState} from '../../index';
import {type EnumValues} from 'src/util';
import {type AlertDescription} from '../alertDefinition';
import {createAction, createReducer} from 'typesafe-actions';
import {saveAlertConversionForm} from 'src/api/alerts/alertConversionApi';
import {type CommonConversion, type CommonConversionFields} from './index';

export function getAlertConversionTypeLabel(type: AlertConversionType) {
  const paths =  type === 'AlertCodeConversion' ? 'Code' :
    type === 'AlertBitByBitConversion' ? 'Bit By Bit' :
      type === 'AlertOneToOneConversion' ? 'One To One' : null;
  if (paths === null) {
    throw new Error(`Alert Conversion Type: ${type} is not supported`);
  }
  return paths;
}
export function getAlertConversionLabel(type: AlertConversionType) {
  return `Alert ${getAlertConversionTypeLabel(type)} Conversion`;
}

export const EAlertConversionType = {
  Null: 'Null',
  AlertCodeConversion: 'AlertCodeConversion',
  AlertBitByBitConversion: 'AlertBitByBitConversion',
  AlertOneToOneConversion: 'AlertOneToOneConversion'
} as const;
export type AlertConversionType = EnumValues<typeof EAlertConversionType>;

export const isAlertConversion =
  (conversion: CommonConversion): conversion is AlertConversion =>
    Object.values(EAlertConversionType).includes((conversion as AlertConversion).type);

export interface AlertConversion extends CommonConversion {
  id: number;
  type: AlertConversionType;
}

export interface AlertCodeConversion extends AlertConversion {
  type: 'AlertCodeConversion';
}
export interface AlertBitByBitConversion extends AlertConversion {
  type: 'AlertBitByBitConversion';
}
export interface AlertOneToOneConversion extends AlertConversion {
  type: 'AlertOneToOneConversion';
}

export const isAlertCodeConversion = (c: AlertConversion): c is AlertCodeConversion => c.type === 'AlertCodeConversion';
export const isAlertBitByBitConversion = (c: AlertConversion): c is AlertBitByBitConversion => c.type === 'AlertBitByBitConversion';
export const isAlertOneToOneConversion = (c: AlertConversion): c is AlertOneToOneConversion => c.type === 'AlertOneToOneConversion';

export interface AlertConversionWithAssignedStatus extends AlertConversion {
  status: 'Assigned' | 'Not Assigned';
}

export interface AlertConversionFields extends CommonConversionFields {
  type: AlertConversionType;
}

export interface AlertConversionForm {
  conversion: AlertConversionFields;
  revision: AlertConversionRevision;
  descriptionAndResponses: AlertDescription[];
}

export interface AlertCodeConversionForm extends AlertConversionForm {
  revision: AlertCodeConversionRevision;
}

export interface AlertOneToOneConversionForm extends AlertConversionForm {
  revision: AlertOneToOneConversionRevisionForm;
}

export interface AlertOneToOneCheck extends BaseAlertCheck { }

export function getAlertOneToOneCheckKey(c: AlertOneToOneCheck) {
  // ensures no extra properties are present, ex. id, and triggerUuid, doesn't matter the trigger if the definition is the same.
  return `dataPointAssociated: ${c.dataPointAssociated}, ` +
    `associatedDataPointId: ${c.associatedDataPointId}, ` +
    `alertGroupId: ${c.alertGroupId}, ` +
    `alertTypeId: ${c.alertTypeId}, ` +
    `ndpaAlertId: ${c.ndpaAlertId}`;
}

export interface BaseAlertCheck extends ReferencesTrigger {
  dataPointAssociated: boolean;
  associatedDataPointId: number | null;
  alertGroupId: number;
  alertTypeId: number | null;
  ndpaAlertId: number | null;
}

export interface AlertDescriptionAndResponseKeys {
  dataPointAssociated: boolean;
  associatedDataPointId: number | null;
  alertGroupId: number;
  alertTypeId: number | null;
  ndpaAlertId: number | null;
}

export interface ReferencesTrigger {
  triggerUuid: string | null;
}

export const makeReferencesTrigger = (triggerUuid?: string): ReferencesTrigger => ({triggerUuid: triggerUuid ?? null});

export interface AlertCodeCheck extends BaseAlertCheck {
  manufacturerAlertCodeDataPointUuid: string;
  hasTrigger: boolean;
}

export interface AlertCodeCheckGroup {
  manufacturerAlertCode: number;
  alertCodeChecks: AlertCodeCheck[];
}

export interface AlertBitByBitCheckGroup {
  alertBitByBitAssignmentGroupRevisionId: number;
  alertBitByBitAssignmentGroupId: number;
  alertStatusDataPointUuid: string;
}

const cloneActions = {
  clear: createAction('CLEAR_ALERT_CONVERSION_CLONE')(),
  set: createAction('SET_ALERT_CONVERSION_CLONE')<AlertConversionForm>()
};
type CloneActions = GetActions<typeof cloneActions>;
const actions = createStandardActions(placeholder<AlertConversion>(), 'ALERT_CODE_CONVERSION/SET', 'ALERT_CODE_CONVERSION/SAVE');
const selectors = createStandardSelectors(placeholder<AlertConversion>(), s => getEntities(s).alertConversions);
export type AlertConversionActions = GetActions<typeof actions>;
export const alertConversions = combineReducers({
  items: standardItemsReducer<AlertConversion, AlertConversionActions>(actions),
  clone: createReducer<AlertConversionForm|null, CloneActions>(null)
    .handleAction(cloneActions.set, (state, action) => action.payload)
    .handleAction(cloneActions.clear, () => null)
});
export const alertConversionStore = {
  selectors: {
    ...selectors,
    getCodeConversions: selector(s => selectors.getAsArray(s).filter(c => c.type === 'AlertCodeConversion')),
    getBitByBitConversions: selector(s => selectors.getAsArray(s).filter(c => c.type === 'AlertBitByBitConversion')),
    getOneToOneConversions: selector(s => selectors.getAsArray(s).filter(c => c.type === 'AlertOneToOneConversion')),
    getClone: selector(s => selectors.getState(s).clone),
    getLatest: selector( s => (id: number) => alertConversionRevisionStore.selectors.getById(s)(selectors.getById(s)(id).latestRevisionId!))
  },
  actions: {
    ...actions,
    cloneActions,
    upsert: (form: AlertConversionForm) => async (dispatch: CommonDispatch) => {
      const response = await saveAlertConversionForm(form);
      await dispatch(batchAppActions<AlertConversionRevisionActions | AlertConversionActions>([
        alertConversionRevisionStore.actions.save(response.revision),
        alertConversionStore.actions.save(response.template)
      ]));
      return response;
    },
    add: (revisions: AlertConversionRevision[]) =>
      async (dispatch: Dispatch, getState: GetCommonState) => {
        const saveActions = [];
        for (const revision of revisions) {
          const oldAlertConversionTemplate = alertConversionStore.selectors.getById(getState())(revision.alertConversionId!);
          const alertConversion: AlertConversion = {
            ...oldAlertConversionTemplate,
            latestRevisionId: revision.id
          };
          saveActions.push(alertConversionStore.actions.save(alertConversion));
          saveActions.push(alertConversionRevisionStore.actions.save(revision));
        }
        dispatch(batchAppActions(saveActions, 'ADD_ALERT_CONVERSION_TEMPLATE_REVISIONS'));
      }
  }
} as const;
