import {combineReducers} from 'redux';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import {type CommonDispatch} from 'src/common/redux/index';
import {upsertConnectionKit} from 'src/api/globalConfigApi';

export interface ConnectionKit {
  id: number;
  name: string;
  description: string;
}

const actions = createStandardActions(placeholder<ConnectionKit>(), 'CONNECTION_KIT/SET', 'CONNECTION_KIT/SAVE');
const selectors = createStandardSelectors(placeholder<ConnectionKit>(), s => getEntities(s).connectionKits);
export type ConnectionKitActions = GetActions<typeof actions>;
export const connectionKits = combineReducers({items: standardItemsReducer<ConnectionKit, ConnectionKitActions>(actions)});

export const connectionKitStore = {
  selectors: {...selectors},
  actions: {
    ...actions,
  upsert: (payload: ConnectionKit) => async (dispatch: CommonDispatch) => {
      const response = await upsertConnectionKit(payload);
      dispatch(actions.save(response));
      return response;
    }}
} as const;
