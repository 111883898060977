import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {type ValueOf} from '../../types/util';
import {getObjectValues} from '../../util';
import {type CommonDispatch} from '../index';
import {
  deleteUserAlertPreference,
  upsertUserAlertPreference
} from 'src/api/userManagementApi';
import {createAction} from 'typesafe-actions';

export const ENotificationType = {
  Email: 'Email',
  Test: 'Text',
  EmailAndText: 'EmailAndText',
  None: 'None'
} as const;
export type NotificationType = ValueOf<typeof ENotificationType>;
export const NotificationTypes = getObjectValues(ENotificationType);

interface UserAlertNotificationPreferences {
  systemTripAndAlarm: NotificationType;
  systemWarning: NotificationType;
  assetTripAndAlarm: NotificationType;
  assetWarning: NotificationType;
  maintenanceAlert: NotificationType;
}

interface UserAlertSchedule extends UserAlertNotificationPreferences, AlertScheduleWeekDays {
  startTime: string;
  endTime: string;
}

export interface AlertScheduleWeekDays {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export const makeAlertScheduleWeekDays = (): AlertScheduleWeekDays => ({
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true
});

export const daysOfWeek: Array<keyof AlertScheduleWeekDays> = Object.keys(makeAlertScheduleWeekDays()) as Array<keyof AlertScheduleWeekDays>;

export interface UserAlertPreference extends UserAlertSchedule {
  id: number;
  userId: number;
  siteId: number;
  siteName?: string;
}

export const deleteAction = createAction('USER_ALERT_PREFERENCE/DELETE')<number>();
const actions = createStandardActions(placeholder<UserAlertPreference>(), 'USER_ALERT_PREFERENCE/SET', 'USER_ALERT_PREFERENCE/SAVE');
const selectors = createStandardSelectors(placeholder<UserAlertPreference>(), s => getEntities(s).userAlertPreferences);
export type UserAlertPreferenceActions = GetActions<typeof actions> | ReturnType<typeof deleteAction>;

export const userAlertPreferences = combineReducers({
  items: standardItemsReducer<UserAlertPreference, UserAlertPreferenceActions>(actions)
    .handleAction(deleteAction, (state, action) => {
    const newState = {...state};
    delete newState[action.payload];
    return newState;
  })
});
export const userAlertPreferenceStore = {
  selectors: {
    ...selectors
  },
  actions: {
    ...actions,
    upsert: (form: UserAlertPreference) => async (dispatch: CommonDispatch) => {
      const response = await upsertUserAlertPreference(form);
      dispatch(userAlertPreferenceStore.actions.save(response));
      return response;
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await deleteUserAlertPreference(id);
      dispatch(deleteAction(response.id));
    }
  }
} as const;
