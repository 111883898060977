import {type CommonState} from '../index';
import type Action from '../index';
import {combineReducers} from 'redux';
import {convertToDropDownOptions, getObjectValues} from '../../util';

export interface InterpolationGraph {
    id: number;
    name: string;
}

export interface InterpolationGraphItems {
    [key: number]: InterpolationGraph;
}

export interface InterpolationGraphState {
    items: InterpolationGraphItems;
}

export type SetInterpolationGraphsAction = Action<'SET_INTERPOLATION_GRAPHS', InterpolationGraphItems>;
export const setInterpolationGraphs = (items: InterpolationGraphItems): SetInterpolationGraphsAction => ({type: 'SET_INTERPOLATION_GRAPHS', data: items});

export type InterpolationGraphActions = SetInterpolationGraphsAction;

function interpolationGraphItems(state: InterpolationGraphItems = {}, action: InterpolationGraphActions) {
    switch (action.type) {
        case 'SET_INTERPOLATION_GRAPHS':
            return action.data;
        default:
            return state;
    }
}

export const interpolationGraphs = combineReducers<InterpolationGraphState>({
    items: interpolationGraphItems
});

export const getInterpolationGraphItems = (state: CommonState) => state.entities.interpolationGraphs.items;
export const getInterpolationGraphsArray = (state: CommonState): InterpolationGraph[] => getObjectValues(getInterpolationGraphItems(state));
export const getInterpolationGraphOptions = (state: CommonState) => convertToDropDownOptions(getInterpolationGraphsArray(state));
