import {combineReducers} from 'redux';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import {type CommonDispatch} from 'src/common/redux/index';
import {groupBy, type IdDictionary} from 'src/common/util';
import {createAction} from 'typesafe-actions';
import {createSelector} from '@reduxjs/toolkit';
import {upsertControllerTypeRevision} from 'src/api/controllerTypeApi';

export interface ControllerTypeRevision {
  id: number;
  name: string;
  controllerTypeId: number;
}
export const merge = createAction('CONTROLLER_TYPE_REVISION/MERGE')<IdDictionary<ControllerTypeRevision>>();
const actions = createStandardActions(placeholder<ControllerTypeRevision>(), 'CONTROLLER_TYPE_REVISION/SET', 'CONTROLLER_TYPE_REVISION/SAVE');
const selectors = createStandardSelectors(placeholder<ControllerTypeRevision>(), s => getEntities(s).controllerTypeRevisions);
export type ControllerTypeRevisionActions = GetActions<typeof actions>  | ReturnType<typeof merge>;
export const controllerTypeRevisions = combineReducers({
  items: standardItemsReducer<ControllerTypeRevision, ControllerTypeRevisionActions>(actions)
  .handleAction(merge, (state, action) => ({...state, ...action.payload}))
});

export const controllerTypeRevisionStore = {
  selectors: {
    ...selectors,
    groupedByControllerTypeId: createSelector(selectors.getAsArray, arr => groupBy(arr, i => i.controllerTypeId))
  },
  actions: {
    ...actions,
    merge: merge,
    upsert: (payload: ControllerTypeRevision) => async (dispatch: CommonDispatch) => {
      const response = await upsertControllerTypeRevision(payload);
      dispatch(actions.save(response));
      return response;
    }
  }
} as const;
