import {combineReducers} from 'redux';
import {type CommonDispatch} from '../index';
import {type RequiredOrderableDPAssignment} from '../abstract/dataPointAssignment';
import {type DataPointTemplate} from '../abstract/dataPointTemplate';
import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities} from '../selectors';
import {createSelector} from '@reduxjs/toolkit';
import memoize from 'lodash.memoize';
import {addAssetGroup, updateAssetGroup} from 'src/api/groupsAndTypesApi';
export interface AssetGroup extends DataPointTemplate<RequiredOrderableDPAssignment> {
  id: number;
  assetTypeIds: number[];
  name: string;
}

export const createNewAssetGroup = (assetGroup: AssetGroup) => async (dispatch: CommonDispatch) => {
  const response: AssetGroup = await addAssetGroup(assetGroup);
  dispatch(actions.save(response));
  return response;
};

export const updateExistingAssetGroup = (assetGroup: AssetGroup) => async (dispatch: CommonDispatch) => {
  const response: AssetGroup = await updateAssetGroup(assetGroup);
  dispatch(actions.save(response));
  return response;
};

const actions = createStandardActions(placeholder<AssetGroup>(), 'ASSET_GROUP/SET', 'ASSET_GROUP/SAVE');
export type AssetGroupActions = GetActions<typeof actions>;
export const assetGroups = combineReducers({items: standardItemsReducer<AssetGroup, AssetGroupActions>(actions)});
export const assetGroupStore = {
  selectors: createStandardSelectors(placeholder<AssetGroup>(), s => getEntities(s).assetGroups),
  actions: {
    ...actions
  }
} as const;

export const getAssetGroupItems = assetGroupStore.selectors.getItems;
export const getAssetGroups = assetGroupStore.selectors.getAsArray;
export const getAssetGroupsAsOptions = assetGroupStore.selectors.getOptions;
// export const getAssetGroupById = assetGroupStore.selectors.getById;

// example of reselect
export const getAssetGroupById = createSelector(
  assetGroupStore.selectors.getItems,
  items => memoize((id: number) => items[id]));
