// Define the nested modal
import { ModalHeader, Modal, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import React from 'react';
import s from './ActionModal.module.scss';

interface Props<TResource> {
  open: boolean;
  setIsOpen: (open: boolean) => void;
  actionConfig: ActionConfig<any>;
  resource: TResource;
}

export function ActionModal({ open, setIsOpen, actionConfig, resource }: Props<any>) {
  const handleAction = (action: keyof ActionConfig<any>) => {
    const actionHandler = actionConfig[action];
    if (actionHandler) {
      actionHandler(resource);
    }
    setIsOpen(false);
  };

  return (
    <Modal className={s['modal']} dialogClassName={s['modal-dialog']} contentClassName={s['modal-content']} show={open} centered={true}>
      <ModalHeader style={{ justifyContent: 'center', height: 'auto', color: '#FFFFFF', borderBottom: '1px solid #000000', backgroundColor: '#262626' }}>
        Action Menu
      </ModalHeader>
      <ModalBody style={{ display: 'flex', flexDirection: 'column', gap: '10px', color: '#FFFFFF', backgroundColor: '#262626' }}>
        {actionConfig['view_requests'] && (
          <Button variant={'info'} className={s['button']} onClick={() => handleAction('view_requests')}>
            List Requests
          </Button>
        )}
        {actionConfig['submit_requests'] && (
          <Button variant={'info'} className={s['button']} onClick={() => handleAction('submit_requests')}>
            Submit Request
          </Button>
        )}
        {actionConfig['change'] && (
          <Button variant={'info'} className={s['button']} onClick={() => handleAction('change')}>
            Change
          </Button>
        )}
        {actionConfig['snooze'] && (
          <Button variant={'info'} className={s['button']} onClick={() => handleAction('snooze')}>
            Snooze
          </Button>
        )}
        {/*{actionConfig['bypass'] && <Button variant={'info'} className={s['button']} onClick={() => handleAction('bypass')}>Bypass</Button>}*/}
        {actionConfig['bypass_add'] && (
          <Button variant={'info'} className={s['button']} onClick={() => handleAction('bypass_add')}>
            Add Bypass
          </Button>
        )}
        {actionConfig['bypass_clear'] && (
          <Button variant={'info'} className={s['button']} onClick={() => handleAction('bypass_clear')}>
            Clear Bypass
          </Button>
        )}
        {actionConfig['edit'] && (
          <Button variant={'info'} className={s['button']} onClick={() => handleAction('edit')}>
            Edit Notes
          </Button>
        )}
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FFFFFF',
          borderTop: '1px solid #000000',
          backgroundColor: '#262626'
        }}
      >
        <Button variant='secondary' onClick={() => setIsOpen(false)}>
          CANCEL
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export type ActionHandler<TResource> = (resource: TResource) => void;

export type ActionConfig<TResource> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  view_requests?: ActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  submit_requests?: ActionHandler<TResource>;

  change?: ActionHandler<TResource>;
  snooze?: ActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  bypass_add?: ActionHandler<TResource>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  bypass_clear?: ActionHandler<TResource>;
  edit?: ActionHandler<TResource>;
};
