import {type CommonState, type GetCommonState} from '../index';
import type Action from '../index';
import {combineReducers, type Dispatch} from 'redux';
import {convertToDropDownOptions, getObjectValues} from '../../util';
import {getUnits} from 'src/api/globalConfigApi';

export interface Unit {
  id: number;
  name: string;
  unitGroupId: number;
}

export interface UnitItems {
  [key: number]: Unit;
}

export interface UnitState {
  items: UnitItems;
}

export type SetUnitsAction = Action<'SET_UNITS', UnitItems>;
export const setUnits = (items: UnitItems): SetUnitsAction => ({type: 'SET_UNITS', data: items});

export type UnitActions = SetUnitsAction;

function unitItems(state: UnitItems = {}, action: UnitActions) {
  switch (action.type) {
    case 'SET_UNITS': return action.data;
    default: return state;
  }
}

export const units = combineReducers<UnitState>({
  items: unitItems
});

export const getUnitItems = (state: CommonState) => state.entities.units.items;
export const getUnitsAsArray = (state: CommonState) => getObjectValues(getUnitItems(state));
export const getUnitsAsOptions = (state: CommonState) => convertToDropDownOptions(getUnitsAsArray(state));
export const getUnitsAsOptionsByUnitGroupId = (state: CommonState) => (unitGroupId: number) =>
  convertToDropDownOptions(getUnitsAsArray(state).filter(u => u.unitGroupId === unitGroupId));

export async function loadUnitsIfNotLoaded(dispatch: Dispatch, getState: GetCommonState) {
  if (Object.keys(getUnitItems(getState())).length !== 0) {
    return;
  }
  dispatch(setUnits(await getUnits()));
}
