import {isNullOrWhitespace} from '../util/string';
import {filterHidden} from 'src/common/types/Hidable';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {type EnumValues} from 'src/util';
import {getEnumValues} from 'src/common/util';

export interface RmxBox extends UnsavedRmxBox {
  latestRevision: RmxBoxRevision;
  latestRevisionId: number;
  rmxBoxRevisions: RmxBoxRevision[];
}
export const ERmxBoxProgram = {
  RMX_PLC: 'RMX_PLC',
  ERL: 'ERL'
} as const;
export type RmxBoxProgram = EnumValues<typeof ERmxBoxProgram>;
export const RmxBoxProgramOptions = getEnumValues(ERmxBoxProgram).map<DropdownOption<RmxBoxProgram>>(o => ({value: o, label: o}));

export interface UnsavedRmxBox {
  id: number;
  name: string;
  serialNumber: string;
  ipAddress: string | null;
  macAddress: string;
  rmxBoxTypeId: number;
  serialTransmitter: string;
  companyId: number | null;
  installationId: number | null;
  wifi: boolean;
  timeoutMinutes: number;
  routerId: number | null;
  phoneNumber: string | null;
  simICCID: string | null;
  routerIMEI: string | null;
  systemID: number | null;
  channel: number | null;
  carrierId: number | null;
  countryId: number | null;
  program: RmxBoxProgram;
}

export const normalizeIpAddress = (ipAddress: string | null) =>
  isNullOrWhitespace(ipAddress) ? null : (ipAddress ?? '')
    .split('.').map(i => isNullOrWhitespace(i) ? '' : String(parseInt(i, 10))).join('.');

export interface RmxBoxRevision {
  id: number;
  rmxBoxId: number;
  rmxBoxPorts: RmxBoxPort[];
  installationRevisionId: number;
}

export function getBoxRevPorts(r?: RmxBoxRevision | null) {
  return (r?.rmxBoxPorts || []);
}

export function getBoxLatestPorts(b?: RmxBox | null) {
  return getBoxRevPorts(b?.latestRevision);
}
export function getBoxLatestSources(b?: RmxBox | null, showHidden?: boolean) {
  return getBoxRevSources(b?.latestRevision, showHidden);
}

export function getBoxRevSources(r?: RmxBoxRevision | null, showHidden?: boolean) {
  return getBoxRevPorts(r).flatMap(p => getPortSources(p, showHidden));
}

export interface RmxBoxPort extends UnsavedRmxBoxPort {
  id: number;
  rmxBoxPortType: RmxBoxPortType;
  rmxBoxSources: RmxBoxSource[];
}

export function getPortDropDownOption(p?: RmxBoxPort): Array<DropdownOption<number>> {
  return p ? [{value: p.id, label: p.rmxBoxPortType.name}] : [];
}

export function getPortSources(p?: RmxBoxPort | null, showHidden?: boolean) {
  return filterRmxBoxSources(p?.rmxBoxSources ?? [], showHidden);
}

export interface UnsavedRmxBoxPort {
  baudRate: string;
  stopBits: number;
  dataBits: number;
  parity: Parity;
}

export type Parity = 'None' | 'Even' | 'Odd';

export interface RmxBoxPortType {
  name: string;
  type: ProtocolType;
}

export type ProtocolType = 'SerialTcp' | 'Serial';

export interface RmxBoxSource extends UnsavedRmxBoxSource {
  id: number;
}

export interface UnsavedRmxBoxSource {
  name: string;
  pollingRateSeconds: number;
  parentSourceId: number | null;
  registerOffset: number;
  rmxBoxPortId: number;
  node: number;
  maxRegisters: number;
  maxBits: number;
  pollRetries: number;
  firstConnectTimeoutSeconds: number;
  ipAddress?: string;
  portNumber?: number;
  sourceTimeoutMinutes: number;
  isRmxBox: boolean;
  isHidden: boolean;
}

export const filterRmxBoxSources = (sArr: RmxBoxSource[], showHidden?: boolean) => filterHidden(sArr, showHidden);
