import {combineReducers} from 'redux';
import rootReducer from '../reducers';
import {initBrowser} from '../actions/browser';
import {persistGlobalStore, persistRootReducer} from 'src/common/redux/store';
import {commonReducers} from 'src/common/redux';
import {enableBatching} from 'redux-batched-actions';
import {configureStore} from '@reduxjs/toolkit';

const configureMainStore = () => {

  const baseReducer = combineReducers({
    ...commonReducers,
    ...rootReducer()
  });
  const root = persistRootReducer(baseReducer);
  const storeInstance = configureStore({
    reducer: enableBatching(root) as typeof root,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false, // required to get redux persist to work
      immutableCheck: { warnAfter: 200 }
    }),
    devTools: true
  });
  const persistorInstance = persistGlobalStore(storeInstance);
  storeInstance.dispatch(initBrowser());

  return { store: storeInstance, persistor: persistorInstance};
};

export const {store, persistor} = configureMainStore();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
