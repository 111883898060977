import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {type CommonDispatch} from '../index';
import {type EnumValues} from 'src/util';
import {makeAlertDescription} from '../../util/factory/alertFactory';
import {createSelector} from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import {scalarArgsResolver} from '../../util/object';
import {getAlertDefinition, saveAlertDefinition} from 'src/api/alerts';
import {distinct} from 'src/common/util';
import {produce} from 'immer';

export interface AlertDefinition {
  id: number;
  type: AlertDefinitionType;
  alertDescriptions: AlertDescription[];
}

export interface AlertDescription {
  id?: number;
  alertTypeId: number | null;
  alertId: number | null;
  description: string;
  response: string;
  groups: AlertDescriptionGroupRelationship[];
}

export function getUsedAlertDescriptionGroupIds(description: AlertDescription|undefined, dataPointAssociated: boolean, descriptions: AlertDescription[]) {
  if(description === undefined) {
    return [];
  }
  return (dataPointAssociated ? descriptions.filter(d => d.alertTypeId === description.alertTypeId) : descriptions)
    .flatMap(d => d.groups)
    .map(g => g.alertGroupId)
    .filter(distinct);
}

export interface ReferencesAlertGroup {
  alertGroupId: number;
}

export interface AlertDescriptionGroupRelationship extends ReferencesAlertGroup {
  alertDescriptionId: number;
}

export const EAlertDefinitionType = {
  Null: 'Null',
  DataPointAssociated: 'DataPointAssociated',
  NonDataPointAssociated: 'NonDataPointAssociated'
} as const;

export type AlertDefinitionType = EnumValues<typeof EAlertDefinitionType>;

export interface DpaAlertDefinition extends AlertDefinition {
  assetGroupId: number|null;
  dataPointId: number;
  type: 'DataPointAssociated';
}

export function makeDpaAlertDefinition(assetGroupId: number|null, dpId: number, description?: AlertDescription): DpaAlertDefinition {
  return {
      alertDescriptions: description ? [description] : [],
      type: 'DataPointAssociated',
      dataPointId: dpId,
      assetGroupId: assetGroupId,
      id: 0
    };
}

export function makeNdpaAlertDefinition(descriptionAndResponse: AlertDescription): NdpaAlertDefinition {
  return {
    description: '',
    alertDescriptions: descriptionAndResponse ? [descriptionAndResponse] : descriptionAndResponse,
    type: 'NonDataPointAssociated',
    id: 0
  };
}

export function addDescriptionAndResponse<T extends AlertDefinition>(def: T, description: AlertDescription) {
  return produce(def, draft => {draft.alertDescriptions.push(description);});
}

export interface NdpaAlertDefinition extends AlertDefinition {
  description: string;
  type: 'NonDataPointAssociated';
}

const selectors = createStandardSelectors(placeholder<AlertDefinition>(), s => getEntities(s).alertDefinitions);

const getNdpaAlertDescription = (alert: NdpaAlertDefinition | undefined, alertGroupId: number) =>
  alert ? alert.alertDescriptions.find(d => d.groups.find(g => g.alertGroupId === alertGroupId)) : undefined;

const getDpaAlertDescription = (alert: DpaAlertDefinition | undefined, alertGroupId: number, alertTypeId: number) =>
  alert ? alert.alertDescriptions.find(a => a.alertTypeId === alertTypeId && a.groups.find(g => g.alertGroupId === alertGroupId)) : undefined;

const getDpaAlerts = createSelector(selectors.getAsArray, arr => arr.filter(a => a.type === 'DataPointAssociated') as DpaAlertDefinition[]);
const getNdpaAlerts = createSelector(selectors.getAsArray, arr => arr.filter(a => a.type === 'NonDataPointAssociated') as NdpaAlertDefinition[]);

const getDpaAlertByAssetGroupAndDataPointId = createSelector(
  getDpaAlerts,
  dpaAlerts => memoize((dataPointId: number) =>
    dpaAlerts.find(a => a.dataPointId === dataPointId) as DpaAlertDefinition)
);

const getDpaAlertByDataPointId = createSelector(
  getDpaAlerts,
  dpaAlerts => memoize((dataPointId: number) => dpaAlerts.find(a => a.dataPointId === dataPointId) as DpaAlertDefinition)
);

const getNdpaAlertByAlertId = createSelector(
  getNdpaAlerts,
  ndpaAlerts => memoize((alertId: number) => ndpaAlerts.find(a => a.id === alertId) as NdpaAlertDefinition)
);

const getNDAAlertDescriptionByAttributes = createSelector(
  getNdpaAlertByAlertId,
    getAlert => memoize((alertId: number, alertGroupId: number) => getNdpaAlertDescription(getAlert(alertId), alertGroupId), scalarArgsResolver));

const getDPAAlertDescriptionByAttributes = createSelector(
  getDpaAlertByDataPointId,
  getAlert => memoize((dataPointId: number, alertGroupId: number, alertTypeId: number) =>
    getDpaAlertDescription(getAlert(dataPointId), alertGroupId, alertTypeId) as AlertDescription | undefined, scalarArgsResolver));

const actions = createStandardActions(placeholder<AlertDefinition>(), 'ALERT_DEF/SET', 'ALERT_DEF/SAVE');
export type AlertDefinitionActions = GetActions<typeof actions>;
export const alertDefinitions = combineReducers({items: standardItemsReducer<AlertDefinition, AlertDefinitionActions>(actions)});

export const alertDefinitionStore = {
  selectors: {
    ...selectors,
    getDpaAlerts: getDpaAlerts,
    getNdpaAlerts: getNdpaAlerts,
    getDpaAlertDefinition: getDpaAlertByAssetGroupAndDataPointId,
    getAlertDescriptionByAlertIdAndAlertGroupId: getNDAAlertDescriptionByAttributes,
    getAlertDescriptionByAlertIdAndAlertGroupIdAndAlertTypeId: getDPAAlertDescriptionByAttributes,
    getOrMakeAlertDescription: createSelector(
      getNDAAlertDescriptionByAttributes,
      getDPAAlertDescriptionByAttributes,
      (getNdpaAlertDesc, getDpaAlertDesc) =>
        memoize((alertGroupId: number, alertTypeId: number | null, associatedDataPointId: number | null, ndpaAlertId: number | null) => {
      if (ndpaAlertId) {
        return getNdpaAlertDesc(ndpaAlertId!, alertGroupId) || makeAlertDescription();
      } else if (associatedDataPointId) {
        return getDpaAlertDesc(associatedDataPointId!, alertGroupId, alertTypeId!) || makeAlertDescription();
      }
      return undefined;
    }, scalarArgsResolver))
  },
  actions: {
    ...actions,
    upsert: (alert: AlertDefinition) => async (dispatch: CommonDispatch) => {
      const response: AlertDefinition = await saveAlertDefinition(alert);
      dispatch(actions.save(response));
      return response;
    },
    load: (id: number) => async (dispatch: CommonDispatch) => {
      const response = await getAlertDefinition(id);
      dispatch(actions.save(response));
      return response;
    }
  }
} as const;
