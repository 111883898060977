import {
  batchAppActions,
  createStandardActions,
  type GetActions,
  placeholder,
  standardItemsReducer
} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {type CommonDispatch} from '../index';
import {convertArrayToDropDownOptions} from '../../util';
import {
  type AlertBitByBitAssignmentGroupRevision, type AlertBitByBitAssignmentGroupRevisionActions,
  alertBitByBitAssignmentGroupRevisionStore,
  bitNumber,
  type BitNumber
} from './alertBitByBitAssignmentGroupRevisions';
import {saveAlertBitByBitAssignmentGroup} from 'src/api/alerts/alertBitByBitAssignmentGroupApi';
import {type IReferencesManufacturerIds} from 'src/common/redux/abstract/IReferencesManufacturerIds';

export interface AlertBitByBitAssignmentGroup extends AlertBitByBitAssignmentGroupFields {
  id: number;
  latestRevisionId: number;
}

export interface AlertBitByBitAssignmentGroupFields extends IReferencesManufacturerIds {
  name: string;
}

export interface AlertBitByBitAssignmentGroupForm {
  template: AlertBitByBitAssignmentGroupFields;
  revision: AlertBitByBitAssignmentGroupRevision;
}

const actions =
  createStandardActions(placeholder<AlertBitByBitAssignmentGroup>(), 'ALERT_BIT_BY_BIT_ASSIGNMENT_GROUP/SET', 'ALERT_BIT_BY_BIT_ASSIGNMENT_GROUP/SAVE');
export type AlertBitByBitAssignmentGroupActions = GetActions<typeof actions>;
const selectors = createStandardSelectors(placeholder<AlertBitByBitAssignmentGroup>(), s => getEntities(s).alertBitByBitAssignmentGroups);
export const alertBitByBitAssignmentGroups =
  combineReducers({items: standardItemsReducer<AlertBitByBitAssignmentGroup, AlertBitByBitAssignmentGroupActions>(actions)});
export const alertBitByBitAssignmentGroupStore = {
  selectors: {
    ...selectors,
    getManyByManufacturer: selector(s => (manufacturerId: number) => selectors.getAsArray(s).filter(a => a.manufacturerIds.includes(manufacturerId))),
    getBitNumberDropDownOptions: selector(_ => convertArrayToDropDownOptions<BitNumber>(Object.values(bitNumber))),
    getLatestRevision: selector(s =>
      (id: number) => alertBitByBitAssignmentGroupRevisionStore.selectors.getById(s)(selectors.getById(s)(id)?.latestRevisionId) as AlertBitByBitAssignmentGroupRevision|undefined)
  },
  actions: {
    ...actions,
    upsert: (form: AlertBitByBitAssignmentGroupForm) => async (dispatch: CommonDispatch) => {
      const response = await saveAlertBitByBitAssignmentGroup(form);
      await dispatch(batchAppActions<AlertBitByBitAssignmentGroupActions | AlertBitByBitAssignmentGroupRevisionActions>([
        alertBitByBitAssignmentGroupRevisionStore.actions.save(response.revision),
        alertBitByBitAssignmentGroupStore.actions.save(response.template)
      ]));
      return response;
    }
  }
} as const;
