
export interface IExternalIntId {
  externalIntId: number | null;
}

export function makeExternalIntIdObj(): IExternalIntId {
  return {
    externalIntId: null
  };
}
