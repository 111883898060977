import {notRequiredString, object, requiredString} from 'src/common/util/yup';

export const EnvironmentSchema = object<any>({
  // NODE_ENV: stringOneOf<typeof process.env.NODE_ENV>(toMutable(Environments)),
  VITE_API_URL: requiredString(),
  // PUBLIC_URL: baseString(),
  VITE_DARK_MODE: notRequiredString(),
  VITE_HOST_URL: requiredString(),
  VITE_SESSION_RECORDING_ENABLED: requiredString()
});
