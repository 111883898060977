import {type Dispatch} from 'redux';
import {calculateSize} from '../util/object';
import {type User, userStore} from './entities/user';
import {getUserManagementState} from 'src/api/userManagementApi';
import {type IdDictionary} from '../util';
import {batchActions} from 'redux-batched-actions';

import {type Role, roleStore} from './entities/role';

export interface UserManagementResponse {
  users: IdDictionary<User>;
  roles: IdDictionary<Role>;
}

export const loadUserManagementData = () => async (dispatch: Dispatch) => {
  const response: UserManagementResponse = await getUserManagementState();
  if (import.meta.env.DEV) {
    calculateSize(response, 'User Management Response');
  }
  await dispatch(batchActions([
    dispatch(userStore.actions.set(response.users)),
    dispatch(roleStore.actions.set(response.roles))
  ], 'BATCH_SET_USER_MANAGEMENT_STATE'));
};
