import {combineReducers} from 'redux';
import {type AssetTemplateFormFields} from '../assetTemplate';
import {type DPTemplateAssignmentTemplate, type DataPointTemplate, getDPAssignments} from '../../abstract/dataPointTemplate';
import {
  createStandardActions,
  type GetActions,
  placeholder,
  standardItemsReducer
} from '../../utils';
import {createStandardSelectors, getEntities} from '../../selectors';
import {type RevisionEntity} from '../../../types/Revision';
import {
  type AlertBitByBitConversionRevision,
  type AlertCodeConversionRevision
} from '../conversions/alertConversionRevision';
import {type EnumValues} from 'src/util';
import {convertToDropDownOptions, type IdDictionary} from '../../../util';
import {type DataPointType, getDataPointById} from '../dataPoint';
import {getAssetGroupById} from '../assetGroup';
import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import {scalarArgsResolver} from '../../../util/object';
import {createAction} from 'typesafe-actions';

export type AssetTemplateRevision = DPTemplateAssignmentTemplate & RevisionEntity & {
  id: number;
  consecutiveRegisterPollingOnly: boolean;
  assetTemplateId: number | null;
  osConversionAssignments: ConversionAssignment[];
  alertConversionAssignments: ConversionAssignment[];
  parentRevisionId: number | null;
  assetModuleName: string | null;
  modules: AssetTemplateRevision[];
  updatedAt?: string;
};
type PropOf<T extends {} , P extends keyof T> = P;

export type DataPointAssignmentKeys =
  keyof DataPointTemplate<any> |
  PropOf<AlertCodeConversionRevision, 'triggers'> |
  PropOf<AlertCodeConversionRevision, 'manufacturerAlertCodeRegisters'> |
  PropOf<AlertBitByBitConversionRevision, 'alertStatusAssignments'>;

export interface AssetTemplateForm {
  template: AssetTemplateFormFields;
  revision: AssetTemplateRevision;
}

export const EConversionAssignmentType = {
  OS: 'OS',
  Alert: 'Alert'
} as const;

export type ConversionAssignmentType = EnumValues<typeof EConversionAssignmentType>;

export interface ConversionAssignment {
  id: number;
  type: ConversionAssignmentType;
  conversionId: number;
  conversionRevisionId: number;
  templateSourceAssignments: TemplateSourceAssigment[];
}

export interface OSConversionAssignment extends ConversionAssignment {
  type: 'OS';
}

export interface AlertConversionAssignment extends ConversionAssignment {
  type: 'Alert';
}

export interface TemplateSourceAssigment {
  id: number;
  conversionTemplateSourceUUid: string;
  conversionTemplateSourceId: number;
  assetTemplateTemplateSourceUuid: string;
  assetTemplateTemplateSourceId: number;
}
export const mergeAssetTemplateRevisions = createAction('ASSET_TEMPLATE_REVISION/MERGE')<IdDictionary<AssetTemplateRevision>>();
const actions = createStandardActions(placeholder<AssetTemplateRevision>(), 'ASSET_TEMPLATE_REVISIONS/SET', 'ASSET_TEMPLATE_REVISIONS/SAVE');
export type AssetTemplateRevisionActions = GetActions<typeof actions> | ReturnType<typeof mergeAssetTemplateRevisions>;
export const assetTemplateRevisions = combineReducers({
  items: standardItemsReducer<AssetTemplateRevision, AssetTemplateRevisionActions>(actions)
    .handleAction(mergeAssetTemplateRevisions, (state, action) => ({...state, ...action.payload}))
});
export const assetTemplateRevisionStore = {
  selectors: {
    ...createStandardSelectors(placeholder<AssetTemplateRevision>(), s => getEntities(s).assetTemplateRevisions),
    getDataPointOptions: createSelector(
      getAssetGroupById, getDataPointById,
      (getAssetGroup, getDataPoint) => memoize((assetGroupId: number, type: DataPointType) => {
        if (!assetGroupId) { return []; }
        const assetGroup = getAssetGroup(assetGroupId);
        return convertToDropDownOptions(getDPAssignments(assetGroup, type).map(a => getDataPoint(a.dataPointId)));
      }, scalarArgsResolver)
    )
  },
  actions: {
    ...actions,
    merge: mergeAssetTemplateRevisions
  }
} as const;
