import {type CommonState} from './index';
import {convertToDropDownOptions, type IdDictionary} from '../util';
import {type Selector} from 'react-redux';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {createSelector} from '@reduxjs/toolkit';
import memoize from 'lodash.memoize';

export const getEntities = (state: CommonState) => state.entities;
export const getInstallationEntities = (state: CommonState) => getEntities(state).installationEntities;

export const selector = <T>(func: (state: CommonState) => T) => func;

export type StandardState<T> = {
  items: IdDictionary<T>;
};

export function createStandardSelectors<T, S extends StandardState<T>>(i: T, getStateParam: (s: CommonState) => S, throwIfNotFound: boolean = false): StandardSelectors<T, S> {
  const getState = createSelector(getStateParam, s => s);
  const getItems = createSelector(getState, s => s.items);
  const getAsArray = createSelector(getItems, items => Object.values<T>(items));
  const getOptions = createSelector(getAsArray, arr => convertToDropDownOptions(arr));
  const getById = createSelector(getItems, items => memoize((id: number, throwIfNotFoundParam?: boolean) => {
    const item = items[id];
    if (throwIfNotFoundParam === false) {
      return item;
    }
    if ((throwIfNotFoundParam || throwIfNotFound) && item === undefined) {
      throw new Error('Could not find item of id: ' + id);
    }
    return item;
  }));
  return {
    getState,
    getItems,
    getAsArray,
    getOptions,
    getById
  } as const;
}

export interface StandardSelectors<T, S extends StandardState<T>> {
  getState: Selector<CommonState, S>;
  getItems: Selector<CommonState, IdDictionary<T>>;
  getAsArray: Selector<CommonState, T[]>;
  getOptions: Selector<CommonState, Array<DropdownOption<number>>>;
  getById: Selector<CommonState, GetById<T>>;
}

export type GetById<T> = (id: number) => T;
