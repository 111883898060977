import {type AssetType} from '../common/redux/entities/assetType';
import {type AssetGroup} from '../common/redux/entities/assetGroup';
import {type SPType} from '../common/redux/entities/spType';
import {api} from './index';

export async function addAssetGroup(assetGroup: AssetGroup) {
  return (await api.post<AssetGroup>('/AssetGroup', assetGroup)).data;
}

export async function updateAssetGroup(assetGroup: AssetGroup) {
  return (await api.put<AssetGroup>('/AssetGroup', assetGroup)).data;
}

export async function addAssetType(assetType: AssetType) {
  return (await api.post<AssetType>('/AssetType', assetType)).data;
}

export async function updateAssetType(assetType: AssetType) {
  return (await api.put<AssetType>('/AssetType', assetType)).data;
}

export async function addSpType(spType: SPType) {
  return (await api.post<SPType>('/SystemProcessType', spType)).data;
}

export async function updateSpType(spType: SPType) {
  return (await api.put<SPType>('/SystemProcessType', spType)).data;
}
