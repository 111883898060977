import {createStandardActions, type GetActions, placeholder, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {type CommonDispatch} from '../index';
import {type ReferencesAlertGroup} from './alertDefinition';
import {saveAlertType} from 'src/api/alerts';

export interface AlertType {
  id: number;
  name: string;
  associatedAlertGroups: AssociatedAlertGroup[];
}

export interface AssociatedAlertGroup extends ReferencesAlertGroup {
  alertTypeId: number;
}

export const upsertAlertType = (alertType: AlertType) => async (dispatch: CommonDispatch) => {
  const response: AlertType = await saveAlertType(alertType);
  dispatch(alertTypeStore.actions.save(response));
  return response;
};

const actions = createStandardActions(placeholder<AlertType>(), 'ALERT_TYPE/SET', 'ALERT_TYPE/SAVE');
export type AlertTypeActions = GetActions<typeof actions>;
export const alertTypes = combineReducers({items: standardItemsReducer<AlertType, AlertTypeActions>(actions)});
export const alertTypeStore = {
  selectors: createStandardSelectors(placeholder<AlertType>(), s => getEntities(s).alertTypes),
  actions: {
    ...actions
  }
} as const;
