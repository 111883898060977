import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {
  createStandardActions,
  type GetActions,
  placeholder,
  standardItemsReducer
} from '../utils';
import {type CommonDispatch} from '../index';
import {upsertManufacturer} from 'src/api/globalConfigApi';

export interface Manufacturer {
  id: number;
  name: string;
  isUniversal: boolean;
}

export function anyManufacturerIdsUniversal(manufacturerIds: number[], manufacturerItems: Record<number, Manufacturer>) {
  return manufacturerIds.map(mId => manufacturerItems[mId]).filter(m => !!m).some(m => m.isUniversal);
}

const actions = createStandardActions(placeholder<Manufacturer>(), 'MANUFACTURER/SET', 'MANUFACTURER/SAVE');
export type ManufacturerActions = GetActions<typeof actions>;
export const manufacturers = combineReducers({items: standardItemsReducer<Manufacturer, ManufacturerActions>(actions)});
export const manufacturerStore = {
  selectors: {
    ...createStandardSelectors(placeholder<Manufacturer>(), s => getEntities(s).manufacturers)
  },
  actions: {
    ...actions,
    upsert: (manufacturer: Manufacturer) => async (dispatch: CommonDispatch) => {
      const response = await upsertManufacturer(manufacturer);
      dispatch(actions.save(response));
      return response;
    }
  }
} as const;
