import {combineReducers, type Dispatch} from 'redux';
import {type CommonDispatch, type GetCommonState} from '../index';
import {type AssetTemplateForm, type AssetTemplateRevision, type AssetTemplateRevisionActions, assetTemplateRevisionStore} from './assetTemplateRevision';
import {
  batchAppActions,
  createStandardActions,
  type GetActions,
  placeholder,
  standardItemsReducer
} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction, createReducer} from 'typesafe-actions';
import {createSelector} from '@reduxjs/toolkit';
import {
  type AssetTemplateSaveResponse,
  getAssetTemplateRevisions,
  saveAssetTemplateForm
} from 'src/api/installationModelTemplateApi';
import {type AppDispatch} from 'src/redux/store';
import {type IdDictionary} from 'src/common/util';
import {type IReferencesManufacturerIds} from 'src/common/redux/abstract/IReferencesManufacturerIds';
import {type IReferencesControllerTypeRevisionIds} from 'src/common/redux/abstract/IReferencesControllerTypeRevisionIds';

export interface AssetTemplate extends AssetTemplateFormFields {
  id: number;
  latestRevisionId: number;
  assetTemplateRevisionIds: number[];
}

export interface AssetTemplateFormFields extends IReferencesManufacturerIds, IReferencesControllerTypeRevisionIds {
  name: string;
  assetGroupId: number;
  containsAssetModules: boolean;
}

export interface AssetTemplateWithAssignedStatus extends AssetTemplate {
  status: 'Assigned' | 'Not Assigned';
}

const cloneActions = {
  clear: createAction('CLEAR_ASSET_TEMPLATE_TO_CLONE')(),
  set: createAction('SET_ASSET_TEMPLATE_TO_CLONE')<AssetTemplateForm>()
};

type AssetTemplateToCloneActions = GetActions<typeof cloneActions>;

const actions = createStandardActions(placeholder<AssetTemplate>(), 'ASSET_TEMPLATE/SET', 'ASSET_TEMPLATE/SAVE');
export type AssetTemplateActions = GetActions<typeof actions> | AssetTemplateToCloneActions;
export const assetTemplates = combineReducers({
  items: standardItemsReducer<AssetTemplate, AssetTemplateActions>(actions),
  assetTemplateToClone: createReducer<AssetTemplateForm|null, AssetTemplateToCloneActions>(null)
    .handleAction(cloneActions.set, (state, action) => action.payload)
    .handleAction(cloneActions.clear, () => null)
});
const selectors = createStandardSelectors(placeholder<AssetTemplate>(), s => getEntities(s).assetTemplates);

const loadRevisionsInner = (assetTemplateId: number) => async (dispatch: AppDispatch) => {
    const response = await getAssetTemplateRevisions(assetTemplateId);
    await dispatch(batchAppActions<AssetTemplateRevisionActions | AssetTemplateActions>([
      assetTemplateStore.actions.save(response.template),
      assetTemplateRevisionStore.actions.merge(response.revisions)
    ]));
    return response.revisions;
  };

export const assetTemplateStore = {
  selectors: {
    ...selectors,
    getClone: selector(s => selectors.getState(s).assetTemplateToClone),
    getRevisionsByTemplate: selector(s => (id: number) =>
      selectors.getById(s)(id).assetTemplateRevisionIds.map(rId => assetTemplateRevisionStore.selectors.getById(s)(rId))),
    getLatest: createSelector(
      assetTemplateRevisionStore.selectors.getById,
      selectors.getById,
      (getRevision, getTemplate) => (id: number) => getRevision(getTemplate(id).latestRevisionId)
    )
  },
  actions: {
    ...actions,
    cloneActions,
    upsert: (form: AssetTemplateForm) => async (dispatch: CommonDispatch) => {
      const response: AssetTemplateSaveResponse = await saveAssetTemplateForm(form);
      await dispatch(batchAppActions<AssetTemplateRevisionActions | AssetTemplateActions>([
        assetTemplateRevisionStore.actions.save(response.revision),
        assetTemplateStore.actions.save(response.template)
      ]));
      return response;
    },
    add: (revisions: AssetTemplateRevision[]) =>
      async (dispatch: Dispatch, getState: GetCommonState) => {
        const saveActions = [];
        for (const revision of revisions) {
          const oldAssetTemplate = assetTemplateStore.selectors.getById(getState())(revision.assetTemplateId!);
          const assetTemplate: AssetTemplate = {
            ...oldAssetTemplate,
            assetTemplateRevisionIds: [...oldAssetTemplate.assetTemplateRevisionIds, revision.id],
            latestRevisionId: revision.id
          };
          saveActions.push(assetTemplateStore.actions.save(assetTemplate));
          saveActions.push(assetTemplateRevisionStore.actions.save(revision));
        }
        dispatch(batchAppActions(saveActions, 'ADD_ASSET_TEMPLATE_REVISIONS'));
      },
    loadRevisions: (assetTemplateId: number) => async (dispatch: AppDispatch): Promise<IdDictionary<AssetTemplateRevision>> => {
      return await dispatch(loadRevisionsInner(assetTemplateId));
    }

  }
} as const;
