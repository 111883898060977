import {
  type ManufacturerOSLabel,
  type ManufacturerOSLabelList
} from 'src/common/redux/entities/conversions/operational-statuses/manufacturerOSLabel';
import {api} from '../index';

export async function getAllManufacturerOperationalStatusLabels() {
  return (await api.get<ManufacturerOSLabel[]>('/ManufacturerOSLabel')).data;
}

export async function upsertManufacturerOperationalStatusLabels(manufacturerOperationStatusLabels: ManufacturerOSLabelList) {
  return (await api.put<ManufacturerOSLabelList>('/ManufacturerOSLabel', manufacturerOperationStatusLabels)).data;
}
