import {type IdDictionary} from '../util';
import {type Company, companyStore} from './entities/company';
import {type RmxBox} from '../types/RmxBox';
import {type RmxBoxType, setRmxBoxTypes} from './entities/rmxBoxType';
import {type Manufacturer, manufacturerStore} from './entities/manufacturers';
import {type AssetTemplate, assetTemplateStore} from './entities/assetTemplate';
import {type AssetTemplateRevision, assetTemplateRevisionStore} from './entities/assetTemplateRevision';
import {type AssetGroup, assetGroupStore} from './entities/assetGroup';
import {type SPType, spTypeStore} from './entities/spType';
import {type SpTemplate, spTemplateStore} from './entities/spTemplate';
import {setRmxBoxes} from './entities/rmxBox';
import {type DataPoint, setDataPoints} from './entities/dataPoint';
import {setUnits, type Unit} from './entities/unit';
import {type AlertType, alertTypeStore} from './entities/alertType';
import {type AlertGroup, alertGroupStore} from './entities/alertGroup';
import {type AlertConversion, alertConversionStore} from './entities/conversions/alertConversion';
import {type Router, routerStore} from 'src/common/redux/entities/router';
import {type AssetType, setAssetTypes} from 'src/common/redux/entities/assetType';
import {type Country, countryStore} from 'src/common/redux/entities/country';
import {type Carrier, carrierStore} from 'src/common/redux/entities/carrier';
import {type ConnectionKit, connectionKitStore} from 'src/common/redux/entities/connectionKit';
import {type ProtocolAdapter, protocolAdapterStore} from 'src/common/redux/entities/protocolAdapter';
import {type ControllerType, controllerTypeStore} from 'src/common/redux/entities/controllerType';
import {type ControllerTypeRevision, controllerTypeRevisionStore} from 'src/common/redux/entities/controllerTypeRevision';

export interface CommonConfigurationResponse {
  companies: IdDictionary<Company>;
  rmxBoxes: IdDictionary<RmxBox>;
  dataPoints: IdDictionary<DataPoint>;
  rmxBoxTypes: IdDictionary<RmxBoxType>;
  manufacturers: IdDictionary<Manufacturer>;
  assetTemplates: IdDictionary<AssetTemplate>;
  assetTemplateRevisions: IdDictionary<AssetTemplateRevision>;
  assetGroups: IdDictionary<AssetGroup>;
  assetTypes: IdDictionary<AssetType>;
  systemProcessTypes: IdDictionary<SPType>;
  spTemplates: IdDictionary<SpTemplate>;
  units: IdDictionary<Unit>;
  alertTypes: IdDictionary<AlertType>;
  alertGroups: IdDictionary<AlertGroup>;
  alertConversions: IdDictionary<AlertConversion>;
  routers: IdDictionary<Router>;
  countries: IdDictionary<Country>;
  carriers: IdDictionary<Carrier>;
  connectionKits: IdDictionary<ConnectionKit>;
  protocolAdapters: IdDictionary<ProtocolAdapter>;
  controllerTypes: IdDictionary<ControllerType>;
  controllerTypeRevisions: IdDictionary<ControllerTypeRevision>;
}

export const getCommonConfigurationActions = (config: CommonConfigurationResponse) =>  {
  return [
    manufacturerStore.actions.set(config.manufacturers),
    setDataPoints(config.dataPoints),
    assetTemplateStore.actions.set(config.assetTemplates),
    assetTemplateRevisionStore.actions.set(config.assetTemplateRevisions),
    assetGroupStore.actions.set(config.assetGroups),
    spTypeStore.actions.set(config.systemProcessTypes),
    companyStore.actions.set(config.companies),
    setRmxBoxes(config.rmxBoxes),
    setRmxBoxTypes(config.rmxBoxTypes),
    spTemplateStore.actions.set(config.spTemplates),
    alertTypeStore.actions.set(config.alertTypes),
    alertGroupStore.actions.set(config.alertGroups),
    setAssetTypes(config.assetTypes),
    alertConversionStore.actions.set(config.alertConversions),
    setUnits(config.units),
    routerStore.actions.set(config.routers),
    carrierStore.actions.set(config.carriers),
    countryStore.actions.set(config.countries),
    connectionKitStore.actions.set(config.connectionKits),
    protocolAdapterStore.actions.set(config.protocolAdapters),
    controllerTypeStore.actions.set(config.controllerTypes),
    controllerTypeRevisionStore.actions.set(config.controllerTypeRevisions)
  ];
};
