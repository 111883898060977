import React from 'react';
import {connect} from 'react-redux';
import DashboardRoutes from 'src/pages/Dashboard/DashboardRoutes';
import {PageHeader} from 'src/components/layout/PageHeader/PageHeader';
import {type WebState} from 'src/redux/types/WebState';
import styles from './Dashboard.module.scss';
type DashboardProps = ReturnType<typeof mapStateToProps>;

export function Dashboard(_: DashboardProps) {
  console.log('dashboard');
  return (
    <div className={styles['page-body']}>
      <PageHeader>
        <DashboardRoutes />
      </PageHeader>
    </div>
  );
}

const mapStateToProps = (state: WebState) => ({});

export default connect(mapStateToProps)(Dashboard);
