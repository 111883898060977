import React, {useState} from 'react';
import {Card, CardBody} from 'src/components/util/Controls';
import styles from './UserManagement.module.scss';
import {connect} from 'react-redux';
import {type WebState} from 'src/redux/types/WebState';
import {PageHeader} from 'src/components/layout/PageHeader/PageHeader';
import {bindActionCreators, type Dispatch} from 'redux';
import {CenteredSpinner} from 'src/components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from 'src/components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError} from 'src/common/util/http';
import {type Readonly} from 'src/common/util/object';
import {useMount} from 'src/hooks/useMount';
import UserManagementRoutes from './UserManagementRoutes';
import {loadUserManagementData} from 'src/common/redux/userManagement';

type UserManagementProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function UserManagement({actions}: UserManagementProps) {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  useMount(async () => {
    try {
      await actions.loadUserManagementData();
    } catch (e) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load users'}));
    }
    setLoading(false);
  });
  const renderContent = () => {
    return (
      <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
        <CardBody>
          <UserManagementRoutes />
        </CardBody>
      </Card>
    );
  };

  return (
    <PageHeader label='User Management' removeMargin={true}>
      <div className={styles['config-container']}>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
        )}
      </div>
    </PageHeader>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadUserManagementData: loadUserManagementData}, dispatch)});
const mapStateToProps = (state: WebState) => ({state: state as Readonly<WebState>});
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
