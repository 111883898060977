import {type DataPoint, type DataPointType, getDataPointById, type GetDPById} from '../entities/dataPoint';
import type TemplateSource from '../../types/TemplateSource';
import {
  type DataPointTemplateAssignment,
  type GeneralDPAssignment,
  type OrderableDPAssignment,
  reindexAssignmentOrder,
  remapDataPoints,
  type RequiredOrderableDPAssignment,
  sortAssignmentsAscending,
  sortTemplateAssignmentsAscending
} from './dataPointAssignment';
import {makeDataPointTemplateAssignment} from '../../util/factory/assetTemplateFactory';
import {exactPropertyOf} from '../../util/object';
import {type AlertBitByBitConversionRevision, type AlertCodeConversionRevision, type TriggersTemplate} from '../entities/conversions/alertConversionRevision';
import {type AssetTemplateForm, type DataPointAssignmentKeys} from '../entities/assetTemplateRevision';
import { arrayToSet, cast, distinct } from '../../util';
import {type SpTemplateForm} from '../entities/spTemplateRevision';
import {type CommonState} from '../index';
import { type AssetGroup, getAssetGroupById } from '../entities/assetGroup';
import {type AssetTemplateFormFields} from '../entities/assetTemplate';
import {type SpTemplateFormFields} from '../entities/spTemplate';
import {useMemo} from 'react';
import {type FormikErrors} from 'formik';
import {getUUID} from 'src/util/uuid';

export interface DataPointTemplate<T> {
  dataDataPointAssignments: T[];
  productionStatusAssignments: T[];
  operationalStatusAssignments: T[];
  specAssignments: T[];
}

export interface ContainsTemplateSources {
  templateSources: TemplateSource[];
}

export interface DPTemplateAssignmentTemplate extends DataPointTemplate<DataPointTemplateAssignment>, ContainsTemplateSources, TriggersTemplate {
  luid: string;
  consecutiveBitPollingOnly: boolean;
}

export function remapTriggersOnDPTemplateAssignmentTemplate<T extends  DPTemplateAssignmentTemplate>(rev: T): T {
  const {dpsDictionary: triggersDictionary, remappedDps: remappedTriggers} = remapDataPoints(rev.triggers);
  return  {
    ...rev,
    triggers: remappedTriggers,
    dataDataPointAssignments: remapTriggerReferences(rev.dataDataPointAssignments, triggersDictionary),
    operationalStatusAssignments: remapTriggerReferences(rev.operationalStatusAssignments, triggersDictionary),
    productionStatusAssignments: remapTriggerReferences(rev.productionStatusAssignments, triggersDictionary),
    specAssignments: remapTriggerReferences(rev.specAssignments, triggersDictionary)
  };
}

const remapTriggerReferences = (
  assignments: DataPointTemplateAssignment[],
  dic: Record<string, DataPoint>): DataPointTemplateAssignment[] =>
  assignments.map(a => remapTriggerReference(a, dic));

export const remapTriggerReference = (a: DataPointTemplateAssignment, triggersDictionary: Record<string, DataPoint>): DataPointTemplateAssignment => ({
  ...a,
  alertSetPoints:  a.alertSetPoints.map(s => ({
    ...s,
    triggerUuid: s.triggerUuid ? triggersDictionary[s.triggerUuid].uuid : null
  }))
});

export const makeDPTemplateAssignmentTemplate = () => cast<DPTemplateAssignmentTemplate>({
  luid: getUUID(),
  templateSources: [],
  triggers: [],
  dataDataPointAssignments: [],
  productionStatusAssignments: [],
  operationalStatusAssignments: [],
  specAssignments: [],
  consecutiveBitPollingOnly: false
});

export function useDpTemplateAssignment(revision: DPTemplateAssignmentTemplate) {
  const {
    operationalStatusAssignments,
    productionStatusAssignments,
    dataDataPointAssignments,
    specAssignments, templateSources, luid, triggers, consecutiveBitPollingOnly} = revision;
  return useMemo(() => cast<DPTemplateAssignmentTemplate>({
      luid, operationalStatusAssignments, productionStatusAssignments, dataDataPointAssignments, specAssignments, templateSources, triggers, consecutiveBitPollingOnly}),
    [luid, operationalStatusAssignments, productionStatusAssignments, dataDataPointAssignments, specAssignments, templateSources, triggers, consecutiveBitPollingOnly]);
}

export function useDpTemplateAssignmentErrors(revision: FormikErrors<DPTemplateAssignmentTemplate> = {}) {
  const {operationalStatusAssignments, productionStatusAssignments, dataDataPointAssignments, specAssignments, templateSources} = revision;
  return useMemo(() => cast<FormikErrors<DPTemplateAssignmentTemplate>>({
      operationalStatusAssignments, productionStatusAssignments, dataDataPointAssignments, specAssignments, templateSources}),
    [operationalStatusAssignments, productionStatusAssignments, dataDataPointAssignments, specAssignments, templateSources]);
}

export function getDPAssignmentProperty(type: DataPointType): DataPointAssignmentKeys {
  switch (type) {
    case 'Data':
      return 'dataDataPointAssignments';
    case 'OperationalStatusField':
      return 'operationalStatusAssignments';
    case 'ProductionStatus':
      return 'productionStatusAssignments';
    case 'Spec':
      return 'specAssignments';
    case 'ManufacturerAlertCode':
      return exactPropertyOf<AlertCodeConversionRevision>()('manufacturerAlertCodeRegisters');
    case 'AlertStatusRegister':
      return exactPropertyOf<AlertBitByBitConversionRevision>()('alertStatusAssignments');
    case 'AlertTrigger':
      return exactPropertyOf<AlertCodeConversionRevision>()('triggers');
    default:
      throw new Error('Unsupported data point type given');
  }
}

export function getDPAssignments<T>(entity: DataPointTemplate<T>, type: DataPointType): T[] {
  const property = getDPAssignmentProperty(type);
  return entity[property as keyof typeof entity] as T[];
}

export function getAllAssignmentsExcludingSpecs<T>(container?: DataPointTemplate<T>) {
  if (!container) {
    return [];
  }
  return container.operationalStatusAssignments
    .concat(container.dataDataPointAssignments)
    .concat(container.productionStatusAssignments);
}

export function getAllAssignmentDataPointIds<T extends GeneralDPAssignment>(container?: DataPointTemplate<T>) {
  return getAllAssignmentsExcludingSpecs(container).map(a => a.dataPointId);
}

export function getAllAssignmentsIncludingSpecsDataPointIds<T extends GeneralDPAssignment>(container?: DataPointTemplate<T>) {
  return getAllAssignmentsIncludingSpecs(container).map(a => a.dataPointId);
}


export function getDataPointsFromAssetGroup(assetGroup: AssetGroup | null, dps: DataPoint[]) {
  if (!assetGroup)
    return dps;
  const dataPointIdsSet = arrayToSet(getAllAssignmentDataPointIds(assetGroup));
  return dps.filter(dp => dataPointIdsSet.has(dp.id));
}

export function getDataPointsIncludingSpecsFromAssetGroup(assetGroup: AssetGroup | null, dps: DataPoint[]) {
  if (!assetGroup)
    return dps;
  const dataPointIdsSet = arrayToSet(getAllAssignmentsIncludingSpecsDataPointIds(assetGroup));
  return dps.filter(dp => dataPointIdsSet.has(dp.id));
}

export const getGroupOrType = (state: CommonState) => (
  form: AssetTemplateFormFields | SpTemplateFormFields
): DataPointTemplate<RequiredOrderableDPAssignment> => {
  if ('assetGroupId' in form) {
    return getAssetGroupById(state)(form.assetGroupId);
  }
  if ('systemProcessTypeId' in form) {
    return getAssetGroupById(state)(form.systemProcessTypeId);
  }
  throw new Error('Not Supported');
};

function getAllDataPointIds<T extends GeneralDPAssignment>(container?: DataPointTemplate<T>) {
  return getAllAssignmentsExcludingSpecs(container).map(a => a.dataPointId).filter(distinct);
}
export const getCurrentEntityDps = (state: CommonState) => (form: AssetTemplateForm | SpTemplateForm, currentDpId?: number): DataPoint[]  => {
  const group = getGroupOrType(state)(form.template);
  if (!group) {
    return [];
  }
  return getAllDataPointIds(form.revision)
    .concat(group.operationalStatusAssignments.map(a => a.dataPointId))
    // filter out the current assignment
    .filter(dpId => dpId !== currentDpId)
    .filter(distinct)
    .map(dpId => getDataPointById(state)(dpId));
};

export const getAssetGroupDps = (state: CommonState) => (assetGroupId: number, currentDpId?: number): DataPoint[]  => {
  const group = getAssetGroupById(state)(assetGroupId);
  if (!group) {
    return [];
  }
  return getAllDataPointIds(group)
    .filter(dpId => dpId !== currentDpId) // filter out the current assignment
    .map(dpId => getDataPointById(state)(dpId));
};

export function getAllAssignmentsIncludingSpecs<T>(container?: DataPointTemplate<T>) {
  return getAllAssignmentsExcludingSpecs(container).concat(container?.specAssignments ?? []);
}

export function reindexDataPointTemplate<T extends DPTemplateAssignmentTemplate>(form: T) {
  return {
    ...form,
    dataDataPointAssignments: reindexAssignmentOrder(form.dataDataPointAssignments),
    operationalStatusAssignments: reindexAssignmentOrder(form.operationalStatusAssignments),
    productionStatusAssignments: reindexAssignmentOrder(form.productionStatusAssignments),
    specAssignments: reindexAssignmentOrder(form.specAssignments)
  };
}

export function reindexDataPointAssignments<T extends DataPointTemplate<OrderableDPAssignment>>(form: T) {
  return {
    ...form,
    dataDataPointAssignments: reindexAssignmentOrder(form.dataDataPointAssignments),
    operationalStatusAssignments: reindexAssignmentOrder(form.operationalStatusAssignments),
    productionStatusAssignments: reindexAssignmentOrder(form.productionStatusAssignments),
    specAssignments: reindexAssignmentOrder(form.specAssignments)
  };
}

export function sortDPTemplate<T extends DataPointTemplate<OrderableDPAssignment>>(form: T): T {
  form = {...form};
  form.productionStatusAssignments = sortAssignmentsAscending(form.productionStatusAssignments);
  form.operationalStatusAssignments = sortAssignmentsAscending(form.operationalStatusAssignments);
  form.dataDataPointAssignments = sortAssignmentsAscending(form.dataDataPointAssignments);
  form.specAssignments = sortAssignmentsAscending(form.specAssignments);
  return form;
}

function convertDpAssignmentsToDpOutputAssignments(
  getDpById: GetDPById,
  currentAssignments: DataPointTemplateAssignment[],
  assignments: RequiredOrderableDPAssignment[]
): DataPointTemplateAssignment[] {
  const newAssignments = assignments
    .filter(a => a.required)
    .map(assignment => {
      const currentAssignment = currentAssignments.find(a => a.dataPointId === assignment.dataPointId);
      if (currentAssignment) {
        return {
          ...currentAssignment,
          required: true
        };
      }
      return makeDataPointTemplateAssignment(getDpById(assignment.dataPointId), assignment.order, assignment.required);
    });
  return [
    ...newAssignments,
    ...currentAssignments
      .filter(a => assignments.find(na => na.dataPointId === a.dataPointId) != null)
      .filter(a => newAssignments.find(na => na.dataPointId === a.dataPointId) == null)
  ];
}

export function applyDPTemplate
<T extends DPTemplateAssignmentTemplate>(getDpById: GetDPById, revision: T, template: DataPointTemplate<RequiredOrderableDPAssignment> | undefined): T {
  if (!template) {
    return revision;
  }
  const dataDataPointAssignments = sortTemplateAssignmentsAscending(
    convertDpAssignmentsToDpOutputAssignments(getDpById, revision.dataDataPointAssignments, template.dataDataPointAssignments));
  const productionStatusAssignments = sortTemplateAssignmentsAscending(
    convertDpAssignmentsToDpOutputAssignments(getDpById, revision.productionStatusAssignments, template.productionStatusAssignments));
  const operationalStatusAssignments = sortTemplateAssignmentsAscending(
    convertDpAssignmentsToDpOutputAssignments(getDpById, revision.operationalStatusAssignments, template.operationalStatusAssignments));
  return {
    ...revision,
    dataDataPointAssignments: dataDataPointAssignments,
    productionStatusAssignments: productionStatusAssignments,
    operationalStatusAssignments: operationalStatusAssignments
  };
}
