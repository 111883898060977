import {
  createStandardActions,
  type GetActions,
  placeholder,
  standardItemsReducer
} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {convertArrayToDropDownOptions, type IdDictionary} from '../../util';
import {type CommonDispatch} from '../index';
import {type RevisionEntity} from '../../types/Revision';
import {createAction} from 'typesafe-actions';
import {
  getAlertBitByBitAssignmentGroupRevisions,
  getAlertBitByBitAssignmentGroupRevisionsByGroupIds
} from 'src/api/alerts/alertBitByBitAssignmentGroupApi';

export const bitNumber = [4, 8, 16, 32] as const;
export type BitNumber = typeof bitNumber[number];

export interface AlertBitByBitAssignmentGroupRevision extends RevisionEntity {
  id: number;
  numberOfBits: BitNumber;
  dataPointAssociated: boolean;
  bitAssignments: BitAssignment[];
  alertBitByBitAssignmentGroupId: number;
}

export interface BitAssignment {
  id: number;
  bit: number;
  activeState: boolean;
  alertGroupId: number;
  alertTypeId: number | null;
}
const merge = createAction('ALERT_BIT_BY_BIT_ASSIGNMENT_GROUP_REV/MERGE')<IdDictionary<AlertBitByBitAssignmentGroupRevision>>();
const actions = createStandardActions(
  placeholder<AlertBitByBitAssignmentGroupRevision>(),
  'ALERT_BIT_BY_BIT_ASSIGNMENT_GROUP_REV/SET',
  'ALERT_BIT_BY_BIT_ASSIGNMENT_GROUP_REV/SAVE'
);
export type AlertBitByBitAssignmentGroupRevisionActions = GetActions<typeof actions> | ReturnType<typeof merge>;
const selectors = createStandardSelectors(placeholder<AlertBitByBitAssignmentGroupRevision>(), s => getEntities(s).alertBitByBitAssignmentGroupRevisions);
export const alertBitByBitAssignmentGroupRevisions =
  combineReducers({
    items: standardItemsReducer<AlertBitByBitAssignmentGroupRevision, AlertBitByBitAssignmentGroupRevisionActions>(actions)
      .handleAction(merge, (state, action) => ({...state, ...action.payload}))
  });
export const alertBitByBitAssignmentGroupRevisionStore = {
  selectors: {
    ...selectors,
    getBitNumberDropDownOptions: selector(s => convertArrayToDropDownOptions<BitNumber>(Object.values(bitNumber)))
  },
  actions: {
    ...actions,
    loadRevisions: (alertBitByBitAssignmentGroupId: number) => async (dispatch: CommonDispatch) => {
      const revisions = await getAlertBitByBitAssignmentGroupRevisions(alertBitByBitAssignmentGroupId);
      await dispatch(actions.set(revisions));
      return revisions;
    },
    merge,
    loadRevisionsByGroups: (groupIds: number[]) => async (dispatch: CommonDispatch) => {
      const revisions = await getAlertBitByBitAssignmentGroupRevisionsByGroupIds(groupIds);
      await dispatch(merge(revisions));
      return revisions;
    }
  }
} as const;
