import {
  type AlertDefinition,
  type AlertDescription,
  type AlertDescriptionGroupRelationship,
  type DpaAlertDefinition,
  type NdpaAlertDefinition
} from '../../redux/entities/alertDefinition';
import {type AlertType, type AssociatedAlertGroup} from '../../redux/entities/alertType';
import {cast} from '../index';
import {type AlertSetPointOverride} from '../../redux/abstract/dataPointAssignment';

export function makeAlertType(): AlertType {
  return {
    id: 0,
    name: '',
    associatedAlertGroups: []
  };
}

export function makeAlertDefinition(dpAssociated: boolean): AlertDefinition {
  const common = {id: 0, alertDescriptions: []};
  if (dpAssociated) {
    return cast<DpaAlertDefinition>({
      ...common,
      dataPointId: 0,
      assetGroupId: null,
      type: 'DataPointAssociated'
    });
  } else {
    return cast<NdpaAlertDefinition>({
      ...common,
      description: '',
      type: 'NonDataPointAssociated'
    });
  }
}

export function makeAlertDescription(alertTypeId?: number): AlertDescription {
  return {
    alertId: null,
    alertTypeId: alertTypeId ? alertTypeId : null,
    description: '',
    groups: [],
    response: ''
  };
}

export function makeAlertDescriptionGroup(alertGroupId: number, alertDescriptionId: number): AlertDescriptionGroupRelationship {
  return {
    alertGroupId: alertGroupId,
    alertDescriptionId: alertDescriptionId
  };
}

export function makeAssociatedAlertGroup(alertGroupId: number, alertTypeId: number): AssociatedAlertGroup {
  return {
    alertGroupId: alertGroupId,
    alertTypeId: alertTypeId
  };
}

export function makeAlertSetPointOverride(luid: string, templateRevisionLuid: string): AlertSetPointOverride {
  return {
    alertSetPointLuid: luid,
    templateRevisionLuid: templateRevisionLuid,
    timerOnDelay: null,
    timerOffDelay: null,
    manualValue: null,
    manualValue2: null
  };
}
