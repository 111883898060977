import {type TemplateInput} from './TemplateInput';
import {type ContainsDelays} from '../redux/abstract/dataPointAssignment';
import type TemplateSource from 'src/common/types/TemplateSource';
import {getUUID} from '../../util/uuid';

export interface TemplateOutput extends ContainsDelays {
  name: string;
  primary: boolean;
  inputs: TemplateInput[];
  calculation: string | null;
  testTimeStampInput: string | null;
  calculationTestOutput: string | null;
  convertToBoolean: boolean;
  comparisonType: ComparisonType | null;
  bitNumber: number | null;
  comparisonValue: number | null;
  resultingValue: boolean | null;
}

export const regenerateOutputsUuids = (outputs: TemplateOutput[], templateSources: Record<string, TemplateSource>): TemplateOutput[] =>
  outputs.map(o => regenerateOutputUuids(o, templateSources));

export const regenerateOutputUuids = (o: TemplateOutput, templateSources: Record<string, TemplateSource>): TemplateOutput => ({
  ...o,
  inputs: o.inputs.map<TemplateInput>(i => ({
    ...i,
    uuid: getUUID(),
    templateSourceUuid: templateSources[i.templateSourceUuid]?.uuid ?? ''
  }))
});

export enum EComparisonType {
  Bit = 'Bit',
  ValueComparison = 'ValueComparison'
}

export type ComparisonType = keyof typeof  EComparisonType;
